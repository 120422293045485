import * as React from "react"
import { FunctionComponent, useEffect, useState } from "react"
import { RouteProps } from "react-router"
import { Loading } from "../../atoms/Loading/Loading"
import { get } from "../../../common/utils"
import { SUB_CATEGORIES } from "../../../common/apiConstants"
import Step1 from "../../templates/AddProduct/step1/Step1"
import Dexie from "dexie"

const Step1Page: FunctionComponent<RouteProps> = (props) => {
  const [ data, setData ] = useState()
  const [selected, setSelected] = useState()
  console.log("isEdit", props.location["state"]["is_edit"] ? true : false)

  useEffect(() => {
    (async () => {

      const db = new Dexie("vendinDB")
      db.version(1).stores({ productForm: 'formName', })
      db.open()
      db.productForm.get("step1").then(res => {
        if (res) {
          setSelected(JSON.parse(res.form).category)
        }
      }).catch(e => {
        console.log("getLocalDB e", e)
      })

      let paramObj = { params: {} }
      paramObj.params.id = props.location.search.replace("?id=", "")
      const data = await get(SUB_CATEGORIES, {})
      // @ts-ignore
      setData(data)
    })()
  }, [])

  return data ? (
    <Step1 categories={data} selected={selected} />
  ) : <Loading />
}

export default Step1Page
