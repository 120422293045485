import "../../../../common/wdyr"
import * as React from "react"
import { FunctionComponent, useMemo, useState } from "react"
import { yupResolver } from "@hookform/resolvers/yup"
import { FormProvider, useForm } from "react-hook-form"
import { ButtonComponent } from "../../../atoms/Button/Button"
import "../../../../styles/maker/maker_common.css"
import { IStep7 } from "./IStep7"
import * as yup from "yup"
import { Button } from "@material-ui/core"
import { TextForm } from "../../../molecules/TextForm/TextForm"
import { FileForm } from "../../../molecules/FileForm/FileForm"
import * as _ from "lodash"
import { addLocalDB, onDraftSubmit } from "../../../../common/utils"
import { RELATIVE_PATH } from "../../../../common/routePath"
import DraftModal from "../../../organisms/common/modal/DraftModal"

export const Step7: FunctionComponent<IStep7> = (props) => {
  const [effectForm, setEffectForm] = useState(props.effects)
  const [ isDraft, setIsDraft ] = React.useState(false)
  const [ showDraftModal, setShowDraftModal ] = React.useState(false)
  const [ rtnProductId, setRtnProductId] = useState()
  const [loading, setLoading] = useState(false)

  const schema = yup.lazy(() => {
    let yupList = {}
    effectForm.map((value, index) => {
      yupList[`effect_title_${index}`] = yup.string().max(255,"255文字以内")
      value.effect_documents.map((document, i) => {
        yupList[`effect_url_${index}_${i}`] = yup.string().url("URLが不正です")
      })
    })
    return yup.object().shape({ ...yupList })
  })

  const methods = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema)
  })

  const { handleSubmit } = methods
  const onClick = async (data) => {
    const submitObje = _.cloneDeep(effectForm)
    Object.keys(data).map((key, index) => {
      const names = key.split("_")
      if (key.includes("effect_title")) {
        if (submitObje[names[2]]) {
          submitObje[names[2]]["effect_title"] = data[key]
        }
      } else if (key.includes("effect_url")) {
        if (submitObje[names[2]] && submitObje[names[2]]["effect_documents"][names[3]]){
          submitObje[names[2]]["effect_documents"][names[3]]["effect_url"] = data[key]
        }
      }
    })

    const dataObj = JSON.stringify({
      effects: [
        ...submitObje
      ]
    })
    if (isDraft) {
      setShowDraftModal(true)
      await onDraftSubmit("step7", dataObj).then(res => {
        // @ts-ignore
        setRtnProductId(res ? res["product_id"] : props.effects[0].product_id)
      }).catch(e => console.log("onDraftSubmit", e)).finally(() => setLoading(false))
    } else {
      await addLocalDB("step7", dataObj, RELATIVE_PATH.STEP8)
    }
  }


  // フォームを呼び出し
  const renderForm = useMemo(() => {
    // @ts-ignore
    return (effectForm.map((value, index) => (
      <div key={`${index}_custom_form`} className="item_full_wrap">
        <TextForm
          id={`effect_title_${index}`}
          title={"効果 " + (index + 1)}
          name={`effect_title_${index}`}
          value={value.effect_title}
          placeholder={"XXX文字以内で入力"}
          style={{ width: "100%" }}
        />
        <div className="effect_child">
          {value.effect_documents.map((document, i) => {
            return (
              <div key={`${i}_document_form`} className="effect_child_row">
                <FileForm
                  id={`document_${index}_${i}`}
                  title={"添付資料"}
                  name={`document_${index}_${i}`}
                  value={document["effect_document"]}
                  caution={"※形式はPDFのみ\r\n※容量は最大2MBまで"}
                  setForm={(index, url) => setFormFile(index, url, i)}
                />
                <TextForm
                  id={`effect_url_${index}_${i}`}
                  title={"資料URL"}
                  name={`effect_url_${index}_${i}`}
                  value={document["effect_url"]}
                  placeholder={"例：https://www.vendin.co.jp"}
                />
              </div>
            )
          })}
          <div className="clone_buttons">
            <Button onClick={e => onAdd(index)} className="addBtn" style={{ marginRight: "1rem" }}>追加</Button>
            <Button onClick={e => onRemove(index)} variant="outlined">削除</Button>
          </div>
        </div>
      </div>
    )))
  }, [effectForm])

  const onAdd = (index) => {
    let temp = _.cloneDeep(effectForm)
    const lastIndex = temp[index].effect_documents.length - 1
    const attribute = _.cloneDeep(temp[index].effect_documents[lastIndex])
    attribute["effect_document"] = ""
    attribute["effect_url"] = ""
    temp[index].effect_documents.push(attribute)

    setEffectForm(temp)
  }

  const onRemove = (index) => {
    let temp = _.cloneDeep(effectForm)
    if (temp[index].effect_documents.length > 1) {
      temp[index].effect_documents.pop()
      setEffectForm(temp)
    }
  }

  const onFormAdd = () => {
    let temp = _.cloneDeep(effectForm)
    const lastForm = _.cloneDeep(temp[0])
    lastForm["effect_title"] = ""
    const tempDocument = lastForm.effect_documents[0]
    tempDocument["effect_document"] = ""
    tempDocument["effect_url"] = ""
    lastForm.effect_documents.splice(0)
    lastForm.effect_documents.push(tempDocument)
    temp.push(lastForm)

    setEffectForm(temp)
  }

  const onFormRemove = () => {
    let temp = _.cloneDeep(effectForm)
    if (temp.length > 1) {
      temp.pop()
      setEffectForm(temp)
    }
  }

  const setFormFile = (index, url, childIndex) => {
    let i = index ? index : 0
    let j = childIndex ? childIndex : 0
    let temp = _.cloneDeep(effectForm)
    const attribute = _.cloneDeep(temp[i])
    attribute["effect_documents"][j]["effect_document"] = url
    temp[i] = attribute
    setEffectForm(temp)
  }

  return (
    <section className="main_area_full main_center step step7">
      <h2 className="h2_2">商品新規登録 - セールス情報
        <div className="step">STEP <span className="big">7</span>/ <span>13</span></div>
      </h2>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onClick)}>
          <div className="ir_box">
            <h3 className="rh3">セールスポイント効果<span className="must">必須項目</span></h3>
            <div className="step7_wrap">
              <div className="step_description">顧客メリットを記入のうえ、各実績を示す資料を添付 or URLを記入してください</div>
              <div className="step_caution">例文：独自技術を採用した最新の美白クリームが登場<br />独自技術を採用した最新の美白クリームが登場</div>

              {renderForm}

              <div className="clone_buttons">
                <Button onClick={onFormAdd} className="addBtn" style={{ marginRight: "1rem" }}>入力項目を追加</Button>
                <Button onClick={onFormRemove} variant="outlined">削除</Button>
              </div>
            </div>
          </div>

          <div className="form_bottom_btn_wrap">
            <div className="fbbw_box">
              <ButtonComponent text={"下書き保存"} type={"submit"} onClick={() => setIsDraft(true)} />
              <ButtonComponent text={"次へ進む"} type={"submit"} onClick={() => setIsDraft(false)} />
            </div>
          </div>

        </form>
      </FormProvider>

      <DraftModal
        productId={rtnProductId}
        is_show={showDraftModal}
        loading={loading}
        setLoading={e => setLoading(e)}
        set_is_show={e => setShowDraftModal(e)}
      />
    </section>
  )
}

export default Step7
