import "../../../../common/wdyr"
import { FunctionComponent, useEffect, useMemo, useState } from "react"
import * as React from "react"
import { yupResolver } from "@hookform/resolvers/yup"
import { Controller, FormProvider, useForm } from "react-hook-form"
import { ButtonComponent } from "../../../atoms/Button/Button"
import "../../../../styles/maker/maker_common.css"
import { IStep11 } from "./IStep11"
import * as yup from "yup"
import { RadioForm } from "../../../molecules/RadioForm/RadioForm"
import { TextForm } from "../../../molecules/TextForm/TextForm"
import { ImageForm } from "../../../molecules/ImageForm/ImageForm"
import * as _ from "lodash"
import { Button } from "@material-ui/core"
import { addLocalDB, onDraftSubmit, validURL } from "../../../../common/utils"
import { RELATIVE_PATH } from "../../../../common/routePath"
import DraftModal from "../../../organisms/common/modal/DraftModal"

export const Step11: FunctionComponent<IStep11> = (props) => {
  const { is_tv_achievement, is_approval, talent_name, talent_image, talent_movie_url, others } = props
  const [ form, setForm ] = useState(others)
  const [ imageForm, setImageForm ] = useState(talent_image)
  const [ isDraft, setIsDraft ] = React.useState(false)
  const [ showDraftModal, setShowDraftModal ] = React.useState(false)
  const [ rtnProductId, setRtnProductId] = useState()
  const [loading, setLoading] = useState(false)
  const schema = yup.lazy(() => {
    let yupList = {}
    yupList[`is_tv_achievement`] = yup.string().required("必須")
    yupList[`is_approval`] = yup.string().required("必須")
    form.map((value, index) => {
      yupList[`others_comment_${index}`] = yup.string().max(255, "255文字以内")
      yupList[`others_movie_${index}`] = yup.string().url("URLが不正です")
    })
    const yupObj = yup.object().shape({
      ...yupList,
      talent_name: yup.mixed().test('match', '必須', function (talent_name) {
        return this.parent.is_approval === "1" ? talent_name.length >= 1 : true
      }),
      talent_image: yup.mixed().test('match', '必須', function (talent_image) {
        if (this.parent.is_approval === "1") {
          const fileCheck = talent_image.length >= 1
          return imageForm ? true : fileCheck
        } else {
          return true
        }
      }),
      talent_movie_url: yup.mixed().test('match', 'URLが不正です', function (talent_movie_url) {
        return this.parent.is_approval === "1" ? validURL(talent_movie_url) : true
      }),
    })
    return yupObj
  })

  const methods = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema)
  })

  const { handleSubmit } = methods
  const onClick = async (data) => {
    const submitObje = _.cloneDeep(form)
    Object.keys(data).map((key, index) => {
      const names = key.split("_")
      if (key.includes("others_comment")) {
        if (names.length > 2 && submitObje[names[2]]) {
          submitObje[names[2]]["others_comment"] = data[key]
        }
      } else if (key.includes("others_movie")) {
        if (names.length > 2 && submitObje[names[2]]) {
          submitObje[names[2]]["others_movie"] = data[key]
        }
      }
    })

    const dataObj = JSON.stringify({
      id: props.id ? props.id : "",
      product_id: props.product_id ? props.product_id : "",
      is_tv_achievement: data.is_tv_achievement,
      is_approval: data.is_approval,
      talent_name: data.talent_name,
      talent_image: imageForm,
      talent_movie_url: data.talent_movie_url,
      others: [ ...submitObje ],
    })

    if (isDraft) {
      setShowDraftModal(true)
      await onDraftSubmit("step11", dataObj).then(res => {
        // @ts-ignore
        setRtnProductId(res ? res["product_id"] : props.product_id)
      }).catch(e => console.log("onDraftSubmit", e)).finally(() => setLoading(false))
    } else {
      await addLocalDB("step11", dataObj, RELATIVE_PATH.STEP12)
    }
  }

  const onAdd = () => {
    let temp = _.cloneDeep(form)
    const lastIndex = temp.length - 1
    const attribute = _.cloneDeep(temp[lastIndex])
    attribute["others_comment"] = ""
    attribute["others_movie"] = ""
    temp.push(attribute)
    setForm(temp)
  }

  const onRemove = () => {
    let temp = _.cloneDeep(form)
    if (temp.length > 1) {
      temp.pop()
      setForm(temp)
    }
  }

  const setFormImage = (index, url) => {
    setImageForm(url.length >= 1 ? url : null)
  }

  // フォームを呼び出し
  const renderForm = useMemo(() => {
    // @ts-ignore
    return (form.map((item, index) => (
      <div className="others" key={`${index}_others`} style={{ width: "100%" }}>
        <TextForm
          id={`others_comment_${index}`}
          name={`others_comment_${index}`}
          title="タイトル"
          value={item["others_comment"]}
          placeholder="例）愛用者コメント（出演またはVTR）開発者コメント"
        />
        <TextForm
          id={`others_movie_${index}`}
          name={`others_movie_${index}`}
          title="動画URL"
          value={item["others_movie"]}
          placeholder="Youtube等へのリンクURL"
        />
      </div>
    )))
  }, [ form ])

  return (
    <section className="main_area_full main_center">
      <h2 className="h2_2">商品新規登録
        <div className="step">STEP <span className="big">11</span>/ <span>13</span></div>
      </h2>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onClick)}>
          <div className="ir_box">
            <h3 className="rh3">出演情報<span className="must">必須項目</span></h3>
            <div className="ir_item multiple_input">
              <div className="ir_head">TV通販実績の有無</div>
              <div className="ir_content">
                <RadioForm
                  id="is_tv_achievement"
                  name="is_tv_achievement"
                  value={is_tv_achievement ? is_tv_achievement.toString() : "0"}
                  selects={[ "あり", "なし" ]}
                  selectsValue={[ "1", "0" ]}
                />
              </div>
            </div>

            <div className="ir_item multiple_input">
              <div className="ir_head">メインタレント<br />出演可否</div>
              <div className="ir_content">
                <RadioForm
                  id="is_approval"
                  name="is_approval"
                  title="出演許諾"
                  value={is_approval ? is_approval.toString() : "0"}
                  selects={[ "OK", "NG" ]}
                  selectsValue={[ "1", "0" ]}
                />
                <TextForm
                  id="talent_name"
                  name="talent_name"
                  title="タレント氏名"
                  value={talent_name}
                  placeholder="例）XXX"
                />
                <ImageForm
                  id="talent_image"
                  name="talent_image"
                  title="写真"
                  value={imageForm}
                  caution={"※形式はJPEG/PNGのみ"}
                  setForm={(index, url) => setFormImage(index, url)}
                />
                <TextForm
                  id="talent_movie_url"
                  name="talent_movie_url"
                  title="自己紹介動画URL"
                  value={talent_movie_url}
                  placeholder="Youtube等へのリンクURL"
                />
              </div>
              {/*<Caution caution={caution} />*/}
            </div>

            <div className="ir_item multiple_input">
              <div className="ir_head">その他参考動画</div>
              <div className="ir_content">
                <div className="step_description">愛用者コメント（出演またはVTR）開発者コメント（VTR）<br />タレントVTRやその他参考出演者動画等があれば登録してください
                </div>
                {renderForm}
                <div className="clone_buttons">
                  <Button onClick={e => onAdd()} className="addBtn" style={{ marginRight: "1rem" }}>追加</Button>
                  <Button onClick={e => onRemove()} variant="outlined">削除</Button>
                </div>
              </div>
            </div>
          </div>

          <div className="form_bottom_btn_wrap">
            <div className="fbbw_box">
              <ButtonComponent text={"下書き保存"} type={"submit"} onClick={() => setIsDraft(true)} />
              <ButtonComponent text={"次へ進む"} type={"submit"} onClick={() => setIsDraft(false)} />
            </div>
          </div>
        </form>
      </FormProvider>

      <DraftModal
        productId={rtnProductId}
        is_show={showDraftModal}
        loading={loading}
        setLoading={e => setLoading(e)}
        set_is_show={e => setShowDraftModal(e)}
      />
    </section>
  )
}

export default Step11
