import * as React from "react"
import { FunctionComponent, useState } from "react"
import { IDealingList } from "./IDealingList"
import { DealingItem } from "../../molecules/DealingItem/DealingItem"

export const DealingList: FunctionComponent<IDealingList> = (props) => {
  const { dealingList } = props

  return (
    <div className="dealing_list">
      {dealingList.map((dealing, index) => (
        <DealingItem key={`${index}_dealing`} {...dealing} />
      ))}
    </div>
  )
}
