import * as React from "react"
import { FunctionComponent, useMemo, useState } from "react"
import { Button, TextareaAutosize, TextField } from "@material-ui/core"
import { FormProvider, Controller, useForm } from "react-hook-form"
import "../../../../styles/edit_notification.css"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { TextForm } from "../../../molecules/TextForm/TextForm"
import { SelectBoxForm } from "../../../molecules/SelectBoxForm/SelectBoxForm"
import { TextAreaForm } from "../../../molecules/TextAreaForm/TextAreaForm"
import { RadioForm } from "../../../molecules/RadioForm/RadioForm"
import { post } from "../../../../common/utils"
import { MESSAGE_NOTICE_UPSERT, SUBMIT_ACCOUNT } from "../../../../common/apiConstants"
import { Loading } from "../../../atoms/Loading/Loading"
import { navigate } from "gatsby"
import { RELATIVE_PATH } from "../../../../common/routePath"
import { MAX_LENGTH } from "../../../../common/yupConstants"
import Backdrop from "@material-ui/core/Backdrop"
import Fade from "@material-ui/core/Fade"
import Modal from "@material-ui/core/Modal"

export const NotificationDetailEdit: FunctionComponent = (props) => {
  let retVal = undefined
  const [ loading, setLoading ] = useState(false)
  const [ showModal, setShowModal ] = useState(false)
  const [ isSendMail, setIsSendMail ] = useState("0")

  const methods = useForm({
    mode: "onBlur",
    resolver: yupResolver(
      yup.object().shape({
        message_to: yup.string().required("必須項目です"),
        title: yup.string().required("必須項目です").max(255, "255" + MAX_LENGTH),
        message: yup.string().required("必須項目です").max(1000, "1000" + MAX_LENGTH),
      })
    )
  })
  const { handleSubmit, formState: { errors } } = methods
  const onSubmitAccount = async data => {
    setLoading(true)
    let paramObj = {}
    paramObj.destination_type = data.message_to
    paramObj.title = data.title
    paramObj.message = data.message
    paramObj.send_mail = data.is_send_mail
    await post(MESSAGE_NOTICE_UPSERT, paramObj).then(response => {
      navigate(RELATIVE_PATH.NOTIFICATIONS)
    }).catch(e => {
      alert(e.message)
    }).finally(() => setLoading(false))
  }

  const changeIsSendMail = e => {
    if (e.target.name === "is_send_mail" && e.target.value === "1") {
      setShowModal(true)
    }
  }

  retVal = (
    <section className="main_area notification_edit">
      <div className="mp_product_title">
        <div className="list_title_box">
          <h2 className="top_title_h2">お知らせ登録</h2>
        </div>
      </div>
      {loading ? <Loading /> : undefined}
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmitAccount)} onChange={changeIsSendMail}>
          <div>
            <div>
              <div className="edit_row">
                <RadioForm
                  id="is_send_mail"
                  name="is_send_mail"
                  title="メール通知："
                  value={isSendMail}
                  selects={[ "無し", "有り" ]}
                  selectsValue={["0", "1"]}
                />
              </div>
              <div className="edit_row">
                <SelectBoxForm
                  id="message_to"
                  name="message_to"
                  title="宛先:"
                  selects={[ "バイヤー", "メーカー", "ALL" ]}
                  selectsValue={[ 2, 1, 0 ]}
                  caution={errors.message_to?.message}
                />
              </div>
            </div>
            <div className="edit_row notification_title">
              <TextForm
                id="title"
                name="title"
                title="件名："
                placeholder="例：ご利用について"
              />
            </div>
            <div className="notification_body">
              <TextAreaForm
                id="message"
                name="message"
                placeholder="例：XXXXXXXXXXXXXXXXXXXX"
                caution={errors.message?.message}
              />
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button variant="contained" size="large" className="submitBtn" type="submit"
                    disabled={Object.keys(errors).length ? true : false}>保存</Button>
          </div>
        </form>
      </FormProvider>

      <Modal
        className="confirm_modal "
        open={showModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showModal}>
          <div className="modal_body">
            <h2 id="transition-modal-title">注意</h2>
            <div style={{marginBottom: 20, lineHeight: 1.8}}>「メール通知：有り」で保存すると<br />宛先のユーザーへメール通知されます</div>
            <span>
              <Button className="positive" variant="contained" onClick={() => setShowModal(false)}>はい</Button>
            </span>
          </div>
        </Fade>
      </Modal>
    </section>
  )

  return retVal
}