import * as React from "react"
import { FunctionComponent, useMemo } from "react"
import { Title } from "../../atoms/Title/Title"
import { Description } from "../../atoms/Description/Description"
import { Caution } from "../../atoms/Caution/Caution"
import { IRadioForm } from "./IRadioForm"
import { useFormContext } from "react-hook-form"
import { Radio, RadioGroup } from "@material-ui/core"
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { string } from "yup"
import { formatItemId } from "../../../common/utils"

export const RadioForm: FunctionComponent<IRadioForm> = (props) => {
  const { register } = useFormContext()
  const { caution, description, id, title, name, value, rules, style, selects, selectsValue } = props

  const renderRequired = () => {
    let retVal = null
    // @ts-ignore
    if (rules && "required" in rules && rules.required) {
      retVal = <span>*</span>
    }
    return retVal
  }

  const renderRadios = useMemo(() => {
    let radios = []
    if (selects && selects.length > 0) {
      if (typeof (selects[0]) == "string") {
        radios = selects.map((select, index) =>
          <label key={`${id}_radio_${index}`}>
            <Radio
              key={`${id}_radio_${index}`}
              value={selectsValue ? selectsValue[index] : select}
              color="default"
              {...register(name, rules)}
            />
            {select}
          </label>
        )
      } else {
        radios = selects.map((select, index) =>
          <label key={`${id}_radio_${index}`}>
            <Radio
              key={`${id}_radio_${index}`}
              value={selectsValue ? selectsValue[index] : select.value}
              color="default"
              {...register(name, rules)}
            />
            {select.label}
          </label>
        )
      }
    }
    return radios
  }, [ selects, value ])

  return (
    <div style={style} className={props["is_column"] ? "ir_sub_item column_item" : "ir_sub_item"} id={formatItemId(name)}>
      {title ?
        <div className="input_title_wrap">
          <Title title={title} />
        </div>
        : null}
      {description ? <Description description={description} /> : null}
      <RadioGroup row defaultValue={value}>
        {renderRadios}
      </RadioGroup>
      {caution ? <div style={{width: "100%"}}><Caution caution={caution} /></div> : null}
    </div>
  )
}
