import * as React from "react"
import { FunctionComponent, useEffect, useState } from "react"
import { RouteProps } from "react-router"
import { useCommonData } from "../../../common/customHooks"
import { Loading } from "../../atoms/Loading/Loading"
import MakersList from "../../templates/MakerList/MakersList"
import "../../../styles/buyer/maker_list.css"
import { listedMakerList } from "../../../common/data/sampleData"
import { get } from "../../../common/utils"
import { COMPANIES, MESSAGE_NOTICE } from "../../../common/apiConstants"

const Makers: FunctionComponent<RouteProps> = () => {
  const [ commonData ] = useCommonData()
  const [type, setType] = useState(1)
  const [word, setWord] = useState()
  const [page, setPage] = useState(1)
  const [data, setData] = useState()

  const changePagination = (page) => {
    setPage(page)
  }
  const onSearch = (word) => {
    setWord(word)
  }

  useEffect( () => {
    (async () => {
      let paramObj = {params: {}}
      paramObj.params.type = type
      word ? paramObj.params.word = word : null
      paramObj.params.page = page
      const result = await get(COMPANIES, paramObj).catch(e => console.log("e", e))
      console.log("result", result)
      // @ts-ignore
      setData(result)
    })()
  }, [page, word])

  return <MakersList {...data} changePagination={e => changePagination(e)} onSearch={e => onSearch(e)} />
}

export default Makers
