import { FunctionComponent, useEffect, useState } from "react"
import * as React from "react"
import { IMemberList } from "./IMemberList"
import "../../../../styles/maker/maker_common.css"
import ProfileSideBar from "../../../organisms/common/profile/ProfileSideBar"
import MemberListForm from "../../../organisms/MemberListForm/MemberListForm"
import { isCompanyAdmin } from "../../../../common/utils"

const MemberList: FunctionComponent<IMemberList> = (props) => {
  const { result, changeSearch, getUserList } = props
  const is_company_admin = isCompanyAdmin()

  return (
    <>
      <main className="main_area_two_col">
        <section className="main_area">
          <h2 className="h2_1">アカウント情報<span className="must_title">必須項目</span></h2>
          <MemberListForm memberList={result} changeSearch={e => changeSearch(e)} getUserList={getUserList}  />
        </section>

        <ProfileSideBar current={"member"} is_company_admin={is_company_admin} />
      </main>
    </>
  )
}

export default MemberList
