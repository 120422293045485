import "../../../common/wdyr"
import { FunctionComponent, useState } from "react"
import * as React from "react"
import "../../../styles/maker/maker_common.css"
import { IApprove } from "./IApprove"
import { isAdmin, post } from "../../../common/utils"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, Grid } from "@material-ui/core"
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline"
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"
import Step2View from "../ProductView/step2/Step2View"
import Step1View from "../ProductView/step1/Step1View"
import { navigate } from "gatsby"
import { PRODUCT_REVIEW } from "../../../common/apiConstants"
import { Loading } from "../../atoms/Loading/Loading"
import { RELATIVE_PATH } from "../../../common/routePath"
import { TextAreaForm } from "../../molecules/TextAreaForm/TextAreaForm"
import { useForm, FormProvider } from "react-hook-form"
import { APPROVAL_TYPES, APPROVED, NOT_APPROVE, UN_APPROVE } from "../../../common/constants"
import * as moment from "moment"
import ProductView from "../ProductView/ProductView"
import Step5View from "../ProductView/step5/Step5View"
import Step6View from "../ProductView/step6/Step6View"
import Step7View from "../ProductView/step7/Step7View"
import Step8View from "../ProductView/step8/Step8View"
import Step9View from "../ProductView/step9/Step9View"
import Step10View from "../ProductView/step10/Step10View"
import Step11View from "../ProductView/step11/Step11View"
import Step12View from "../ProductView/step12/Step12View"
import Backdrop from "@material-ui/core/Backdrop"
import Fade from "@material-ui/core/Fade"
import Modal from "@material-ui/core/Modal"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: 400,
      borderRadius: 5,
      borderColor: "#ccc"
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },
    accountBlock: {
      marginBottom: "2rem",
      width: '100%',
    },
    heading: {
      fontWeight: theme.typography.fontWeightBold,
    },
  }),
)

export const Approve: FunctionComponent<IApprove> = (props) => {
  const { product_info, company, user, review } = props
  const [ loading, setLoading ] = useState(false)
  const [ step1Form, setStep1Form ] = useState(product_info.step1)
  const [ step2Form, setStep2Form ] = useState(product_info.step2)
  const [ step3Form, setStep3Form ] = useState(product_info.step3)
  const [ step4Form, setStep4Form ] = useState(product_info.step4)
  const [ step5Form, setStep5Form ] = useState(product_info.step5)
  const [ step6Form, setStep6Form ] = useState(product_info.step6)
  const [ step7Form, setStep7Form ] = useState(product_info.step7)
  const [ step8Form, setStep8Form ] = useState(product_info.step8)
  const [ step9Form, setStep9Form ] = useState(product_info.step9)
  const [ step10Form, setStep10Form ] = useState(product_info.step10)
  const [ step11Form, setStep11Form ] = useState(product_info.step11)
  const [ step12Form, setStep12Form ] = useState(product_info.step12)
  const [ expandPanel, setExpandPanel ] = useState<string | false>(false)
  const [ submitType, setSubmitType ] = useState<string>()
  const [ showModal, setShowModal ] = useState(false)
  const [ showReturnModal, setShowReturnModal ] = useState(false)
  const [ submitData, setSubmitData ] = useState()
  const classes = useStyles()

  const methods = useForm({
    mode: "onBlur",
  })
  const { handleSubmit } = methods

  const renderProductView = (data: JSX.Element, title, panel, path) => {
    return (
      <Accordion expanded={expandPanel === panel} className="accordion_body" key={panel}>
        <AccordionSummary onClick={() => expandPanel === panel ? setExpandPanel("") : setExpandPanel(panel)}>
          <div className="accordion_title">
            {expandPanel === panel ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />}
            <div className="accordion_title_area">
              <label className={classes.heading}>{title}</label>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails className="input_approve_memo">
          <div>{data}</div>
          <div className="approve_memo">
            <h4>管理者記入欄</h4>
            <TextAreaForm
              id={`approve_memo_${panel}`}
              name={`approve_memo_${panel}`}
              value={review ? review[panel] : undefined}
              placeholder="例）XXXXXXXXXXXXXXXXXXX"
            />
          </div>
        </AccordionDetails>
      </Accordion>
    )
  }

  const onApprove = async () => {
    console.log("onApprove", submitData, submitType)
    let approve_type
    switch (submitType) {
      case "保存":
        approve_type = NOT_APPROVE
        break
      case "承認":
        approve_type = APPROVED
        break
      case "差し戻し":
        approve_type = UN_APPROVE
        break
    }
    let paramObj = {}
    paramObj.id = product_info.step2.id
    paramObj.status = approve_type
    paramObj.step1 = submitData.approve_memo_step1
    paramObj.step2 = submitData.approve_memo_step2
    paramObj.step3 = submitData.approve_memo_step3
    paramObj.step4 = submitData.approve_memo_step4
    paramObj.step5 = submitData.approve_memo_step5
    paramObj.step6 = submitData.approve_memo_step6
    paramObj.step7 = submitData.approve_memo_step7
    paramObj.step8 = submitData.approve_memo_step8
    paramObj.step9 = submitData.approve_memo_step9
    paramObj.step10 = submitData.approve_memo_step10
    paramObj.step11 = submitData.approve_memo_step11
    paramObj.step12 = submitData.approve_memo_step12
    console.log("paramObj", paramObj)
    await post(PRODUCT_REVIEW, paramObj).then(response => {
      console.log("PRODUCT_REVIEW　success", response)
      setShowReturnModal(true)
    }).catch(e => {
      console.log("PRODUCT_REVIEW", e)
    }).finally(() => {
      setLoading(false)
      setShowModal(false)
    })
  }

  const onConfirmApprove = (data) => {
    console.log("onConfirmApprove", data)
    setShowModal(true)
    setSubmitData(data)
  }
  const closeModal = () => {
    setShowModal(false)
    setSubmitData(null)
  }

  return (
    <section className="main_center approve">
      {loading ? <Loading /> : undefined}
      <h2 className="h2_2">商品審査</h2>
      <section className="main_center product_detail">
        <div className={classes.accountBlock}>
          <div style={{ textAlign: "center", marginBottom: 30, fontWeight: "bold", lineHeight: 1.4 }}>
            内容を確認し、「承認」or「差し戻し」ボタンを押してください<br />申請ステータスを変更しないで保存する場合は「保存」ボタンを押してください
          </div>
          <Grid container alignItems="center" className="product_head">
            <Grid item md={2}>
              <img src={company["logo"]} width={120} />
            </Grid>
            <Grid item md={1}>
              <div style={{ marginBottom: 20 }}>メーカー：</div>
              <div>申請者：</div>
            </Grid>
            <Grid item md={2}>
              <div style={{ marginBottom: 20 }}>{company["name"]}</div>
              <div>{user["name"]}</div>
            </Grid>
            <Grid item md={1}>
              <div style={{ marginBottom: 20 }}>ステータス：</div>
              <div>申請日：</div>
            </Grid>
            <Grid item md={2}>
              <div style={{ marginBottom: 20 }}>{APPROVAL_TYPES[step2Form.approval_status]}</div>
              <div>{moment(step12Form.updated_at).format("YYYY/MM/DD hh:mm")}</div>
            </Grid>
          </Grid>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onConfirmApprove)}>
              {step1Form ? renderProductView(
                <Step1View {...step1Form} />, "カテゴリー", "step1", RELATIVE_PATH.STEP1) : undefined}
              {step2Form ? renderProductView(
                <Step2View {...step2Form} />, "商品基本情報", "step2", RELATIVE_PATH.STEP2) : undefined}
              {step3Form ? renderProductView(
                <ProductView {...step3Form} />, "商品仕様", "step3", RELATIVE_PATH.STEP3) : undefined}
              {step4Form ? renderProductView(
                <ProductView {...step4Form} />, "商品分類別情報", "step4", RELATIVE_PATH.STEP4) : undefined}
              {step5Form ? renderProductView(
                <Step5View {...step5Form} />, "返品・サポート情報", "step5", RELATIVE_PATH.STEP5) : undefined}
              {step6Form ? renderProductView(
                <Step6View {...step6Form} />, "セールスポイント - 商品概要", "step6", RELATIVE_PATH.STEP6) : undefined}
              {step7Form ? renderProductView(
                <Step7View {...step7Form} />, "セールスポイント - 効果", "step7", RELATIVE_PATH.STEP7) : undefined}
              {step8Form ? renderProductView(
                <Step8View {...step8Form} />, "セールスポイント - 実績", "step8", RELATIVE_PATH.STEP8) : undefined}
              {step9Form ? renderProductView(
                <Step9View {...step9Form} />, "セールスポイント - 基本", "step9", RELATIVE_PATH.STEP9) : undefined}
              {step10Form ? renderProductView(
                <Step10View {...step10Form} />, "セールスポイント - 実演", "step10", RELATIVE_PATH.STEP10) : undefined}
              {step11Form ? renderProductView(
                <Step11View {...step11Form} />, "セールスポイント - 出演情報", "step11", RELATIVE_PATH.STEP11) : undefined}
              {step12Form ? renderProductView(
                <Step12View {...step12Form} />, "その他", "step12", RELATIVE_PATH.STEP12) : undefined}

              {isAdmin() ? (
                <div className="approve_button_area">
                  <Button variant="outlined" color={"default"} className="save" type={"submit"}
                          onClick={() => setSubmitType("保存")}>
                    保存
                  </Button>
                  <div className="fbbw_box">
                    <Button variant="contained" className="approve" style={{ marginRight: 40 }} type={"submit"}
                            onClick={() => setSubmitType("承認")}>
                      承認
                    </Button>
                    <Button variant="contained" className="un_approve" type={"submit"}
                            onClick={() => setSubmitType("差し戻し")}>
                      差し戻し
                    </Button>
                  </div>
                </div>
              ) : undefined}
            </form>
          </FormProvider>
        </div>

        <Modal
          className="confirm_modal"
          open={showModal}
          onClose={() => setShowModal(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={showModal}>
            <div className="modal_body">
              <h2 id="transition-modal-title">{submitType}します。よろしいですか？</h2>
              <div>
                {loading ? <CircularProgress size={20} className="spinner" /> : undefined}
              </div>
              <Button className="positive" variant="contained" onClick={onApprove}>はい</Button>
              <Button className="negation" variant="outlined" onClick={closeModal}>いいえ</Button>
            </div>
          </Fade>
        </Modal>

        <Modal
          className="confirm_modal"
          open={showReturnModal}
          onClose={() => setShowReturnModal(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={showReturnModal}>
            <div className="modal_body">
              <h2 id="transition-modal-title">{submitType}しました。一覧画面へ戻ります。</h2>
              <Button className="positive" variant="contained" onClick={() => navigate(RELATIVE_PATH.APPROVALS)}>はい</Button>
            </div>
          </Fade>
        </Modal>
      </section>
    </section>
  )
}

export default Approve
