import { FunctionComponent, useEffect, useState } from "react"
import * as React from "react"
import { IAccount } from "./IAccount"
import Header from "../../../organisms/common/header/Header"
import Footer from "../../../organisms/common/footer/Footer"
import "../../../../styles/maker/maker_common.css"
import { Backdrop } from "@material-ui/core"
import BuyerHeadBottom from "../../../organisms/common/header/buyer/BuyerHeadBottom"
import BuyerSideBar from "../../../organisms/common/sidebar/buyer/BuyerSideBar"
import AccountForm from "../../../organisms/AccountForm/AccountForm"
import ProfileSideBar from "../../../organisms/common/profile/ProfileSideBar"
import { isCompanyAdmin } from "../../../../common/utils"

const Account: FunctionComponent<IAccount> = (props) => {
  const { account } = props
  const is_company_admin = isCompanyAdmin()

  return (
    <>
      <main className="main_area_two_col">
        <section className="main_area">
          <h2 className="h2_1">アカウント情報<span className="must_title">必須項目</span></h2>
          <AccountForm
            id={account["userId"]}
            type={account["userType"]}
            name={account["userName"]}
            email={account["userEmail"]}
            company_name={account["company"]["name"]}
            password={""}
            is_edit={true}
          />
        </section>

        <ProfileSideBar current={"account"} is_company_admin={is_company_admin} />
      </main>
    </>
  )
}

export default Account
