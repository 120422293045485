import * as React from "react"
import { FunctionComponent, useEffect, useState } from "react"
import { RouteProps } from "react-router"
import { get } from "../../../common/utils"
import { COMPANIES } from "../../../common/apiConstants"
import { CompanyList } from "../../templates/CompanyList/CompanyList"

const Companies: FunctionComponent<RouteProps> = () => {
  const [data, setData] = useState(null)
  const [companyType, setCompanyType] = useState("")
  const [enableType, setEnableType] = useState("")
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    (async () => {
      await searchCompany()
    })()
  }, [page])

  const searchCompany = async () => {
    setLoading(true)
    setData(null)
    let paramObj = {params: {}}
    companyType !== "" ? paramObj.params.type = companyType : false
    enableType !== "" ? paramObj.params.enable = enableType : false
    paramObj.params.page = page
    const companyList = await get(COMPANIES, paramObj).catch(e => console.log("e", e))
    setData(companyList)
    setLoading(false)
  }

  return (
    <main className="regist">
      <CompanyList
        {...data}
        loading={loading}
        setLoading={setLoading}
        companyType={companyType}
        setCompanyType={setCompanyType}
        enableType={enableType}
        setEnableType={setEnableType}
        setPage={e => setPage(e)}
        searchCompany={searchCompany}
      />
    </main>
  )
}

export default Companies
