import "../../../../common/wdyr"
import { FunctionComponent, useEffect, useMemo, useState } from "react"
import * as React from "react"
import { IStep3 } from "./IStep3"
import { yupResolver } from "@hookform/resolvers/yup"
import { FormProvider, useForm } from "react-hook-form"
import { ProductForm } from "../../../organisms/ProductForm/ProductForm"
import { ButtonComponent } from "../../../atoms/Button/Button"
import "../../../../styles/maker/maker_common.css"
import { RELATIVE_PATH } from "../../../../common/routePath"
import { addLocalDB, onDraftSubmit } from "../../../../common/utils"
import imageCompression from "browser-image-compression"
import DraftModal from "../../../organisms/common/modal/DraftModal"
import _ from "lodash"

export const Step3: FunctionComponent<IStep3> = (props) => {
  const { step3 } = props
  const [ isDraft, setIsDraft ] = React.useState(false)
  const [ showDraftModal, setShowDraftModal ] = React.useState(false)
  const [ rtnProductId, setRtnProductId] = useState()
  const [loading, setLoading] = useState(false)
  const compressOption = { maxSizeMB: 2 }
  // const resolver = rules ? { resolver: yupResolver(rules) } : {}
  const methods = useForm({
    mode: "onBlur",
    // resolver: yupResolver(COSMETIC)
  })

  const filePromise = async (file) => {
    return new Promise(resolve => {
      let fr = new FileReader()
      fr.readAsDataURL(file)
      fr.onload = () => {
        console.log("onload", fr.result)
        resolve(fr.result)
      }
    })
  }

  const { handleSubmit } = methods
  const onNextStep = async (data) => {
    // データ挿入用にコピーを作成
    let step3Temp = _.cloneDeep(step3)
    const dataKeys = Object.keys(data)
    let promiseData: Promise<any>[] = []
    let promiseChild: Promise<any>[] = []

    step3.map((formObj, indexNum) => {
      if (formObj.forms.length >= 1) {
        Object.values(data).map((dataObj, index) => {
          let attributeTemp: Object[] = []
          Object.keys(dataObj).map(async (key, i) => {
            promiseData.push(new Promise(async resolve => {
              let isNotMatch = true
              formObj["forms"][index]["attributes"].map(async (item, j) => {
                if (key === item.name) {
                  isNotMatch = false
                  const copyItem = _.cloneDeep(item)
                  copyItem.name = key
                  if (Number(copyItem.input_type) == 5) {
                    if (dataObj[key].length) {
                      promiseChild.push(filePromise(dataObj[key][0]).then(res => {
                        copyItem.value = res
                      }))
                    }
                  } else if (Number(item.input_type) == 6) {
                    // imageの場合はbase64へ変更
                    if (dataObj[key][0]) {
                      promiseChild.push(imageCompression(dataObj[key][0], compressOption).then(async imageC => {
                        await imageCompression.getDataUrlFromFile(imageC).then(res => {
                          copyItem.value = res
                        })
                      }))
                    }
                  } else if (Number(item.input_type) == 7) {
                    let tableValues = []
                    let objKeyData = {}
                    let holdIndex = "0"
                    const tableKeys = Object.keys(dataObj[key])
                    tableKeys.map((tKey, index) => {
                      const currentIndex = tKey.split("_")[0]
                      if (currentIndex === holdIndex) {
                        objKeyData[tKey.split("_")[2]] = dataObj[key][tKey]
                      } else {
                        holdIndex = currentIndex
                        tableValues.push(objKeyData)
                        objKeyData = {}
                        objKeyData[tKey.split("_")[2]] = dataObj[key][tKey]
                      }
                    })
                    tableValues.push(objKeyData)
                    copyItem.value = tableValues
                  } else {
                    copyItem.value = dataObj[key]
                  }
                  attributeTemp.push(copyItem)
                }
              })

              if (isNotMatch) {
                formObj["forms"][index]["attributes"].map(async (item, j) => {
                  if (key.includes(item.name)) {
                    const copyItem = _.cloneDeep(item)
                    copyItem.name = key

                    if (Number(copyItem.input_type) == 5) {
                      // ファイルの場合はbase64へ変換
                      if (dataObj[key].length) {
                        promiseChild.push(filePromise(dataObj[key][0]).then(res => {
                          copyItem.value = res
                        }))
                      }

                    } else if (Number(item.input_type) == 6) {
                      // imageの場合はbase64へ変更
                      if (dataObj[key][0]) {
                        promiseChild.push(imageCompression(dataObj[key][0], compressOption).then(async imageC => {
                          await imageCompression.getDataUrlFromFile(imageC).then(res => {
                            copyItem.value = res
                          })
                        }))
                      }
                    } else {
                      copyItem.value = dataObj[key]
                    }
                    attributeTemp.push(copyItem)
                  }
                })
              }
              step3Temp[indexNum]["forms"][index]["attributes"] = attributeTemp
              //@ts-ignore
              resolve()
            }))
          })
        })
      }
    })

    await Promise.all(promiseChild)
    await Promise.all(promiseData).then(async () => {
      const dataObj = JSON.stringify({ ...step3Temp })
      if (isDraft) {
        setShowDraftModal(true)
        await onDraftSubmit("step3", dataObj).then(res => {
          // @ts-ignore
          setRtnProductId(res ? res["product_id"] : props["step3"][0]["forms"][0].product_id)
        }).catch(e => console.log("onDraftSubmit", e)).finally(() => setLoading(false))
      } else {
        await addLocalDB("step3", dataObj, RELATIVE_PATH.STEP4)
      }
    })
  }


  // プロダクトフォームを呼び出し
  const renderProductForm = (form) => {
    return (form.map((value, index) => <ProductForm key={`${index}_custom_form`} {...value} />))
  }

  return (
    <section className="main_area_full main_center">
      <h2 className="h2_2">商品新規登録
        <div className="step">STEP <span className="big">3</span>/ <span>13</span></div>
      </h2>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onNextStep)}>
          {step3.map((item, index) => {
            return (
              item.forms.length >= 1 ? (
                <div className="ir_box" style={{ marginBottom: 50 }} key={`step3_${index}`}>
                  {item.title ? <h3 className="rh3">{item.title}<span className="must">必須項目</span></h3> : null}
                  {renderProductForm(item.forms)}
                </div>
              ) : undefined
            )
          })}
          <div className="form_bottom_btn_wrap">
            <div className="fbbw_box">
              <ButtonComponent text={"下書き保存"} type={"submit"} onClick={() => setIsDraft(true)} />
              <ButtonComponent text={"次へ進む"} type={"submit"} onClick={() => setIsDraft(false)} />
            </div>
          </div>
        </form>
      </FormProvider>

      <DraftModal
        productId={rtnProductId}
        is_show={showDraftModal}
        loading={loading}
        setLoading={e => setLoading(e)}
        set_is_show={e => setShowDraftModal(e)}
      />
    </section>
  )
}

export default Step3
