import * as React from "react"
import { FunctionComponent, useState } from "react"
import { Accordion, AccordionDetails, AccordionSummary, Button } from "@material-ui/core"
import { IQuestionDetail } from "./IQuestionDetail"
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline"
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import { isBrowser, renderText } from "../../../../common/utils"
import { TextAreaForm } from "../../../molecules/TextAreaForm/TextAreaForm"
import { useForm, FormProvider } from "react-hook-form"
import { Loading } from "../../../atoms/Loading/Loading"
import Backdrop from "@material-ui/core/Backdrop"
import Fade from "@material-ui/core/Fade"
import Modal from "@material-ui/core/Modal"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: 400,
      borderRadius: 5,
      borderColor: "#ccc"
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },
    accountBlock: {
      marginBottom: "2rem",
      width: '100%',
    },
    heading: {
      fontWeight: theme.typography.fontWeightBold,
    },
  }),
)

export const QuestionDetail: FunctionComponent<IQuestionDetail> = (props) => {
  const [ expandPanel, setExpandPanel ] = useState<number | false>(false)
  const classes = useStyles()
  const {id, questions, name, images, onAnswer, onDelete} = props
  const [ qaId, setQaId ] = useState()
  const [ isSubmit, setIsSubmit ] = useState()
  const [loading, setLoading] = useState(false)
  const [completeModal, setCompleteModal] = useState(false)

  const methods = useForm({
    mode: "onBlur",
  })

  const { handleSubmit } = methods
  const onClick = async data => {
    setLoading(true)
    if (isSubmit) {
      // QA回答処理
      let message = ""
      const keys = Object.keys(data)
      keys.map((key) => {
        if (qaId.toString() === key.split("_")[1]) {
          message = data[key]
        }
      } )

      const submitData = {
        question_id: qaId,
        message: message
      }

      const res = await onAnswer(submitData)
      res ? setCompleteModal(true) : false
    } else {
      // QA削除処理
      const submitData = { question_id: qaId }

      onDelete(submitData)
    }
    setLoading(false)
  }

  let retVal = undefined

  retVal = (
    <section className="main_area notification_list">
      {loading ? <Loading /> : undefined}
      <div className="mp_product_title">
        <div className="list_title_box">
          <h2 className="top_title_h2">QA詳細</h2>
        </div>
      </div>

      <div className="dealing_head">
        <div className="dealing_head_content">
          <div className="dealing_detail_wrap">
            <div>商品：</div>
            <div className="img_with_text">
              <img src={images? images[0].image: ""} width={40} />
              <span className="bold">{name}</span>
            </div>
          </div>
        </div>
      </div>

      <div>
        {questions ? questions.map((question, index) => (
          <Accordion expanded={expandPanel === index} className="accordion_body" key={`${index}_question`}>
            <AccordionSummary onClick={() => expandPanel === index ? setExpandPanel(null) : setExpandPanel(index)}>
              <div className="accordion_title">
                {expandPanel === index ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />}
                <label className={classes.heading}>{renderText(question.question)}</label>
              </div>
            </AccordionSummary>
            <AccordionDetails className="accordion_body ir_box">
              <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onClick)} className="answer_form">
                  <TextAreaForm
                    id={`answer_${question.id}`}
                    name={`answer_${question.id}`}
                    value={question.answer}
                  />
                  <div className="submit_buttons">
                    <Button variant="outlined" type={"submit"} style={{marginRight: "1rem"}} onClick={() => {
                      setIsSubmit(false)
                      setQaId(question.id)
                    }}>削除</Button>
                    <Button className="addBtn" type={"submit"} onClick={() => {
                      setIsSubmit(true)
                      setQaId(question.id)
                    }}>保存</Button>
                  </div>
                </form>
              </FormProvider>
            </AccordionDetails>
          </Accordion>
        )): undefined}
      </div>

      <Modal
        className="confirm_modal"
        open={completeModal}
        onClose={() => setCompleteModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={completeModal}>
          <div className="modal_body">
            <h2 id="transition-modal-title">質問への回答が完了しました。</h2>
            <Button className="negation" variant="outlined" onClick={() => setCompleteModal(false) }>閉じる</Button>
          </div>
        </Fade>
      </Modal>

    </section>
  )


  return retVal
}