import * as React from "react"
import { FunctionComponent } from "react"
import { Title } from "../../atoms/Title/Title"
import { Description } from "../../atoms/Description/Description"
import { Caution } from "../../atoms/Caution/Caution"
import { ITextAreaForm } from "./ITextAreaForm"
import { useFormContext } from "react-hook-form"
import { TextareaAutosize } from "@material-ui/core"
import { formatItemId } from "../../../common/utils"

export const TextAreaForm: FunctionComponent<ITextAreaForm> = (props) => {
  const { register, control, formState: {errors} } = useFormContext()
  const { caution, description, id, title, name, value, placeholder, rules, style } = props

  const renderRequired = () => {
    let retVal = null
    // @ts-ignore
    if (rules && "required" in rules && rules.required) {
      retVal = <span>*</span>
    }
    return retVal
  }

  return (
    <div style={style} className={props["is_column"] ? "ir_sub_item column_item" : "ir_sub_item"} id={formatItemId(name)}>
      {title?  (
        <div className="input_title_wrap">
          <Title title={title} />
          {renderRequired()}
        </div>
      ): null}
      {description? <Description description={description} /> : null}
      <textarea id={id} defaultValue={value} placeholder={placeholder} {...register(name, rules)} />
      {errors[name] ? <p className="error">{errors[name].message}</p> : undefined}
      {caution? <Caution caution={caution} /> : null}
    </div>
  )
}
