import * as React from "react"
import { FunctionComponent, useEffect, useState } from "react"
import { RouteProps } from "react-router"
import { Loading } from "../../atoms/Loading/Loading"
import { get, setHeaderCommonData } from "../../../common/utils"
import { DEALING } from "../../../common/apiConstants"
import Dealing from "../../templates/Message/DealingDetail/Dealing"

const DealingPage: FunctionComponent<RouteProps> = (props) => {
  const { dealing_id } = props.location.state
  const [data, setData] = useState()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    (async () => {
      setLoading(true)
      let paramObj = {params: {}}
      paramObj.params.id = dealing_id
      const result = await get(DEALING, paramObj).catch(e => console.log("e", e))
      // @ts-ignore
      setData(result)
      await setHeaderCommonData()
      setLoading(false)
    })()
  }, [])

  return data ? (
    <Dealing {...data} loading={loading} />
  ) : <Loading />
}

export default DealingPage
