import * as React from "react"
import { FunctionComponent, useState } from "react"
import { IMakerList } from "./IMakerList"
import { MakerItem } from "../../molecules/MakerItem/MakerItem"
import { Box, IconButton, InputBase, Link } from "@material-ui/core"
import SearchIcon from "@material-ui/icons/Search"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import { CustomPagination } from "../../molecules/Pagination/Pagination"
import { Loading } from "../../atoms/Loading/Loading"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: 400,
      borderRadius: 5,
      borderColor: "#ccc"
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },
    accountBlock: {
      marginBottom: "2rem",
      width: '100%',
    },
    heading: {
      fontWeight: theme.typography.fontWeightBold,
    },
  }),
)
export const MakerList: FunctionComponent<IMakerList> = (props) => {
  const { total, data, last_page, current_page, changePagination, onSearch } = props
  const [keyword, setKeyword] = useState<string>()
  const classes = useStyles()

  const searchAccounts = async () => {
    onSearch(keyword)
  }

  return (
    <section className="main_center">
      {data ? (
        <>
          <div className={"list_title_box"}>
            <h2 className={"top_title_h2"}>掲載メーカー一覧</h2>
            <div className={"search_list_num"}>
              <span>{total}</span>
              件見つかりました。
            </div>
          </div>
          <div className={"maker_search_box"}>
            <div className="search_wrap">
              <Box className={classes.root} border={1}>
                <InputBase
                  className={classes.input}
                  defaultValue={keyword}
                  onBlur={e => setKeyword(e.target.value)}
                  inputProps={{ 'aria-label': 'search google maps' }}
                  placeholder="検索したいキーワードを入力"
                />
                <IconButton onClick={searchAccounts} className={classes.iconButton} aria-label="search">
                  <SearchIcon />
                </IconButton>
              </Box>
            </div>
          </div>
          <div className={"maker_list_area"}>
            <div className={"ml_initial_box"}>
              {data.map((marketItem, index) => (
                <MakerItem id={`${index.toString()}`} key={index} {...marketItem} />
              ))}
            </div>
            <CustomPagination current_page={current_page} last_page={last_page} setPage={changePagination} />
          </div>
        </>
      ): <Loading />}
    </section>
  )
}
