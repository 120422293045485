import * as React from "react"
import { FunctionComponent, useEffect, useState } from "react"
import { RouteProps } from "react-router"
import PublicInfo from "../../templates/ProfileSetting/PublicInfo/PublicInfo"
import { useCommonData } from "../../../common/customHooks"
import { Loading } from "../../atoms/Loading/Loading"
import { convertBlobToFile, getLocalUser, post } from "../../../common/utils"
import { get } from "../../../common/utils"
import { BRAND_REGISTER,COMPANY, COMPANY_DETAIL } from "../../../common/apiConstants"
import BrandInfo from "../../templates/ProfileSetting/BrandInfo/BrandInfo"

const ProfileBrandInfo: FunctionComponent<RouteProps> = () => {
  const [ data, setData ] = useState()
  const [ companyId, setCompanyId ] = useState()
  const [ header, setHeader ] = useState()
  const [ loading, setLoading ] = useState(false)
  const localUser = getLocalUser()

  const emptyJson = [
    {
      brand_name: "",
      brand_url: "",
      brand_body: ""
    }
  ]

  useEffect(() => {
    (async () => {
      let paramObj = { params: {} }
      paramObj.params.id = localUser["companyId"]
      const result = await get(COMPANY_DETAIL, paramObj).catch(e => alert("DEALINGS", e))
      console.log("result", result)
      // @ts-ignore
      setCompanyId(result.company.id)
      setData(result.brand_info.length >= 1 ? result.brand_info : emptyJson)
    })()
  }, [])

  const submitBrandInfo = async (data) => {
    let is_success = false
    setLoading(true)
    await post(BRAND_REGISTER, data).then(res => {
      is_success = true
    }).catch(e => {
      is_success = false
      console.log("BRAND_REGISTER error", e)
    }).finally(() => setLoading(false))

    return is_success
  }

  return data ? (
    <BrandInfo
      company_id={companyId}
      brand_info={data}
      submitBrandInfo={e => submitBrandInfo(e)}
      loading={loading}
    />
  ) : <Loading />
}

export default ProfileBrandInfo
