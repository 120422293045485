import { FunctionComponent, useEffect, useState } from "react"
import * as React from "react"
import { IStep2 } from "./IStep2"
import "../../../../styles/maker/maker_common.css"
import { Button } from "@material-ui/core"
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { RadioForm } from "../../../molecules/RadioForm/RadioForm"
import { TextForm } from "../../../molecules/TextForm/TextForm"
import * as _ from "lodash"
import { ImageForm } from "../../../molecules/ImageForm/ImageForm"
import { ButtonComponent } from "../../../atoms/Button/Button"
import { RELATIVE_PATH } from "../../../../common/routePath"
import { addLocalDB, onDraftSubmit } from "../../../../common/utils"
import DraftModal from "../../../organisms/common/modal/DraftModal"
import { MovieForm } from "../../../molecules/MovieForm/MovieForm"

export const Step2: FunctionComponent<IStep2> = (props) => {
  const {
    id,
    name,
    images,
    movie,
    url,
    brand_name,
    brand_url,
    order_brand_name,
    order_brand_url,
    wholesale_price,
    broadcast_price,
    regular_price_type,
    regular_price,
    stock_status,
    stock,
    is_renewal,
    is_selling
  } = props
  const [ isDraft, setIsDraft ] = React.useState(false)
  const [ showDraftModal, setShowDraftModal ] = React.useState(false)
  const [ rtnProductId, setRtnProductId] = useState()
  const [loading, setLoading] = useState(false)
  const [ imageForm, setImageForm ] = useState<any>(images && images.length ? images : [{ image: "" }])
  const [ movieForm, setMovieForm ] = useState()

  const methods = useForm({
    mode: "onBlur",
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string().max(255,"255文字以内").required("必須項目です"),
        image: yup.array().length(1, "必須項目です"),
        url: yup.string().max(1000,"1000文字以内").url("URLが不正です"),
        brand_name: yup.string().max(255,"255文字以内").required("必須項目です"),
        brand_url: yup.string().max(1000,"1000文字以内").url("URLが不正です"),
        order_brand_name: yup.string().max(255,"255文字以内").required("必須項目です"),
        order_brand_url: yup.string().max(1000,"1000文字以内").url("URLが不正です").required("必須項目です"),
        wholesale_price: yup.number().typeError("卸価格は数字で入力してください").required("必須項目です"),
        broadcast_price: yup.mixed().test('match', '不正な金額です', function (broadcast_price) {
          let regular = this.parent.regular_price ? this.parent.regular_price : 0
          if (isNaN(broadcast_price) || !broadcast_price) {
            return false
          } else {
            return Number(regular) >= Number(broadcast_price)
          }
        }),
        regular_price_type: yup.string().required("必須項目です"),
        regular_price: yup.mixed().test('match', '不正な金額です', function (regular_price) {
          if (isNaN(regular_price) || !regular_price) {
            return false
          } else {
            let broadcast_price = this.parent.broadcast_price ? this.parent.broadcast_price : 0
            return Number(regular_price) >= Number(broadcast_price)
          }
        }),
        stock_status: yup.string().required("必須項目です"),
        stock: yup.number().typeError("在庫数は数字で入力してください").required("必須項目です"),
        is_renewal: yup.string().required("必須項目です"),
        is_selling: yup.string().required("必須項目です"),
      })
    )
  })

  const { handleSubmit, control, formState: { errors } } = methods

  const onNextStep = async ( data ) => {
    setLoading(true)
    const dataObj = JSON.stringify({
      id: id ? id : rtnProductId ? rtnProductId : null,
      name: data.name,
      images: imageForm,
      movie: movieForm ? movieForm : null,
      url: data.url,
      brand_name: data.brand_name,
      brand_url: data.brand_url,
      order_brand_name: data.order_brand_name,
      order_brand_url: data.order_brand_url,
      wholesale_price: data.wholesale_price,
      broadcast_price: data.broadcast_price,
      regular_price: data.regular_price,
      regular_price_type: data.regular_price_type,
      stock: data.stock,
      stock_status: data.stock_status,
      is_renewal: data.is_renewal,
      is_selling: data.is_selling
    })
    if (isDraft) {
      setShowDraftModal(true)
      await onDraftSubmit("step2", dataObj).then(res => {
        // @ts-ignore
        setRtnProductId(res ? res["product_id"] : props.id)
      }).catch(e => console.log("onDraftSubmit", e)).finally(() => setLoading(false))
    } else {
      await addLocalDB("step2", dataObj, RELATIVE_PATH.STEP3)
    }
  }

  const onAdd = () => {
    let temp = _.cloneDeep(imageForm)
    const lastIndex = temp.length - 1
    const attribute = _.cloneDeep(temp[lastIndex])
    attribute["image"] = ""
    temp.push(attribute)
    setImageForm(temp)
  }

  const onRemove = () => {
    let temp = _.cloneDeep(imageForm)
    if (temp.length > 1) {
      temp.pop()
      setImageForm(temp)
    }
  }

  const renderForm = () => {
    return (imageForm.map((item, index) => (
      <div className="images" key={`${index}_images`} style={{ width: "100%" }}>
        <ImageForm
          id={`image_${index}`}
          name={`image_${index}`}
          value={item.image}
          caution={"※形式はJPEG/PNGのみ\r\n※容量は最大2MBまで"}
          setForm={(index, url) => setFormImage(index, url)}
        />
      </div>
    )))
  }

  const setFormImage = (index, url) => {
    let temp = _.cloneDeep(imageForm)
    const attribute = _.cloneDeep(temp[index])
    if (attribute) {
      attribute["image"] = url
      temp[index] = attribute
      setImageForm(temp)
    } else {
      temp[index ? index : 0] = { image: "" }
      setImageForm(temp)
    }
  }

  return (
    <section className="main_area_full main_center step_two">
      <h2 className="h2_2">商品新規登録
        <div className="step">STEP <span className="big">2</span>/ <span>13</span></div>
      </h2>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onNextStep)}>
          <div className="ir_box">
            <h3 className="rh3">商品基本情報<span className="must">必須項目</span></h3>
            <div className="ir_item multiple_input">
              <div className="ir_head">商品名</div>
              <div className="ir_content">
                <TextForm
                  id="name"
                  name="name"
                  value={name}
                  placeholder="例）XXX"
                />
              </div>
            </div>
            <div className="ir_item multiple_input">
              <div className="ir_head">商品写真</div>
              <div className="ir_content">
                {errors.image ? <p className="error">{errors.image.message}</p> : undefined}
                {renderForm()}
                <div className="clone_buttons">
                  <Button onClick={e => onAdd()} className="addBtn" style={{ marginRight: "1rem" }}>追加</Button>
                  <Button onClick={e => onRemove()} variant="outlined">削除</Button>
                </div>
              </div>
            </div>
            <div className="ir_item multiple_input add_movie">
              <div className="ir_head">紹介動画</div>
              <div className="ir_content">
                <MovieForm
                  id="movie"
                  name="movie"
                  value={movie}
                  setForm={e => setMovieForm(e)}
                />
              </div>
            </div>
            <div className="ir_item multiple_input">
              <div className="ir_head">商品URL</div>
              <div className="ir_content">
                <TextForm
                  id="url"
                  name="url"
                  value={url}
                  placeholder="例：https://www.yourdomain.co.jp/itemdetail"
                />
              </div>
            </div>
            <div className="ir_item multiple_input">
              <div className="ir_head">ブランド情報</div>
              <div className="ir_content">
                <TextForm
                  id="brand_name"
                  name="brand_name"
                  title="製造販売元"
                  value={brand_name}
                  placeholder="例：VENDIN"
                />
                <TextForm
                  id="brand_url"
                  name="brand_url"
                  title="製造販売元URL"
                  value={brand_url}
                  placeholder="例：https://www.vendin.co.jp"
                />
                <TextForm
                  id="order_brand_name"
                  name="order_brand_name"
                  title="発売元"
                  value={order_brand_name}
                  placeholder="例：VENDINコンシューマー"
                />
                <TextForm
                  id="order_brand_url"
                  name="order_brand_url"
                  title="発売元URL"
                  value={order_brand_url}
                  placeholder="例：https://www.vendin.co.jp"
                />
              </div>
            </div>
          </div>
          <div className="ir_box">
            <h3 className="rh3">価格・在庫情報<span className="must">必須項目</span></h3>
            <div className="ir_item multiple_input">
              <div className="ir_head">価格情報</div>
              <div className="ir_content">
                <TextForm
                  id="wholesale_price"
                  name="wholesale_price"
                  title="卸価格"
                  value={wholesale_price}
                  after_text={"円（税抜）"}
                />
                <RadioForm
                  id="regular_price_type"
                  name="regular_price_type"
                  title="定価（小売価格）"
                  value={ regular_price_type ? regular_price_type.toString() : "0"}
                  selects={[ "メーカー希望小売価格", "メーカー直販価格" ]}
                  selectsValue={["0", "1"]}
                  caution={"メーカー希望小売価格はメーカーサイト上に記載があり、販売を行っていない場合の金額になります。"}
                />
                <TextForm
                  id="regular_price"
                  name="regular_price"
                  value={regular_price}
                  after_text={"円（税抜）"}
                />
                <TextForm
                  id="broadcast_price"
                  name="broadcast_price"
                  title="TV通販の希望販売価格"
                  value={broadcast_price}
                  after_text={"円（税抜）"}
                  caution={"TV通販での想定販売価格をご記載下さい。実際の販売価格に関しては今後交渉・相談が可能です。"}
                />
                {errors.regular_price_type ? <p className="error">必須入力です</p> : undefined}
              </div>
            </div>
            <div className="ir_item multiple_input">
              <div className="ir_head">在庫情報</div>
              <div className="ir_content">
                {errors.stock_status ? <p className="error">必須入力です</p> : undefined}
                <RadioForm
                  id="stock_status"
                  name="stock_status"
                  title={`在庫情報`}
                  value={stock_status ? stock_status.toString() : "0"}
                  selects={[ "あり", "なし（再販予定あり)", "なし（再販予定なし)" ]}
                  selectsValue={["0", "1", "2"]}
                />
                <TextForm
                  id="stock"
                  name="stock"
                  title="在庫数"
                  value={stock}
                  after_text={"個"}
                />
              </div>
            </div>
            <div className="ir_item multiple_input">
              <div className="ir_head">販売状況</div>
              <div className="ir_content">
                {errors.stock_status ? <p className="error">必須入力です</p> : undefined}
                <RadioForm
                  id="is_renewal"
                  name="is_renewal"
                  title={`リニューアル商品`}
                  value={is_renewal ? is_renewal.toString() : "0"}
                  selects={[ "新商品", "リニューアル商品" ]}
                  selectsValue={["0", "1"]}
                />
                <RadioForm
                  id="is_selling"
                  name="is_selling"
                  title={`公開設定`}
                  value={is_selling ? is_selling.toString() : "0"}
                  selects={[ "未公開", "公開" ]}
                  selectsValue={["0", "1"]}
                />
              </div>
            </div>
            <div className="form_bottom_btn_wrap">
              <div className="fbbw_box">
                <ButtonComponent text={"下書き保存"} type={"submit"} onClick={() => setIsDraft(true)} />
                <ButtonComponent text={"次へ進む"} type={"submit"} onClick={() => setIsDraft(false)} />
              </div>
            </div>
          </div>
        </form>
      </FormProvider>

      <DraftModal
        productId={rtnProductId}
        is_show={showDraftModal}
        loading={loading}
        setLoading={e => setLoading(e)}
        set_is_show={e => setShowDraftModal(e)}
      />
    </section>
  )
}

export default Step2