import * as React from "react"
import { FunctionComponent, useMemo, useState } from "react"
import { Title } from "../../atoms/Title/Title"
import { Description } from "../../atoms/Description/Description"
import { Caution } from "../../atoms/Caution/Caution"
import { IFileForm } from "./IFileForm"
import { useFormContext } from "react-hook-form"
import CancelIcon from '@material-ui/icons/Cancel'
import { convertDataURItoBlob, convertDataURItoBlobUrl } from "../../../common/utils"
import { array } from "yup"

export const FileForm: FunctionComponent<IFileForm> = (props) => {
  const { register } = useFormContext()
  const { caution, description, id, title, name, value, placeholder, rules, style, setForm } = props
  const [ fileValue, setFileValue ] = useState(value)
  const [ isFileTypeError, setIsFileTypeError ] = useState(false)
  const [loading, setLoading] = useState(false)
  const compressOption = { maxSizeMB: 2 }

  const fileField = register(name)

  const handleFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files === null || event.target.files.length === 0) {
      return
    }
    const files = event.target.files

    // fileが存在しない、image以外の場合はエラー
    if (files.length == 0 || ![ "application/pdf" ].includes(files[0]["type"])) {
      setIsFileTypeError(true)
      return false
    } else {
      setIsFileTypeError(false)
      let reader = new FileReader()
      reader.readAsDataURL(files[0])
      reader.onloadend = (e) => {
        let base64data = reader.result
        setFileValue(base64data)
        if (setForm) {
          setForm(event.target.name.split("_")[1], base64data)
        }
      }

      // setFileValue(blobUrl)
      // setForm(event.target.name.split("_")[1], blobUrl)
    }
  }

  const renderRequired = () => {
    let retVal = null
    // @ts-ignore
    if (rules && "required" in rules && rules.required) {
      retVal = <span>*</span>
    }
    return retVal
  }

  const onDeleteFile = () => {
    const names = name.split("_")
    setFileValue(null)
    if (setForm) {
      if (names.length >= 3) {
        setForm(names[1], "", names[2])
      } else {
        setForm(names[1], "")
      }
    }
  }

  const urlStartWith = (url) => {
    let dataUrl
    if (url instanceof Array) {
      dataUrl = url[0]
    } else {
      dataUrl = url
    }
    if (dataUrl && dataUrl.startsWith("data:")) {
      return (
        <div>
          <a id={`${id}_file`} href={convertDataURItoBlobUrl(dataUrl)} target="_blank">ファイルを開く</a>
          <CancelIcon onClick={e => onDeleteFile(e)} />
        </div>
      )
    } else if (dataUrl && dataUrl.startsWith("http")) {
      return (
        <div>
          <a id={`${id}_file`} href={dataUrl} target="_blank">ファイルを開く</a>
          <CancelIcon onClick={e => onDeleteFile(e)} />
        </div>
      )
    } else {
      return undefined
    }
  }

  const renderDom = () => {
    let retVal = (
      <div className="file_wrap">
        <div>
          <label htmlFor={`${id}_file_upload`} className="file_label">
            ファイルを選択
            <input
              id={`${id}_file_upload`}
              className="file_upload"
              type={"file"}
              accept={"application/pdf"}
              placeholder={placeholder}
              alt={fileValue}
              {...fileField}
              onChange={ async (e) => {
                setLoading(true)
                fileField.onChange(e)
                await handleFile(e)
                setLoading(false)
              }}
            />
          </label>
          {caution ? <Caution caution={caution} /> : null}
        </div>
        {fileValue ? urlStartWith(fileValue) : undefined}
      </div>
    )

    return retVal
  }

  return (
    <div style={style} className={props["is_column"] ? "ir_sub_item column_item" : "ir_sub_item"}>
      {title ? (
        <div className="input_title_wrap">
          <Title title={title} />
          {renderRequired()}
        </div>
      ) : null}
      {description ? <Description description={description} /> : null}
      {renderDom()}
      {/*{caution ? <Caution caution={caution} /> : null}*/}
    </div>
  )
}
