import * as React from "react"
import { FunctionComponent } from "react"
import { Button, FormControl, MenuItem, Select } from "@material-ui/core"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { INotificationList } from "./INotificationList"
import { Message } from "../../../molecules/Message/Message"
import { CustomPagination } from "../../../molecules/Pagination/Pagination"
import { Loading } from "../../../atoms/Loading/Loading"
import { isAdmin } from "../../../../common/utils"
import { navigate } from "gatsby"
import { RELATIVE_PATH } from "../../../../common/routePath"

export const NotificationList: FunctionComponent<INotificationList> = (props) => {
  const { total, data, current_page, last_page, changePagination } = props
  const [ sort, setSort ] = React.useState('')

  const handleChange = (event) => {
    setSort(event.target.value)
  }

  return (
    <section className="main_area notification_list">
      {data ? (
        <>
          <div className="mp_product_title">
            <div className="list_title_box">
              <h2 className="top_title_h2">お知らせ一覧</h2>
              <div className="search_list_num"><span>{total ? total : 0}</span>件見つかりました</div>
            </div>
          </div>

          <div>
            {data.map((message, index) => (
              <Message key={`${index}_message_row`} {...message} />
            ))}
          </div>
          <CustomPagination current_page={current_page} last_page={last_page} setPage={changePagination} />
        </>
      ) : <Loading />}
      {isAdmin() ? (
        <div className="new_buttons">
          <Button className="completion new_btn" variant="contained" onClick={() => navigate(RELATIVE_PATH.NOTIFICATION_DETAIL_EDIT)}>新規作成</Button>
        </div>
      ) : undefined }
    </section>
  )
}