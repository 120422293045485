import "../../../../common/wdyr";
import { FunctionComponent } from "react"
import * as React from "react"
import { IStep9 } from "../../AddProduct/step9/IStep9"
import "../../../../styles/maker/maker_common.css"
import { OuterLink } from "../../../atoms/OuterLink/OuterLink"
import { changeMultipleLines, convertDataURItoBlobUrl } from "../../../../common/utils"


export const Step9View: FunctionComponent<IStep9> = (props) => {
  const {sales_points} = props

  return (
    <section className="product_view_wrap">
      {sales_points.map((row, index) => (
        <div className="product_view_row" key={row.title} >
          <div className="ir_head" > {index + 1} </div>
          <div className="view_content">
            <div className="view_item">
              <div className="view_title" >{row.title} </div>
            </div>
            <div className="view_item">
              <div className="view_value" >{changeMultipleLines(row.sales_point)} </div>
            </div>

            {row.sales_documents.map((item, index) => {
              let docUrl = item.sales_document.startsWith("data:") ? convertDataURItoBlobUrl(item.sales_document) : item.sales_document
              return (
                <div className="view_value" key={`sales_document_${index}`} >
                  <div className="view_title">添付資料</div>
                  <div className="view_item">
                    <div className="view_value">
                      <p>
                        {docUrl ? (
                          <a id={`${index}_file`} href={docUrl} target="_blank" >ファイルを開く</a>
                        ) : "添付なし"}
                      </p>
                    </div>
                  </div>
                  <div className="view_title">資料URL</div>
                  <div className="view_item">
                    <div className="view_value"><OuterLink url={item.sales_url} title={item.sales_url} /></div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      ))}
    </section>
  )
}
export default Step9View