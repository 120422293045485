import * as React from "react"
import { FunctionComponent, useEffect, useState } from "react"
import { RouteProps } from "react-router"
import { Loading } from "../../atoms/Loading/Loading"
import Step10 from "../../templates/AddProduct/step10/Step10"
import Dexie from "dexie"

const Step10Page: FunctionComponent<RouteProps> = (props) => {
  const [ data, setData ] = useState()
  const [ id, setId ] = useState(props.id)

  const emptyJson = {
    sales_points: [
      {
        sales_body: "",
        sales_documents: [
          {
            sales_document: "",
            sales_url: ""
          },
        ]
      }
    ]
  }

  useEffect(() => {
    (async () => {
      const db = new Dexie("vendinDB")
      db.version(1).stores({ productForm: 'formName', })
      db.open()
      db.productForm.get("step10").then(res => {
        if (res) {
          const tempForm = JSON.parse(res.form)
          console.log("tempForm", tempForm)
          if (tempForm.sales_points[0].sales_body) {
            // @ts-ignore
            setData(JSON.parse(res.form))
          } else {
            emptyJson["sales_points"][0]["id"] = tempForm.sales_points[0]["id"]
            emptyJson["sales_points"][0]["product_id"] = tempForm.sales_points[0]["product_id"]
            // @ts-ignore
            setData(emptyJson)
          }
        } else {
          // @ts-ignore
          setData(emptyJson)
        }
      }).catch(e => {
        console.log("getLocalDB e", e)
      })
    })()
  }, [])

  return data ? (
    <main className="regist">
      <Step10 {...data} />
    </main>
  ) : <Loading />
}

export default Step10Page
