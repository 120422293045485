import * as React from "react"
import { FunctionComponent } from "react"
import { IDealingItem } from "./IDealingItem"
import { Chip } from "@material-ui/core"
import { Link } from "gatsby"
import { RELATIVE_PATH } from "../../../common/routePath"

export const DealingItem: FunctionComponent<IDealingItem> = (props) => {
  const { id, product, unread } = props

  return (
    <div className="dealing_item">
      <Link to={RELATIVE_PATH.DEALING_DETAIL} state={{ dealing_id: id }} className="dealing_link_item" color="inherit">
        <div className="dealing_link_img" style={{height: 120, width: 125}}>
          <img src={product.images[0].image} style={{height: 120, width: "100%"}} alt="" className="dealing_img"/>
        </div>
        {unread ? <Chip label={"対応が必要です"} className="redChip dealing_status" size="small" /> : undefined}
      </Link>
    </div>
  )
}
