import * as React from "react"
import { FunctionComponent } from "react"
import { FormControl, InputLabel, MenuItem, NativeSelect, Select } from "@material-ui/core"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Pagination } from "@material-ui/lab"
import { IQuestionList } from "./IQuestionList"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableBody from "@material-ui/core/TableBody"
import TableContainer from "@material-ui/core/TableContainer"
import { withStyles } from "@material-ui/core/styles"
import TableCell from "@material-ui/core/TableCell"
import * as moment from "moment"
import { navigate } from "gatsby"
import { RELATIVE_PATH } from "../../../../common/routePath"
import { CustomPagination } from "../../../molecules/Pagination/Pagination"

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell)

export const QuestionList: FunctionComponent<IQuestionList> = (props) => {
  const { data, current_page, last_page, setPage } = props
  let retVal = undefined
  const [ sort, setSort ] = React.useState('')

  const handleChange = (event) => {
    setSort(event.target.value)
  }

  const rowClick = (event, row) => {
    console.log(event, row)
    let data = {question: row.questions}
    console.log(data)
    navigate(RELATIVE_PATH.QUESTION, {state: {id : row.id}})
  }

  retVal = (
    <section className="main_area notification_list">
      <div className="mp_product_title">
        <div className="list_title_box">
          <h2 className="top_title_h2">QA一覧</h2>
          <div className="search_list_num"><span>{data.total ? data.total : 0}</span>件見つかりました</div>
        </div>
      </div>

      <div style={{marginBottom: 20}}>
        <TableContainer>
          <Table className="vendin_table" aria-label="vendin_table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">No.</StyledTableCell>
                <StyledTableCell align="left">商品名</StyledTableCell>
                <StyledTableCell align="left">最終更新日</StyledTableCell>
                <StyledTableCell align="left">QA件数</StyledTableCell>
                <StyledTableCell align="left">未回答</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow className="clickEnabled" key={row.id} onClick={(e) => rowClick(e, row)}>
                  <StyledTableCell align="left">{index + 1}</StyledTableCell>
                  <StyledTableCell align="left" className="bold">
                    <div className="img_with_text"><img src={row.images && row.images[0] ? row.images[0].image: ""} width={40} /><span
                      className="bold">{row.name}</span></div>
                  </StyledTableCell>
                  <StyledTableCell align="left">{row.questions_max_updated_at ? moment(row.questions_max_updated_at).format("YYYY/MM/DD HH:mm"): "-"}</StyledTableCell>
                  <StyledTableCell align="left"><span className="bold">{row.questions_count}</span></StyledTableCell>
                  <StyledTableCell align="left">{row.unanswered_count}</StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <CustomPagination current_page={current_page} last_page={last_page} setPage={setPage} />
    </section>
  )


  return retVal
}