import { FunctionComponent, useEffect, useState } from "react"
import * as React from "react"
import "../../../../styles/message/message.css"
import MessageSideBar from "../../../organisms/common/message/MessageSideBar"
import { IQuestions } from "./IQuestions"
import { QuestionList } from "../../../organisms/Message/QuestionList/QuestionList"

const Questions: FunctionComponent<IQuestions> = (props) => {

  return (
    <main className="main_area_two_col">
      <QuestionList {...props} />

      <MessageSideBar current={"question"} />
    </main>
  )
}

export default Questions
