import { FunctionComponent, useEffect, useState } from "react"
import * as React from "react"
import "../../../../styles/message/message.css"
import MessageSideBar from "../../../organisms/common/message/MessageSideBar"
import { IQuestion } from "./IQuestion"
import { QuestionDetail } from "../../../organisms/Message/QuestionDetail/QuestionDetail"

const Question: FunctionComponent<IQuestion> = (props) => {
  const { data, onAnswer, onDelete } = props

  return (
    <main className="main_area_two_col">
      <QuestionDetail
        {...data}
        onAnswer={onAnswer}
        onDelete={onDelete}
      />

      <MessageSideBar current={"question"} />
    </main>
  )
}

export default Question
