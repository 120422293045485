import * as React from "react"
import { FunctionComponent, useState } from "react"
import { IMessage } from "./IMessage"
import { BUYER, BUYER_ADMIN, USER_TYPE } from "../../../common/constants"
import * as moment from "moment"
import { RELATIVE_PATH } from "../../../common/routePath"
import { Link, navigate } from "gatsby"
import { getUserType } from "../../../common/utils"

export const Message: FunctionComponent<IMessage> = (props) => {
  const { id, title, sub_title, message, updated_at, updated_user } = props

  console.log("Message", props.message_type, props)

  const returnPath = () => {
    let path
    switch (props.message_type) {
      case "ProductQa":
        let userType = getUserType()
        if (Number(userType) === BUYER || Number(userType) === BUYER_ADMIN) {
          navigate(RELATIVE_PATH.PRODUCT, { state: { product_id: props.product_id } })
        } else {
          navigate(RELATIVE_PATH.QUESTION, { state: { id: props.product_id } })
        }
        break
      case "Notice":
        navigate(RELATIVE_PATH.NOTIFICATION_DETAIL + "/?id" + id)
        break
      case "DealingMessage":
        navigate(RELATIVE_PATH.DEALING_DETAIL, {state:{dealing_id: props.dealing_id}})
        break
      default :
        navigate(RELATIVE_PATH.NOTIFICATION_DETAIL + "/?id" + id)
        break
    }
    return path
  }

  const returnTitle = () => {
    let rtnTitle
    switch (props.message_type) {
      case "ProductQa":
        rtnTitle = "質問が投稿されました"
        break
      case "Notice":
        rtnTitle = title
        break
      case "DealingMessage":
        rtnTitle = "新規商談メッセージがあります"
        break
      default :
        rtnTitle = title
        break
    }
    return rtnTitle
  }

  return (
    <div className="message_row" onClick={() => returnPath()}>
      <div className="message_link_item">
        <label className="updated_at">{moment(updated_at).format("YYYY/MM/DD HH:mm")}</label>
        {updated_user ? <label className="user_type">{USER_TYPE[updated_user.type]}</label> : undefined}
        {updated_user && updated_user.company ?
          <label className="company_name">{updated_user.company.name}</label> : undefined}
        <label className="title sub_title">{returnTitle()}</label>
        <label className="gap">-</label>
        <label className="body">{props.question ? props.question : message}</label>
      </div>
    </div>
  )
}
