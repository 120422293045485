import * as React from "react"
import { FunctionComponent, useEffect } from "react"
import { RouteProps } from "react-router"
import { account } from "../../../common/data/sampleData"
import Account from "../../templates/ProfileSetting/Account/Account"
import { useCommonData } from "../../../common/customHooks"
import { Loading } from "../../atoms/Loading/Loading"
import { FormProvider, useForm } from "react-hook-form"
import { getLocalUser } from "../../../common/utils"

const ProfileAccount: FunctionComponent<RouteProps> = () => {
  const [commonData] = useCommonData()
  const method = useForm({ mode: "onBlur" })
  const localUser = getLocalUser()

  let renderCompornents = commonData ? (
      <Account account={localUser} />
  ) : <Loading />

  return (
    <FormProvider {...method}>
      {renderCompornents}
    </FormProvider>
  )
}

export default ProfileAccount
