import * as React from "react"
import { FunctionComponent, useEffect, useMemo, useState } from "react"
import { RouteProps } from "react-router"
import { products } from "../../../common/data/sampleData"
import ProductsTemp from "../../templates/Products/Products"
import { FormProvider, useForm } from "react-hook-form"
import { PRODUCTS } from "../../../common/apiConstants"
import { get, getUserType } from "../../../common/utils"

const Products: FunctionComponent<RouteProps> = (props) => {
  const method = useForm({ mode: "onBlur" })
  const {category_id, sub_category_id, category_name, word} = props.location.state
  const [jsonData, setJsonData] = useState()
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [keyword, setKeyword] = useState("")
  const [priceStart, setPriceStart] = useState(0)
  const [priceEnd, setPriceEnd] = useState(1000000)
  const [searchStatus, setSearchStatus] = useState({
    search: "",
    new: false,
    renewal: false,
    tv: false,
    unFavorite: false,
    unStock: false,
    actor: false
  })
  const [sort, setSort] = useState(5)

  useEffect( () => {
        (async () => {
          await onGetProducts()
        })()
  }, [page, category_id, sub_category_id, word, sort ])

  const onGetProducts = async () => {
    setLoading(true)
    let paramObj = {params: {}}
    paramObj.params.page = page
    paramObj.params.with = 0
    if (keyword) {
      paramObj.params.keyword = keyword
    } else if (word) {
      paramObj.params.keyword = word
    }
    paramObj.params.price_start = priceStart
    paramObj.params.price_end = priceEnd >= 100000 ? 1000000 : priceEnd
    category_id ? paramObj.params.category = category_id : false
    sub_category_id ? paramObj.params.sub_category = sub_category_id : false
    searchStatus["new"] ? paramObj.params.is_new = searchStatus["new"] ? 1 : 0 : false
    searchStatus["renewal"] ? paramObj.params.is_renewal = searchStatus["renewal"] ? 1  : 0 : false
    searchStatus["tv"] ? paramObj.params.has_broadcast = searchStatus["tv"] ? 1  : 0 : false
    searchStatus["unStock"] ? paramObj.params.has_stock = searchStatus["unStock"] ? 1  : 0 : false
    searchStatus["actor"] ? paramObj.params.has_actor = searchStatus["actor"] ? 1  : 0 : false
    paramObj.params.sort = sort

    await get(PRODUCTS, paramObj).then(response => {
      const dataObj = {
        products: {
          total: response.total,
          data: response.data,
          current_page: response.current_page,
          last_page: response.last_page,
        }
      }
      setJsonData(dataObj)
    }).catch(e => {
      console.log("e", e)
    }).finally(() => {
      setLoading(false)
    })
  }

  const renderProductList = () => {
    return (
      <ProductsTemp
        {...jsonData}
        loading={loading}
        categoryName={category_name}
        sort={sort}
        setPage={e => setPage(e)}
        setKeyword={e => setKeyword(e)}
        setPriceStart={e => setPriceStart(e)}
        setPriceEnd={e => setPriceEnd(e)}
        searchStatus={searchStatus}
        setSearchStatus={e => setSearchStatus(e)}
        setSort={e => setSort(e)}
        onGetProducts={onGetProducts}
      />
    )
  }

  return (
    <FormProvider {...method}>
      {renderProductList()}
    </FormProvider>
  )
}

export default Products
