import * as React from "react"
import { FunctionComponent, useEffect, useState } from "react"
import { RouteProps } from "react-router"
import { products } from "../../../common/data/sampleData"
import ProductTemp from "../../templates/Product/Product"
import { FormProvider, useForm } from "react-hook-form"
import { DEALINGS, PRODUCT_ALL_INFORMATION } from "../../../common/apiConstants"
import { get, getUserType } from "../../../common/utils"
import { Loading } from "../../atoms/Loading/Loading"

const Product: FunctionComponent<RouteProps> = (props) => {
  const method = useForm({ mode: "onBlur" })
  const [jsonData, setJsonData] = useState()
  const [productId, setProductId] = useState(props.location.state["product_id"])
  const [isDealing, setIsDealing] = useState(false)

  useEffect( () => {
        (async () => {
          let paramObj = {params: {}}
          paramObj.params.id = productId
          await get(PRODUCT_ALL_INFORMATION, paramObj).then(async response => {
            // 商談一覧取得
            const dealing = await get(DEALINGS, {params: { status: 0 }}).catch(e => console.log("e", e))
            if (dealing.data) {
              // 商談一覧に該当の商品IDがいればフラグ立て
              dealing.data.map((item) => item.product_id === productId ? setIsDealing(true) : false)
            }
            setJsonData(response)
          }).catch(e => {
            console.log("e", e)
          })
        })()
  }, [productId])

  return jsonData ? (
    <FormProvider {...method}>
      <ProductTemp {...jsonData} isDealing={isDealing} />
    </FormProvider>
  ) : <Loading />
}

export default Product
