import { FunctionComponent } from "react"
import * as React from "react"
import "../../../../styles/common.css"
import Modal from "@material-ui/core/Modal"
import Backdrop from "@material-ui/core/Backdrop"
import Fade from "@material-ui/core/Fade"
import {IDraftModal} from "./IDraftModal"
import { Button, CircularProgress } from "@material-ui/core"
import { navigate } from "gatsby"
import { RELATIVE_PATH } from "../../../../common/routePath"
import { delInLocalDbProducts } from "../../../../common/utils"

export const DraftModal: FunctionComponent<IDraftModal> = (props) => {
  const {productId, is_show, set_is_show, loading, setLoading} = props

  const setDraftProduct = async () => {
    setLoading(true)
    await delInLocalDbProducts(productId).catch(e => console.log("setDraftProduct", e)).finally(() => setLoading(false))
    set_is_show(false)
  }

  return (
      <Modal
        className="confirm_modal"
        open={is_show}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={is_show}>
          <div className="modal_body">
            <h2 id="transition-modal-title">下書き保存をしました</h2>
            <div style={{marginBottom: 20}}>入力を続けますか？</div>
            <div>
              {loading ? <CircularProgress size={20} className="spinner" /> : undefined}
            </div>
            <Button className="positive" variant="contained" onClick={() => setDraftProduct()}>はい</Button>
            <Button className="positive" variant="contained" onClick={() => navigate(RELATIVE_PATH.CREATED_PRODUCTS)}>いいえ</Button>
          </div>
        </Fade>
      </Modal>
  )
}

export default DraftModal
