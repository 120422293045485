import "../../../../common/wdyr"
import { FunctionComponent } from "react"
import * as React from "react"
import "../../../../styles/maker/maker_common.css"
import { IStep5 } from "../../AddProduct/step5/IStep5"


const Step5View: FunctionComponent<IStep5> = (props) => {
  const {
    after_service,
    contact_company,
    division_name,
    contact_person,
    reception_time_from,
    reception_time_to,
    contact_company_tel,
    contact_company_fax,
    contact_company_address,
    contact_company_mail_address,
    return_name,
    return_post,
    return_prefectures,
    return_address,
    return_tel,
    return_fax,
    pl_insurance_number
  } = props

  return (
    <section className="product_view_wrap">
      <div className="product_view_row">
        <div className="ir_head">アフターサービス</div>
        <div className="view_content">
          <div className="view_title">アフターサービスの有無</div>
          <div className="view_item">
            <div className="view_value">{Number(after_service) ? "あり" : "なし"}</div>
          </div>
          <div className="view_title">窓口会社名</div>
          <div className="view_item">
            <div className="view_value">{contact_company ? contact_company : "未記入"}</div>
          </div>
          <div className="view_title">部署名</div>
          <div className="view_item">
            <div className="view_value">{division_name ? division_name : "未記入"}</div>
          </div>
          <div className="view_title">担当者</div>
          <div className="view_item">
            <div className="view_value">{contact_person ? contact_person : "未記入"}</div>
          </div>
          <div className="view_title">受付時間</div>
          <div className="view_item">
            <div className="view_value">
              {reception_time_from && reception_time_to ? (
                reception_time_from + " ～ " + reception_time_to
              ) : "未記入"}
            </div>
          </div>
          <div className="view_title">電話番号</div>
          <div className="view_item">
            <div className="view_value">{contact_company_tel ? contact_company_tel : "未記入"}</div>
          </div>
          <div className="view_title">FAX</div>
          <div className="view_item">
            <div className="view_value">{contact_company_fax ? contact_company_fax : "未記入"}</div>
          </div>
          <div className="view_title">住所</div>
          <div className="view_item">
            <div className="view_value">{contact_company_address ? contact_company_address : "未記入"}</div>
          </div>
          <div className="view_title">Eメールアドレス</div>
          <div className="view_item">
            <div className="view_value">{contact_company_mail_address ? contact_company_mail_address : "未記入"}</div>
          </div>
        </div>
      </div>

      <div className="product_view_row">
        <div className="ir_head">返品先情報</div>
        <div className="view_content">
          <div className="view_title">返品先情報</div>
          <div className="view_item">
            <div className="view_value">{return_name}</div>
          </div>
          <div className="view_title">郵便番号</div>
          <div className="view_item">
            <div className="view_value">{return_post}</div>
          </div>
          <div className="view_title">都道府県</div>
          <div className="view_item">
            <div className="view_value">{return_prefectures}</div>
          </div>
          <div className="view_title">以降の住所</div>
          <div className="view_item">
            <div className="view_value">{return_address}</div>
          </div>
          <div className="view_title">電話番号</div>
          <div className="view_item">
            <div className="view_value">{return_tel}</div>
          </div>
          <div className="view_title">FAX番号</div>
          <div className="view_item">
            <div className="view_value">{return_fax}</div>
          </div>
        </div>
      </div>

      <div className="product_view_row">
        <div className="ir_head">保険情報（PL保険等）</div>
        <div className="view_content">
          <div className="view_title">保険証番号</div>
          <div className="view_item">
            <div className="view_value">{pl_insurance_number}</div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Step5View