import * as React from "react"
import { FunctionComponent, useState } from "react"
import { IProductListSideBar } from "./IProductListSideBar"
import { Button, Checkbox, FormControlLabel, Link, TextField, Tooltip } from "@material-ui/core"
import Slider from '@material-ui/core/Slider'
import { useForm, FormProvider } from "react-hook-form"

const valueLabelFormat = (value: number) => {
  return (value * 1000).toLocaleString() + "円"
}

const ValueLabelComponent = (props) => {
  const { children, open, value, setKeyword } = props
  return <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>{children}</Tooltip>
}

export const ProductListSideBar: FunctionComponent<IProductListSideBar> = (props) => {
  const { current, setKeyword, setPriceStart, setPriceEnd, setSearchStatus, searchStatus, onGetProducts } = props
  const [ value, setValue ] = React.useState([ 0, 100 ])
  const [ searchVal, setSearchVal] = useState()
  const [ state, setState ] = React.useState(searchStatus)

  const methods = useForm({
    mode: "onBlur",
  })
  const { handleSubmit } = methods

  const handleChange = (event, newValue) => {
    setValue(newValue)
    setPriceStart(newValue[0] ? newValue[0]*1000 : 0)
    setPriceEnd(newValue[1] ? newValue[1]*1000 : 0)
  }

  const checkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.checked })
    setSearchStatus({ ...state, [event.target.name]: event.target.checked })
  }

  const onBlurSearch = (event) => {
    setSearchVal(event.target.value)
  }

  const onSearch = () => {
    setKeyword(searchVal)
    setPriceStart(value[0] ? value[0]*1000 : 0)
    setPriceEnd(value[1] ? value[1]*1000 : 0)
    setSearchStatus(state)
    onGetProducts()
  }

  const renderMaxPrice = (priceMin, priceMax) => {
    let rtnPrice
    const price = priceMax > priceMin ? priceMax * 1000 : priceMin * 1000
    if (price >= 100000) {
      rtnPrice = price.toLocaleString() + "円以上"
    } else {
      rtnPrice = price.toLocaleString() + "円"
    }
    return rtnPrice
  }

  return (
    <section className="side_area_filter">
      <h2 className="product_filter_title">絞り込み検索</h2>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSearch)}>
        <TextField
          variant="outlined"
          className="search"
          id={"search"}
          type={"text"}
          name={"search"}
          onBlur={onBlurSearch}
          placeholder={"キーワード検索"} />

        <h3 className="product_filter_title">金額指定</h3>
        <Slider
          value={value}
          onChange={handleChange}
          ValueLabelComponent={ValueLabelComponent}
          valueLabelDisplay="auto"
          aria-labelledby="range-slider"
          step={1}
          style={{ color: '#666666' }}
          valueLabelFormat={valueLabelFormat}
        />
        <p className="price_label">
          {(value[1] > value[0] ? value[0] * 1000 : value[1] * 1000).toLocaleString()}円 -　
          {renderMaxPrice(value[0], value[1])}
        </p>
        <h3 className="product_filter_title">詳細条件</h3>
        <div className="detail_filter">
          <FormControlLabel
            control={<Checkbox checked={state.new} onChange={checkChange} name="new" color="default" />}
            label="新着商品"
          />
          {/*TODO: 商品登録にはリニューアルに関する項目が無いが不要？*/}
          <FormControlLabel
            control={<Checkbox checked={state.renewal} onChange={checkChange} name="renewal" color="default" />}
            label="リニューアル商品"
          />
          <FormControlLabel
            control={<Checkbox checked={state.tv} onChange={checkChange} name="tv" color="default" />}
            label="TV通販実績あり"
          />
          <FormControlLabel
            control={<Checkbox checked={state.unFavorite} onChange={checkChange} name="unFavorite" color="default" />}
            label="お気に入り商品を除く"
          />
          <FormControlLabel
            control={<Checkbox checked={state.unStock} onChange={checkChange} name="unStock" color="default" />}
            label="在庫なしを除外"
          />
          <FormControlLabel
            control={<Checkbox checked={state.actor} onChange={checkChange} name="actor" color="default" />}
            label="タレント出演検討可"
          />
        </div>
        <Button variant="contained" size="large" className="submitBtn" fullWidth={true} type="submit">絞り込み</Button>
      </form>
      </FormProvider>
    </section>
  )
}

export default ProductListSideBar
