import * as React from "react"
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { FunctionComponent, useMemo, useState } from "react"
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import CloseIcon from '@material-ui/icons/Close'
import Modal from "@material-ui/core/Modal"
import { Button } from "@material-ui/core"
import Backdrop from "@material-ui/core/Backdrop"
import Fade from "@material-ui/core/Fade"
import { IFavoriteProductList } from "./IFavoriteProductList"
import { IProductItem } from "../ProductItem/IProductItem"
import { del } from "../../../common/utils"
import { FAVORITE, MESSAGE_NOTICE_DELETE } from "../../../common/apiConstants"
import { Loading } from "../../atoms/Loading/Loading"
import { RELATIVE_PATH } from "../../../common/routePath"
import { navigate } from "gatsby"

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell)

export const FavoriteProducts: FunctionComponent<IFavoriteProductList> = (props) => {
  const { favoriteProductList, setDeleteId } = props
  const [ addedFavorite, setDelFavorite ] = useState(false)
  const [ delmsg, setDelmsg ] = useState()
  const [ success, setSuccess ] = useState(false)
  const [ delId, setDelId ] = useState()
  const [loading, setLoading] = useState(false)

  const rowClick = (event, row) => {
    navigate(RELATIVE_PATH.PRODUCT, {state:{product_id: row.product_id}})
  }
  //お気に入り
  // TODO APIを叩く
  const onFavorite = async (e, row) => {
    setDelmsg(row.product.name)
    setDelFavorite(true)
    setDelId(row.product.id)
  }

  const submitDelete = async () => {
    setLoading(true)
    let paramObj = { params: {} }
    paramObj.params.product_id = delId
    await del(FAVORITE, paramObj).then(() => {
      setSuccess(true)
      setDeleteId(delId)
    }).catch(e => {
      alert(e)
    }).finally(() => {
      setLoading(false)
    })
  }

  const renderFavoriteList = useMemo(() => {
    return (
      <TableBody>
        {favoriteProductList.map((row) => (
          <TableRow className="clickEnabled" key={row.id}>
            <StyledTableCell align="left" onClick={(e) => rowClick(e, row)}>{row.product.id}</StyledTableCell>
            <StyledTableCell align="left" className="bold" onClick={(e) => rowClick(e, row)}>
              <div className="img_with_text">
                {row.product.images.length >= 1 ? (
                  <img src={row.product["images"].shift().image} width={40} />) : undefined}<span
                className="bold">{row.product.name}</span>
              </div>
            </StyledTableCell>
            <StyledTableCell align="left" onClick={(e) => rowClick(e, row)}><span className="bold">{row.product.brand_name}</span></StyledTableCell>
            <StyledTableCell align="left" onClick={(e) => rowClick(e, row)}>¥{row.product.regular_price.toLocaleString()}(税抜)</StyledTableCell>
            <StyledTableCell align="left" onClick={(e) => rowClick(e, row)}>{row.product.stock.toLocaleString()}</StyledTableCell>
            <StyledTableCell align="right" className="bold">
              <div className="bold" onClick={(e) => onFavorite(e, row)}>
                <span className="bold" style={{ paddingRight: 30 }}><CloseIcon /></span>
              </div>
            </StyledTableCell>
          </TableRow>
        ))}
      </TableBody>
    )
  }, [favoriteProductList])

  return (
    <>
      {loading ? <Loading /> : undefined}
      <TableContainer>
        <Table className="vendin_table" aria-label="vendin_table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">No.</StyledTableCell>
              <StyledTableCell align="left">商品名</StyledTableCell>
              <StyledTableCell align="left">ブランド名</StyledTableCell>
              <StyledTableCell align="left">定価</StyledTableCell>
              <StyledTableCell align="left">在庫数</StyledTableCell>
              <StyledTableCell align="left"></StyledTableCell>
            </TableRow>
          </TableHead>
          {renderFavoriteList}
        </Table>
      </TableContainer>
      <Modal
        className="confirm_modal "
        open={addedFavorite}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={addedFavorite}>
          <div className="modal_body">
            {success ? <h2 id="transition-modal-title">お気に入りから削除しました</h2> :
              <h2 id="transition-modal-title">{delmsg} をお気に入りから削除しますか？</h2>}
            <span>
              {success ? (
                <Button className="positive" variant="contained" onClick={() => setDelFavorite(false)}>閉じる</Button>
              ) : (
                <>
                  <Button className="positive" variant="contained" onClick={() => submitDelete()}>はい</Button>
                  <Button className="positive" variant="contained" onClick={() => setDelFavorite(false)}>いいえ</Button>
                </>
              )}
            </span>
          </div>
        </Fade>
      </Modal>
    </>
  )
}
export default FavoriteProducts
