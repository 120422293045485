import * as React from "react"
import { FunctionComponent, useMemo, useState } from "react"
import { Title } from "../../atoms/Title/Title"
import { Description } from "../../atoms/Description/Description"
import { Caution } from "../../atoms/Caution/Caution"
import { IImageForm } from "./IImageForm"
import { useFormContext } from "react-hook-form"
import imageCompression from 'browser-image-compression'
import CancelIcon from '@material-ui/icons/Cancel'
import { Loading } from "../../atoms/Loading/Loading"
import { isBrowser } from "../../../common/utils"

export const ImageForm: FunctionComponent<IImageForm> = (props) => {
  const { register, formState: {errors}, setValue } = useFormContext()
  const { caution, description, id, title, name, value, placeholder, rules, style, setForm } = props
  const [ imageValue, setImageValue ] = useState(value)
  const [ isFileTypeError, setIsFileTypeError ] = useState(false)
  const [loading, setLoading] = useState(false)
  const compressOption = { maxSizeMB: 2 }

  const imageField = register(name)

  const handleFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files === null || event.target.files.length === 0) {
      setForm(event.target.name.split("_")[1], "")
      return
    }
    const files = event.target.files

    // fileが存在しない、image以外の場合はエラー
    if (files.length == 0 || ![ "image/jpeg", "image/png" ].includes(files[0]["type"])) {
      setIsFileTypeError(true)
      setForm(event.target.name.split("_")[1], "")
      return false
    } else {
      setIsFileTypeError(false)
      const compressFile = await imageCompression(files[0], compressOption)
      const blobUrl = await imageCompression.getDataUrlFromFile(compressFile)
      setImageValue(blobUrl)
      if (setForm) {
        setForm(event.target.name.split("_")[1], blobUrl)
      }
    }
  }

  const renderRequired = () => {
    let retVal = null
    // @ts-ignore
    if (rules && "required" in rules && rules.required) {
      retVal = <span>*</span>
    }
    return retVal
  }

  const onDeleteImage = () => {
    const names = name.split("_")
    setImageValue(null)
    if (setForm) {
      setForm(names[1], "")
      setValue(name, "")
    }
  }

  const renderDom = () => {
    let retVal = (
      <div className="thumbnail_wrap">
        {imageValue ? <div className="thumbnail"><img src={imageValue} alt={"image preview"} /><span><CancelIcon onClick={() => onDeleteImage()} /></span></div> : undefined }
        <div className="label_wrap">
          <label htmlFor={`${id}_file_upload`} className="file_label">
            ファイルを選択
            <input
              id={`${id}_file_upload`}
              name={name}
              type={"file"}
              key={imageValue}
              alt={imageValue}
              {...imageField}
              onChange={ async (e) => {
                setLoading(true)
                imageField.onChange(e)
                await handleFile(e)
                setLoading(false)
              }}
              accept={"image/*"}
              className="file_upload"
            />
          </label>
          {caution ? <Caution caution={caution} /> : null}
          {errors[name]? <p className="error">{errors[name].message}</p> : undefined}
        </div>
      </div>
    )

    return retVal
  }

  return (
    <div style={style} className={props["is_column"] ? "ir_sub_item column_item" : "ir_sub_item"}>
      {loading ? <Loading /> : undefined}
      {title ? (
        <div className="input_title_wrap clearfix">
          <Title title={title} />
          {renderRequired()}
        </div>
      ) : null}
      {description ? <Description description={description} /> : null}
      {renderDom()}
      {isFileTypeError ? <div className="file_error">アップロードは2MB以下のJPEG/PNGのみ</div> : undefined}
    </div>
  )
}
