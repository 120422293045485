import { FunctionComponent, useEffect, useState } from "react"
import * as React from "react"
import "../../../../styles/message/message.css"
import MessageSideBar from "../../../organisms/common/message/MessageSideBar"
import { IApprovals } from "./IApprovals"
import { ApprovalList } from "../../../organisms/Message/ApprovalList/ApprovalList"

const Approvals: FunctionComponent<IApprovals> = (props) => {
  const { total, data, is_admin, search, approveType, setSearch, setApproveType } = props

  return (
    <main className={is_admin ? "regist" : "main_area_two_col"}>
      <ApprovalList
        total={total}
        data={data}
        current_page={props["current_page"]}
        last_page={props["last_page"]}
        setPage={props["setPage"]}
        search={search}
        approveType={approveType}
        setSearch={setSearch}
        setApproveType={setApproveType}
      />

      {is_admin ? undefined : <MessageSideBar current={"approval"} />}
    </main>
  )
}

export default Approvals
