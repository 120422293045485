import { FunctionComponent, useEffect, useState } from "react"
import * as React from "react"
import "../../../styles/message/message.css"
import { ICreated } from "./ICreated"
import { CreatedProducts } from "../../organisms/CreatedProducts/CreatedProducts"

const Created: FunctionComponent<ICreated> = (props) => {
  const { total, current_page, last_page, setPage, data, is_admin, search, approveType, setSearch, setApproveType } = props

  return (
    <main className={is_admin ? "regist" : "main_area_two_col"}>
      <CreatedProducts
        total={total}
        current_page={current_page}
        last_page={last_page}
        setPage={setPage}
        data={data}
        search={search}
        approveType={approveType}
        setSearch={setSearch}
        setApproveType={setApproveType} />
    </main>
  )
}

export default Created
