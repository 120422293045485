import * as React from "react"
import { FunctionComponent, useEffect, useState } from "react"
import { RouteProps } from "react-router"
import { Loading } from "../../atoms/Loading/Loading"
import { get } from "../../../common/utils"
import { SUB_CATEGORIES } from "../../../common/apiConstants"
import Step6 from "../../templates/AddProduct/step6/Step6"
import Step7 from "../../templates/AddProduct/step7/Step7"
import Dexie from "dexie"

const Step7Page: FunctionComponent<RouteProps> = (props) => {
  const [ data, setData ] = useState()
  const [ id, setId ] = useState(props.id)

  const emptyJson = {
    effects: [
      {
        effect_title: "",
        effect_documents: [
          {
            effect_document: "",
            effect_url: ""
          },
        ]
      }
    ]
  }

  useEffect(() => {
    (async () => {
      const db = new Dexie("vendinDB")
      db.version(1).stores({ productForm: 'formName', })
      db.open()
      db.productForm.get("step7").then(res => {
        if (res) {
          const tempForm = JSON.parse(res.form)
          if (tempForm.effects[0].effect_title) {
            // @ts-ignore
            setData(JSON.parse(res.form))
          } else {
            emptyJson["effects"][0]["id"] = tempForm.effects[0]["id"]
            emptyJson["effects"][0]["product_id"] = tempForm.effects[0]["product_id"]
            // @ts-ignore
            setData(emptyJson)
          }
        } else {
          // @ts-ignore
          setData(emptyJson)
        }
      }).catch(e => {
        console.log("getLocalDB e", e)
      })
    })()
  }, [])

  return data ? (
    <main className="regist">
      <Step7 {...data} />
    </main>
  ) : <Loading />
}

export default Step7Page
