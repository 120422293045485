import { FunctionComponent, useEffect, useState } from "react"
import * as React from "react"
import "../../../../styles/message/message.css"
import MessageSideBar from "../../../organisms/common/message/MessageSideBar"
import { IDealing } from "./IDealing"
import { DealingDetail } from "../../../organisms/Message/DealingDetail/DealingDetail"

const Dealing: FunctionComponent<IDealing> = (props) => {

  return (
    <main className="main_area_two_col">
      <DealingDetail {...props} />

      <MessageSideBar current={"dealing"} />
    </main>
  )
}

export default Dealing
