import * as React from "react"
import "../../../../../styles/common.css"
import {FunctionComponent} from "react";
import {NEWS_TYPE} from "../../../../../common/constants"
import * as moment from "moment"
import {IMakerHeadBottom} from "./IMakerHeadBottom"
import { Link } from "gatsby"
import { RELATIVE_PATH } from "../../../../../common/routePath"
import { getLocalUser } from "../../../../../common/utils"

const MakerHeadBottom:FunctionComponent<IMakerHeadBottom> = (props) => {
    const { news_release, showSideBar } = props
    const localUser = getLocalUser()

    return (
        <div className="head_lower_wrap">
            <div className="head_lower_box">
                <div className="h_side_btn_wrap">
                    <div className="h_side_btn" onClick={showSideBar} color="initial">商品管理</div>
                </div>
                <div className="h_news_wrap">
                    <div className="h_n_cate">{NEWS_TYPE[news_release["type"]]}</div>
                    <div className="h_n_day">{news_release["created_at"] ? moment(news_release["created_at"]).format("M/DD") : ""}</div>
                    <Link to={RELATIVE_PATH.NOTIFICATION_DETAIL + "/?id" + news_release.id} className="h_n_title">{news_release["title"]}</Link>
                    <Link to={RELATIVE_PATH.NOTIFICATIONS} className="h_n_list_link">お知らせ一覧</Link>
                </div>
                <div style={{position: "absolute", right: "26%"}} >{localUser.company.name}</div>
            </div>
        </div>
    )
}

export default MakerHeadBottom