import * as React from "react"
import { FunctionComponent, useEffect, useState } from "react"
import { RouteProps } from "react-router"
import { Loading } from "../../atoms/Loading/Loading"
import { get } from "../../../common/utils"
import { PRODUCT_REGISTER_FORM, SUB_CATEGORIES } from "../../../common/apiConstants"
import Step1 from "../../templates/AddProduct/step1/Step1"
import Step3 from "../../templates/AddProduct/step3/Step3"
import Dexie from "dexie"

const Step3Page: FunctionComponent<RouteProps> = (props) => {
  const [ data, setData ] = useState()

  useEffect(() => {
    (async () => {
      const localFile = JSON.parse(localStorage.getItem("product_forms"))
      const db = new Dexie("vendinDB")
      db.version(1).stores({ productForm: 'formName', })
      db.open()
      db.productForm.get("step3").then(res => {
        if (res) {
          const hoge = Object.values(JSON.parse(res.form))
          console.log("hoge", hoge)
          // @ts-ignore
          setData(Object.values(JSON.parse(res.form)))
        } else {
          // @ts-ignore
          setData(localFile.product_info.step3)
        }
      }).catch(e => {
        console.log("getLocalDB e", e)
      })
    })()
  }, [])

  return data ? (
      <main className="regist">
        <Step3 step3={data} />
      </main>
  ) : <Loading />
}

export default Step3Page
