import { FunctionComponent, useEffect, useState } from "react"
import * as React from "react"
import { IStep1 } from "./IStep1"
import "../../../../styles/maker/maker_common.css"
import { Button, FormControl, MenuItem, Select } from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { ButtonComponent } from "../../../atoms/Button/Button"
import { navigate } from "gatsby"
import { RELATIVE_PATH } from "../../../../common/routePath"
import { Loading } from "../../../atoms/Loading/Loading"
import { addLocalDB, get } from "../../../../common/utils"
import { PRODUCT_REGISTER_FORM, SUB_CATEGORIES } from "../../../../common/apiConstants"

export const Step1: FunctionComponent<IStep1> = (props) => {
  const { categories, selected } = props
  const [ item, setItem ] = React.useState(selected)
  const [ loading, setLoading ] = useState(false)

  const handleChange = (event) => {
    setItem(event.target.value)
  }

  const onNextStep = async () => {
    localStorage.removeItem("step1")
    let paramObj = { params: {} }
    paramObj.params.id = Number(item)
    const data = await get(PRODUCT_REGISTER_FORM, paramObj).catch(e => console.log("PRODUCT_REGISTER_FORM", e))
    localStorage.setItem("product_forms", JSON.stringify(data))

    await addLocalDB("step1", JSON.stringify({ category: item }), RELATIVE_PATH.STEP2)
  }

return (
  <section className="main_center step step_one">
    <h2 className="h2_2">商品新規登録
      <div className="step">STEP <span className="big">1</span>/ <span>13</span></div>
    </h2>
    {loading ? <Loading /> : undefined}
    <form>
      <div className="ir_box">
        <h3 className="rh3">商品カテゴリ選択<span className="must">必須項目</span></h3>
        <FormControl variant="outlined" className="select1">
          <Select
            displayEmpty
            labelId=""
            id=""
            value={item}
            native
            onChange={handleChange}
            IconComponent={props => (
              <ExpandMoreIcon {...props} className={`material-icons ${props.className}`}>
              </ExpandMoreIcon>
            )}
          >
            <option value="">カテゴリを選択してください</option>
            {categories.map((item, index) => {
              let optionGroup = null
              if (item.sub_categories.length >= 1) {
                optionGroup = (
                  <optgroup key={`${index}_custom_form`} label={item.title}>
                    {item.sub_categories.map((subItem, i) => {
                      return <option className="select_option" key={`${i}_sub`}
                                     value={subItem.id}>{subItem.title}</option>
                    })}
                  </optgroup>
                )
              }
              return optionGroup
            })}
          </Select>
        </FormControl>
      </div>

      <div className="form_bottom_btn_wrap">
        <div className="fbbw_box">
          <Button onClick={onNextStep}>次へ進む</Button>
        </div>
      </div>
    </form>
  </section>
)
}

export default Step1