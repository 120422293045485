import { FunctionComponent, useEffect, useState } from "react"
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import * as React from "react"
import "../../../styles/common.css"
import { IMemberListForm } from "./IMemberListForm"
import SearchIcon from '@material-ui/icons/Search'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  IconButton,
  InputBase,
  Paper
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import AccountForm from "../AccountForm/AccountForm"
import Modal from "@material-ui/core/Modal"
import Backdrop from "@material-ui/core/Backdrop"
import Fade from "@material-ui/core/Fade"
import { getLocalUser } from "../../../common/utils"
import { RELATIVE_PATH } from "../../../common/routePath"
import { navigate } from "gatsby"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: 400,
      borderRadius: 5,
      borderColor: "#ccc"
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },
    accountBlock: {
      marginBottom: "2rem",
      width: '100%',
    },
    heading: {
      fontWeight: theme.typography.fontWeightBold,
    },
  }),
)

export const MemberListForm: FunctionComponent<IMemberListForm> = (props) => {
  const { memberList, changeSearch, getUserList } = props
  const [ accountModal, setAccountModal ] = useState(false)
  const [ success, setSuccess ] = useState(false)
  const [ keyword, setKeyword ] = useState<string>("")
  const classes = useStyles()
  const localUser = getLocalUser()

  const setShowModal = () => {
    setSuccess(true)
  }

  const searchAccounts = async () => {
    changeSearch(keyword)
  }

  return (
    <>
      <div className="account_manage_wrap">
        <div className="search_wrap">
          <Box className={classes.root} border={1}>
            <InputBase
              className={classes.input}
              defaultValue={keyword}
              onBlur={e => setKeyword(e.target.value)}
              inputProps={{ 'aria-label': 'search google maps' }}
            />
            <IconButton onClick={searchAccounts} className={classes.iconButton} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Box>
        </div>

        <div className={classes.accountBlock}>
          {memberList ? (
            memberList.map((account, index) => (
              // ログインユーザー以外を表示する
              account.id !== parseInt(localUser["userId"]) ? (
                <Accordion key={`${index}_account_manage`}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <label className={classes.heading}>{account.name}</label>
                  </AccordionSummary>
                  <AccordionDetails className="member_account">
                    <AccountForm
                      id={account.id}
                      type={account.type}
                      name={account.name}
                      email={account.email}
                      company_name={account.company_name ? account.company_name : localUser["company"]["name"]}
                      password={account.password}
                      is_edit={true}
                      getUserList={getUserList}
                    />
                  </AccordionDetails>
                </Accordion>
              ) : undefined
            ))
          ) : undefined}
        </div>
        <div className="item_left">
          <Button variant="contained" size="large" className="submitBtn"
                  onClick={() => setAccountModal(true)}>追加</Button>
        </div>
      </div>

      <Modal
        className="confirm_modal account_modal"
        open={accountModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={accountModal}>
          <div className="modal_body account_modal_width">
            <h2 id="transition-modal-title">担当者アカウント追加</h2>
            <div>
              {success ?
                <div className="success_message">担当者アカウントを作成しました</div> :
                <AccountForm is_edit={false} company_name={localUser["company"]["name"]} setShowModal={() => setShowModal()} />}
            </div>
            {success ? <Button className="positive" variant="contained" onClick={() => location.reload()}>閉じる</Button> :
              <Button className="positive" variant="contained" onClick={() => setAccountModal(false)}>閉じる</Button> }
          </div>
        </Fade>
      </Modal>
    </>
  )
}

export default MemberListForm
