import { FunctionComponent } from "react"
import * as React from "react"
import { IFavoriteListPage } from "./IFavoriteList"
import "../../../styles/maker/maker_common.css"
import FavoriteProductForm from "../../organisms/FavoriteProductForm/FavoriteProductForm"

const FavoriteList: FunctionComponent<IFavoriteListPage> = (props) => {
  const {favoriteProductList, setDeleteId} = props
  return (
    <main className="top_title_box">
      <FavoriteProductForm favoriteProductList={favoriteProductList} setDeleteId={setDeleteId} />
    </main>
  )
}

export default FavoriteList
