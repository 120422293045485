import * as React from "react"
import { IOuterLink } from "./IOuterLink"
import LaunchIcon from '@material-ui/icons/Launch'

export const OuterLink = React.memo((props: IOuterLink) => {
  if (props.url) {
    return (
      <a href={props.url} className="outer_link" target={"_blank"} ><span>{props.title}</span><LaunchIcon fontSize={"small"} /></a>
    )
  } else {
    return "未記入"
  }
})
