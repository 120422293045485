import * as React from "react"
import { FunctionComponent, useEffect, useState } from "react"
import { RouteProps } from "react-router"
import { useCommonData } from "../../../common/customHooks"
import { Loading } from "../../atoms/Loading/Loading"
import Approvals from "../../templates/Message/ApprovalList/Approvals"
import {approvals} from "../../../common/data/sampleData"
import { get, getUserType, isAdmin } from "../../../common/utils"
import { PRODUCTS } from "../../../common/apiConstants"
import { NOT_APPROVE } from "../../../common/constants"

const ApprovalsPage: FunctionComponent<RouteProps> = () => {
  const [ data, setData ] = useState()
  const [ page, setPage ] = useState(1)
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState("")
  const [approveType, setApproveType] = useState(NOT_APPROVE.toString())
  const is_admin = isAdmin()

  useEffect(() => {
    (async () => {
      setLoading(true)
      let paramObj = {params: {}}
      paramObj.params.page = page
      paramObj.params.with = 0
      paramObj.params.approve = approveType
      search ? paramObj.params.keyword = search : false
      await get(PRODUCTS, paramObj).then(response => {
        setData(response)
      }).finally(() => {
        setLoading(false)
      })
      // // @ts-ignore
      // setData(data)
    })()
  }, [search, approveType, page])

  return data ? (
    <Approvals
      {...data}
      is_admin={is_admin}
      search={search}
      approveType={approveType}
      setPage={e => setPage(e)}
      setSearch={e => setSearch(e)}
      setApproveType={e => setApproveType(e)}
    />
  ) : <Loading />
}

export default ApprovalsPage
