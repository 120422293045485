import * as React from "react"
import { FunctionComponent, useMemo } from "react"
import { Title } from "../../atoms/Title/Title"
import { Description } from "../../atoms/Description/Description"
import { Caution } from "../../atoms/Caution/Caution"
import { ICheckBoxForm } from "./ICheckBoxForm"
import { useFormContext } from "react-hook-form"
import { Checkbox } from "@material-ui/core"
import { formatItemId } from "../../../common/utils"

export const CheckBoxForm: FunctionComponent<ICheckBoxForm> = (props) => {
  const { register } = useFormContext()
  const { caution, description, id, title, name, value, rules, style, selects } = props

  const renderRequired = () => {
    let retVal = null
    // @ts-ignore
    if (rules && "required" in rules && rules.required) {
      retVal = <span>*</span>
    }
    return retVal
  }

  const renderCheckbox = useMemo(() => {
    let radios = []
    if (selects && selects.length > 0) {
      if (typeof (selects[0]) == "string") {
        radios = selects.map((select, index) => {
          const isChecked = value ? !!value.find(val => val === select) : false
          return (
            <label key={`${id}_check_${index}`} className="check_label" {...register(name, rules)}>
              <Checkbox value={select} defaultChecked={isChecked} name={name} color="default" />
              {select}
            </label>
          )
        })
      } else {
        radios = selects.map((select, index) => {
          const isChecked = value ? !!value.find(val => val === select) : false
          return (
            <label key={`${id}_check_${index}`} className="check_label">
              <Checkbox value={select} defaultChecked={isChecked} name={name} color="default" {...register(name, rules)} />
              {select.label}
            </label>
          )
        })
      }
    }
    return radios
  }, [ selects, value ])

  return (
    <div className={"ir_sub_item"} style={style} id={formatItemId(name)}>
      {title ? <div style={{width: "100%"}}><Title title={title} /></div> : null}
      {renderRequired()}
      {description ? <Description description={description} /> : null}
      {renderCheckbox}
      {caution ? <div style={{width: "100%"}}><Caution caution={caution} /></div> : null}
    </div>
  )
}
