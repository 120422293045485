import * as React from "react"
import "../../../../../styles/common.css"
import { FunctionComponent, useEffect, useState } from "react"
import { post } from "../../../../../common/utils"
import Modal from "@material-ui/core/Modal"
import Backdrop from "@material-ui/core/Backdrop"
import Fade from "@material-ui/core/Fade"
import { Button, CircularProgress } from "@material-ui/core"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { TextForm } from "../../../../molecules/TextForm/TextForm"
import { TextAreaForm } from "../../../../molecules/TextAreaForm/TextAreaForm"
import { CONTACT } from "../../../../../common/apiConstants"

const Contact: FunctionComponent = (props) => {
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    if (!showModal) {
      methods.setValue("title", "")
      methods.setValue("message", "")
    }
  }, [showModal])

  const methods = useForm({
    mode: "onBlur",
    resolver: yupResolver(
      yup.object().shape({
        title: yup.string().required("必須"),
        message: yup.string().required("必須")
      })
    ),
    defaultValues: {
      title: "",
      message: ""
    }
  })

  const { handleSubmit, control, formState: { errors } } = methods

  const onSubmit = async (data) => {
    setLoading(true)
    let paramObj = {}
    paramObj.title = data.title
    paramObj.message = data.message

    await post(CONTACT, paramObj).then(res => {
      setSuccess(true)
    }).catch(e => {
      console.log("CONTACT ERROR", e)
    }).finally(() => {
      setLoading(false)
    })
  }

  return (
    <>
      <p onClick={() => setShowModal(true)}>お問い合わせ</p>
      <Modal
        className="confirm_modal account_modal"
        open={showModal}
        onClose={() => setShowModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showModal}>
          <div className="modal_body">
            {success ? (
              <>
                <h2 className="contact_title">お問い合わせありがとうございました。</h2>
                <Button
                  variant="contained"
                  size="large"
                  className="login_button"
                  onClick={() => {
                    setShowModal(false)
                    setSuccess(false)
                  }}
                >
                  閉じる
                </Button>
              </>
            ) : (
              <>
                <h2 className="contact_title">お問い合わせ内容を記入し、「送信」ボタンを押してください。</h2>
                <FormProvider {...methods}>
                  <form method="post" autoComplete="on" onSubmit={handleSubmit(onSubmit)}>
                    <div className="contact_input_wrap">
                      <div className="title">
                        <TextForm
                          id="title"
                          name="title"
                          title="件名"
                          placeholder="○○○について"
                          rules={{ required: true }}
                        />
                      </div>
                      <div className="message">
                        <TextAreaForm
                          id="message"
                          name="message"
                          title="お問い合わせ内容"
                          rules={{ required: true }}
                        />
                      </div>
                    </div>
                    <Button variant="contained" size="large" className="login_button" type="submit" startIcon={loading ? <CircularProgress size={20} color={"inherit"} /> : null}>
                      送信
                    </Button>
                  </form>
                </FormProvider>
              </>
            )}
          </div>
        </Fade>
      </Modal>
    </>
  )
}

export default Contact