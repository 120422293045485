import * as React from "react"
import { FunctionComponent, useEffect, useState } from "react"
import { RouteProps } from "react-router"
import { Loading } from "../../atoms/Loading/Loading"
import Maker from "../../templates/Maker/Maker"
import { get } from "../../../common/utils"
import { COMPANY_DETAIL, MAKER_PRODUCTS } from "../../../common/apiConstants"
import MakerProducts from "../../templates/Maker/MakerProducts"

const MakerProductsView: FunctionComponent<RouteProps> = (props) => {
  const {category_id, category_name, company_id} = props.location.state
  const [ data, setData ] = useState()
  const [ page, setPage ] = useState(1)
  const [ loading, setLoading ] = useState(false)
  const [sort, setSort] = useState(0)

  useEffect(() => {
    (async () => {
      setLoading(true)
      let paramObj = { params: {} }
      paramObj.params.id = company_id
      const companyDetail = await get(COMPANY_DETAIL, paramObj).catch(e => console.log("e", e))
      paramObj.params.page = page
      category_id ? paramObj.params.category_id = category_id : false
      const makerProduct = await get(MAKER_PRODUCTS, paramObj).catch(e => console.log("e", e))
      // @ts-ignore
      setData({...companyDetail, ...makerProduct})
      setLoading(false)
    })()
  }, [category_id, category_name, company_id, page])

  return data ? (
    <MakerProducts {...data} category_name={category_name} loading={loading} setPage={e => setPage(e)} />
  ) : <Loading />
}

export default MakerProductsView
