import * as React from "react"
import "../../../../styles/common.css"
import { FunctionComponent } from "react"
import { getUserType, post } from "../../../../common/utils"
import { ADMIN, BUYER, BUYER_ADMIN, MAKER, MAKER_ADMIN } from "../../../../common/constants"
import { RELATIVE_PATH } from "../../../../common/routePath"
import { Link } from "gatsby"
import Logo from "../../../../images/common/vendin.svg"

const FooterComponent: FunctionComponent = () => {
  const getTopLink = () => {
    const userType = getUserType()
    let topPath
    switch (parseInt(userType)) {
      case BUYER:
      case BUYER_ADMIN:
        topPath = RELATIVE_PATH.BUYER_TOP
        break
      case MAKER:
      case MAKER_ADMIN:
        topPath = RELATIVE_PATH.MAKER_TOP
        break
      case ADMIN:
        topPath = RELATIVE_PATH.ADMIN_TOP
        break
      default:
        topPath = ""
        break
    }
    return topPath
  }

  return (
    <footer>
      <div className="footer_wrap">
        <Link to={getTopLink()} className="f_top_link"><img src={Logo} alt="VENDIN" width={135} /></Link>
        <div className="copy">©2021 VENDIN Co., Ltd. All Rights Reserved</div>
      </div>
    </footer>
  )
}

export default FooterComponent