import { FunctionComponent, useEffect, useState } from "react"
import * as React from "react"
import { IAdminTop } from "./IAdminTop"
import { MessageList } from "../../organisms/MessageList/MessageList"
import { DealingList } from "../../organisms/DealingList/DealingList"
import { ReviewProducts } from "../../molecules/ReviewProducts/ReviewProducts"
import "../../../styles/maker/maker_common.css"
import { makeStyles } from "@material-ui/core/styles"
import { Link, navigate } from "gatsby"
import { RELATIVE_PATH } from "../../../common/routePath"
import { Loading } from "../../atoms/Loading/Loading"

const useStyles = makeStyles(theme => ({
  // Some extra styling if you'd like
  link: {
    margin: "auto",
    color: "#FFFFFF",
    cursor: "pointer"
  },
}));

const AdminTop: FunctionComponent<IAdminTop> = (props) => {
  const { data, loading } = props
  console.log("data", data)
  const classes = useStyles()

  return (
    <>
      <main className="regist">
        {loading ? <Loading /> : undefined}
        <section className="main_center">
          <div className="top_title_box">
            <h2 className="top_title_h2">新着申請</h2>
            <a href="#" className="top_title_link">申請一覧へ</a>
          </div>
          {data ? <ReviewProducts reviewProductList={data} is_admin={true} /> : <div>新着の申請はありません</div>}
        </section>
      </main>
    </>
  )
}

export default AdminTop
