import * as React from "react"
import { FunctionComponent, useEffect, useState } from "react"
import { RouteProps } from "react-router"
import { Loading } from "../../atoms/Loading/Loading"
import Step13 from "../../templates/AddProduct/step13/Step13"
import Dexie from "dexie"

const Step13Page: FunctionComponent<RouteProps> = (props) => {
  const [ data, setData ] = useState()
  const [ id, setId ] = useState(props.id)

  const emptyData = {
    sales_extra_point: ""
  }

  useEffect(() => {
    const db = new Dexie("vendinDB")
    db.version(1).stores({ productForm: 'formName', })
    db.open()
    db.productForm.orderBy('formName').toArray().then(res => {
      if (res) {
        // @ts-ignore
        setData(res)
      } else {
        // @ts-ignore
        setData(emptyData)
      }
    }).catch(e => {
      console.log("getLocalDB e", e)
    })

    const localFile = localStorage.getItem("step12")
    if (localFile) {
      // @ts-ignore
      setData(JSON.parse(localFile))
    }
  }, [])

  return data ? (
    <main className="regist">
      <Step13 {...data} />
    </main>
  ) : <Loading />
}

export default Step13Page
