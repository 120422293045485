import * as React from "react"
import { FunctionComponent, useEffect, useState } from "react"
import { RouteProps } from "react-router"
import { Loading } from "../../atoms/Loading/Loading"
import Maker from "../../templates/Maker/Maker"
import { get } from "../../../common/utils"
import { COMPANY_DETAIL } from "../../../common/apiConstants"

const MakerView: FunctionComponent<RouteProps> = (props) => {
  const [data, setData] = useState()

  useEffect( () => {
    (async () => {
      let paramObj = {params: {}}
      paramObj.params.id = props.location.search.replace("?id=","")
      const result = await get(COMPANY_DETAIL, paramObj).catch(e => console.log("e", e))
      // @ts-ignore
      setData(result)
    })()
  }, [])

  return data ? (
    <Maker {...data} />
  ) : <Loading />
}

export default MakerView
