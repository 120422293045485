import * as React from "react"
import { FunctionComponent, useEffect, useState } from "react"
import { RouteProps } from "react-router"
import { Loading } from "../../atoms/Loading/Loading"
import { get } from "../../../common/utils"
import { SUB_CATEGORIES } from "../../../common/apiConstants"
import Step6 from "../../templates/AddProduct/step6/Step6"
import Dexie from "dexie"

const Step6Page: FunctionComponent<RouteProps> = (props) => {
  const [ data, setData ] = useState()
  const [ id, setId ] = useState(props.id)

  const emptyJson = {
    sales_title: "",
    sales_documents: [
      {
        document: ""
      }
    ]
  }

  useEffect(() => {
    (async () => {
      const db = new Dexie("vendinDB")
      db.version(1).stores({ productForm: 'formName', })
      db.open()
      db.productForm.get("step6").then(res => {
        if (res) {
          const tempForm = JSON.parse(res.form)
          if (tempForm.sales_title) {
            let tempJsonData = JSON.parse(res.form)
            // 添付無し時用
            if (!res.form.sales_documents) {
              tempJsonData.sales_documents.push({document: ""})
            }
            // @ts-ignore
            setData(tempJsonData)
          } else {
            emptyJson["id"] = tempForm.id
            emptyJson["product_id"] = tempForm.product_id
            // @ts-ignore
            setData(emptyJson)
          }
        } else {
          // @ts-ignore
          setData(emptyJson)
        }
      }).catch(e => {
        console.log("getLocalDB e", e)
      })
    })()
  }, [])

  return data ? (
    <main className="regist">
      <Step6 {...data} />
    </main>
  ) : <Loading />
}

export default Step6Page
