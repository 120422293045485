import * as React from "react"
import { FunctionComponent } from "react"
import { Button, FormControl, InputLabel, MenuItem, NativeSelect, Select } from "@material-ui/core"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Pagination } from "@material-ui/lab"
import { IDealingList } from "./IDealingList"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableBody from "@material-ui/core/TableBody"
import TableContainer from "@material-ui/core/TableContainer"
import { withStyles } from "@material-ui/core/styles"
import TableCell from "@material-ui/core/TableCell"
import { DEALING_TYPES, SELLING_TYPES } from "../../../../common/constants"
import { getDealingStatus } from "../../../../common/utils"
import { CustomPagination } from "../../../molecules/Pagination/Pagination"
import { Loading } from "../../../atoms/Loading/Loading"
import { navigate } from "gatsby"
import { RELATIVE_PATH } from "../../../../common/routePath"

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell)

export const DealingList: FunctionComponent<IDealingList> = (props) => {
  const { total, data, changePagination, onSearch, status, loading } = props
  let retVal = undefined
  const [ sort, setSort ] = React.useState('')
  console.log("props", props)

  const handleChange = (event) => {
    setSort(event.target.value)
  }
  const statusChange = (event) => {
    onSearch(event.target.value)
  }

  const rowClick = (event, row) => {
    navigate(RELATIVE_PATH.DEALING_DETAIL, {state:{dealing_id: row.id}})
  }

  retVal = (
    <section className="main_area notification_list">
      <div className="mp_product_title dealing">
        <div className="list_title_box">
          <h2 className="top_title_h2">商談一覧</h2>
          <div className="search_list_num"><span>{total ? total : 0}</span>件見つかりました</div>
        </div>
      </div>

      {loading ? <Loading /> : undefined}

      <div className="search_status">
        <FormControl variant="outlined">
          <Select
            displayEmpty
            labelId=""
            id=""
            value={status}
            onChange={statusChange}
            IconComponent={props => (
              <ExpandMoreIcon {...props} className={`material-icons ${props.className}`}>
              </ExpandMoreIcon>
            )}
          >
            <MenuItem value="">
              <em>ステータス</em>
            </MenuItem>
            <MenuItem value={0}>全て</MenuItem>
            <MenuItem value={1}>商談中</MenuItem>
            <MenuItem value={2}>商談成立</MenuItem>
            <MenuItem value={3}>商談終了</MenuItem>
          </Select>
        </FormControl>
      </div>

      <div style={{ marginBottom: 20 }}>
        <TableContainer>
          <Table className="vendin_table" aria-label="vendin_table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">No.</StyledTableCell>
                <StyledTableCell align="left">商品名</StyledTableCell>
                <StyledTableCell align="left">バイヤー</StyledTableCell>
                <StyledTableCell align="left">商談ステータス</StyledTableCell>
                <StyledTableCell align="left">公開ステータス</StyledTableCell>
                <StyledTableCell align="left">未読件数</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow className="clickEnabled" key={row.id} onClick={(e) => rowClick(e, row)}>
                  <StyledTableCell align="left">{row.id}</StyledTableCell>
                  <StyledTableCell align="left" className="bold">
                    <div className="img_with_text">
                      {row.product.images ? <img src={row.product.images[0].image} width={40} /> : undefined}
                      <span className="bold">{row.product.name}</span></div>
                  </StyledTableCell>
                  <StyledTableCell align="left"><span className="bold">{row.created_user.name}</span></StyledTableCell>
                  <StyledTableCell align="left">
                    {DEALING_TYPES[getDealingStatus(row.buyer_adopted_at, row.buyer_closed_at, row.maker_adopted_at, row.maker_closed_at)]}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {SELLING_TYPES[row.product.is_selling]}
                  </StyledTableCell>
                  <StyledTableCell align="left">{row.messages_count}</StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <CustomPagination current_page={props.current_page} last_page={props.last_page} setPage={changePagination} />
    </section>
  )


  return retVal
}