import * as React from "react"
import { FunctionComponent, useEffect, useState } from "react"
import { RouteProps } from "react-router"
import PublicInfo from "../../templates/ProfileSetting/PublicInfo/PublicInfo"
import { useCommonData } from "../../../common/customHooks"
import { Loading } from "../../atoms/Loading/Loading"
import { convertBlobToFile, getLocalUser, post } from "../../../common/utils"
import { get } from "../../../common/utils"
import { COMPANY, COMPANY_DETAIL } from "../../../common/apiConstants"

const ProfilePublicInfo: FunctionComponent<RouteProps> = () => {
  const [ data, setData ] = useState()
  const [ logo, setLogo ] = useState()
  const [ header, setHeader ] = useState()
  const [loading, setLoading] = useState(false)
  const localUser = getLocalUser()

  useEffect(() => {
    (async () => {
      let paramObj = { params: {} }
      paramObj.params.id = localUser["companyId"]
      const result = await get(COMPANY_DETAIL, paramObj).catch(e => alert("DEALINGS", e))
      result.company.logo ? setLogo(result.company.logo) : false
      result.company.image ? setHeader(result.company.image) : false
      // @ts-ignore
      setData({...result.company})
    })()
  }, [])

  const submitPublicInfo = async (data) => {
    let is_success
    setLoading(true)
    const formData = new FormData()
    const logoFile = logo.startsWith("data:") ? await convertBlobToFile("logo", logo) : logo
    const headerFile = header.startsWith("data:") ? await convertBlobToFile("header", header) : header

    formData.append("logo", logoFile)
    formData.append("image", headerFile)
    formData.append("introduction", data.introduction)
    formData.append("profile", data.profile)
    formData.append("appeal", data.appeal)
    await post(COMPANY, formData).then(res => {
      is_success = true
    }).catch(e => {
      is_success = false
      console.log(e.message)
    }).finally(() => setLoading(false))
    return is_success
  }

  return data ? (
    <PublicInfo
      publicInfo={data}
      setLogoImage={(_, url) => setLogo(url)}
      setHeaderImage={(_, url) => setHeader(url)}
      submitPublicInfo={e => submitPublicInfo(e)}
      loading={loading}
    />
  ) : <Loading />
}

export default ProfilePublicInfo
