import * as React from "react"
import { FunctionComponent, useEffect, useState } from "react"
import { RouteProps } from "react-router"
import { Loading } from "../../atoms/Loading/Loading"
import Notifications from "../../templates/Message/NotificationList/Notifications"
import { get } from "../../../common/utils"
import { MESSAGE_NOTICES } from "../../../common/apiConstants"

const NotificationsPage: FunctionComponent<RouteProps> = () => {
  const [page, setPage] = useState(1)
  const [notifications, setNotifications] = useState(null)

  useEffect(() => {
    (async () => {
      let paramObj = {params: {}}
      paramObj.params.page = page
      const result = await get(MESSAGE_NOTICES, paramObj).catch(e => console.log("e", e))
      // @ts-ignore
      setNotifications(result)
    })()
  }, [page])

  const changePagination = (page) => {
    setPage(page)
  }

  return <Notifications {...notifications} changePagination={e => changePagination(e)} />
}

export default NotificationsPage
