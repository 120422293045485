import { FunctionComponent, useEffect, useState } from "react"
import * as React from "react"
import { IBuyerTop } from "./IBuyerTop"
import "../../../styles/maker/maker_common.css"
import { MessageList } from "../../organisms/MessageList/MessageList"
import { DealingList } from "../../organisms/DealingList/DealingList"
import { RecommendList } from "../../organisms/Recommend/RecommendList"
import "swiper/swiper.min.css"
import "swiper/components/navigation/navigation.min.css"
import "swiper/components/pagination/pagination.min.css"
import { AdoptProducts } from "../../molecules/AdoptProducts/AdoptProducts"
import { RELATIVE_PATH } from "../../../common/routePath"
import { Link } from "gatsby"

const BuyerTop: FunctionComponent<IBuyerTop> = (props) => {
  const { messageList, dealingList, recommendList, newProductList, adoptProductList } = props

  return (
    <>
      <main className="regist">
        <section className="main_center">
          <div className="top_title_box">
            <h2 className="top_title_h2">最新メッセージ</h2>
            <Link to={RELATIVE_PATH.NOTIFICATIONS} className="top_title_link">メッセージ一覧管理へ</Link>
          </div>
          {messageList.length >= 1 ? (
            <MessageList messageList={messageList} />
          ) : <div style={{textAlign: "center", marginBottom: 20}}>最新のメッセージはありません</div>}
        </section>

        <section className="main_center">
          <div className="top_title_box">
            <h2 className="top_title_h2">商談中の商品</h2>
            <Link to={RELATIVE_PATH.DEALINGS} className="top_title_link">商談管理へ</Link>
          </div>
          {dealingList.length >= 1 ? (
            <DealingList dealingList={dealingList} />
          ) : <div style={{textAlign: "center", marginBottom: 20}}>商談中の商品はありません</div>}
        </section>

        <section className="main_center">
          <div className="top_title_box">
            <h2 className="top_title_h2">おすすめ商品</h2>
          </div>
          {recommendList.length >= 1 ? (
            <RecommendList productList={recommendList} />
          ) : <div style={{textAlign: "center", marginBottom: 20}}>おすすめの商品はありません</div>}
        </section>

        <section className="main_full">
            <div className="top_title_box">
              <h2 className="top_title_h2">新着商品</h2>
              <Link to={RELATIVE_PATH.PRODUCTS} className="top_title_link">一覧へ</Link>
            </div>
          <div className="full_section_wrap">
            {newProductList.length >= 1 ? (
              <RecommendList productList={newProductList} />
            ) : <div style={{textAlign: "center", marginBottom: 20}}>新着商品はありません</div>}
          </div>
        </section>

        <section className="main_center">
          <div className="top_title_box">
            <h2 className="top_title_h2">最新の採用実績</h2>
          </div>
          {adoptProductList.length >= 1 ? (
            <AdoptProducts adoptProductList={adoptProductList} />
          ) : <div style={{textAlign: "center", marginBottom: 20}}>最新の採用実績はありません</div>}
        </section>
      </main>
    </>
  )
}

export default BuyerTop
