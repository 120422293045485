import * as React from "react"
import { FunctionComponent, useMemo, useState } from "react"
import { Title } from "../../atoms/Title/Title"
import { Description } from "../../atoms/Description/Description"
import { Caution } from "../../atoms/Caution/Caution"
import { ICustomRadioForm } from "./ICustomRadioForm"
import { useFormContext } from "react-hook-form"
import { Button, FormControl, Grid, MenuItem, Radio, RadioGroup, Select } from "@material-ui/core"
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { string } from "yup"
import h27_wash_190 from "../../../images/jis/h27_wash_190.jpg"
import h27_wash_170 from "../../../images/jis/h27_wash_170.jpg"
import h27_wash_160 from "../../../images/jis/h27_wash_160.jpg"
import h27_wash_161 from "../../../images/jis/h27_wash_161.jpg"
import h27_wash_151 from "../../../images/jis/h27_wash_151.jpg"
import h27_wash_150 from "../../../images/jis/h27_wash_150.jpg"
import h27_wash_140 from "../../../images/jis/h27_wash_140.jpg"
import h27_wash_141 from "../../../images/jis/h27_wash_141.jpg"
import h27_wash_142 from "../../../images/jis/h27_wash_142.jpg"
import h27_wash_130 from "../../../images/jis/h27_wash_130.jpg"
import h27_wash_131 from "../../../images/jis/h27_wash_131.jpg"
import h27_wash_132 from "../../../images/jis/h27_wash_132.jpg"
import h27_wash_110 from "../../../images/jis/h27_wash_110.jpg"
import h27_wash_100 from "../../../images/jis/h27_wash_100.jpg"
import h27_wash_220 from "../../../images/jis/h27_wash_220.jpg"
import h27_wash_210 from "../../../images/jis/h27_wash_210.jpg"
import h27_wash_200 from "../../../images/jis/h27_wash_200.jpg"
import h27_wash_320 from "../../../images/jis/h27_wash_320.jpg"
import h27_wash_310 from "../../../images/jis/h27_wash_310.jpg"
import h27_wash_300 from "../../../images/jis/h27_wash_300.jpg"
import h27_wash_440 from "../../../images/jis/h27_wash_440.jpg"
import h27_wash_430 from "../../../images/jis/h27_wash_430.jpg"
import h27_wash_420 from "../../../images/jis/h27_wash_420.jpg"
import h27_wash_410 from "../../../images/jis/h27_wash_410.jpg"
import h27_wash_445 from "../../../images/jis/h27_wash_445.jpg"
import h27_wash_435 from "../../../images/jis/h27_wash_435.jpg"
import h27_wash_425 from "../../../images/jis/h27_wash_425.jpg"
import h27_wash_415 from "../../../images/jis/h27_wash_415.jpg"
import h27_wash_530 from "../../../images/jis/h27_wash_530.jpg"
import h27_wash_520 from "../../../images/jis/h27_wash_520.jpg"
import h27_wash_510 from "../../../images/jis/h27_wash_510.jpg"
import h27_wash_500 from "../../../images/jis/h27_wash_500.jpg"
import h27_wash_620 from "../../../images/jis/h27_wash_620.jpg"
import h27_wash_621 from "../../../images/jis/h27_wash_621.jpg"
import h27_wash_610 from "../../../images/jis/h27_wash_610.jpg"
import h27_wash_611 from "../../../images/jis/h27_wash_611.jpg"
import h27_wash_600 from "../../../images/jis/h27_wash_600.jpg"
import h27_wash_710 from "../../../images/jis/h27_wash_710.jpg"
import h27_wash_711 from "../../../images/jis/h27_wash_711.jpg"
import h27_wash_712 from "../../../images/jis/h27_wash_712.jpg"
import h27_wash_700 from "../../../images/jis/h27_wash_700.jpg"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

export const CustomRadioForm: FunctionComponent<ICustomRadioForm> = (props) => {
  const { register } = useFormContext()
  const { caution, description, id, title, name, value, rules, style, selects, selectsValue } = props
  console.log("CustomRadioForm value", value)
  const [washing, setWashing] = useState(value ? value instanceof Object ? value["washing"] : JSON.parse(value)["washing"] : "")
  const [bleaching, setBleaching] = useState(value ? value instanceof Object ? value["bleaching"] : JSON.parse(value)["bleaching"] : "")
  const [dry, setDry] = useState(value ? value instanceof Object ? value["dry"] : JSON.parse(value)["dry"] : "")
  const [naturalDrying, setNaturalDrying] = useState(value ? value instanceof Object ? value["naturalDrying"] : JSON.parse(value)["naturalDrying"] : "")
  const [iron, setIron] = useState(value ? value instanceof Object ? value["iron"] : JSON.parse(value)["iron"] : "")
  const [dryCleaning, setDryCleaning] = useState(value ? value instanceof Object ? value["dryCleaning"] : JSON.parse(value)["dryCleaning"] : "")
  const [wetCleaning, setWetCleaning] = useState(value ? value instanceof Object ? value["wetCleaning"] : JSON.parse(value)["wetCleaning"] : "")
  const [isShow, setIsShow] = useState(false)

  const renderRequired = () => {
    let retVal = null
    // @ts-ignore
    if (rules && "required" in rules && rules.required) {
      retVal = <span>*</span>
    }
    return retVal
  }

  const changedSelected = (e, setState) => {
    setState(e.target.value)
  }

  const unChecked = (setState) => {
    setState("")
  }

  const changedShowStatus = (e) => {
    if (e.target.value) {
      setIsShow(true)
    } else {
      setWashing("")
      setBleaching("")
      setDry("")
      setNaturalDrying("")
      setIron("")
      setDryCleaning("")
      setWetCleaning("")
      setIsShow(false)
    }
  }

  const renderRadioImage = (path, size, title) => {
    return (
      <div className="custom_radio_item">
        <p>{title}</p>
        <div><img src={path} width={size} /></div>
      </div>
    )
  }

  const renderRadios = useMemo(() => {

    return (
      <div>
        <div className="radioWrap">
          <RadioGroup row value={washing} className="rowRadioGroup" onClick={e => changedSelected(e, setWashing)}>
            <h3>洗濯処理記号</h3>
            <Grid container justifyContent="flex-start" alignItems="center" spacing={2}>
              <Grid item md={1}>
                <label className="image_radio">
                  <Radio color="default" value="0" {...register(`${name}.washing`, rules)} />{renderRadioImage(h27_wash_190, 40, 190)}
                </label>
              </Grid>
              <Grid item md={1}>
                <label className="image_radio">
                  <Radio color="default" value="1" {...register(`${name}.washing`, rules)} />{renderRadioImage(h27_wash_170, 40, 170)}
                </label>
              </Grid>
              <Grid item md={1}>
                <label className="image_radio">
                  <Radio color="default" value="2" {...register(`${name}.washing`, rules)} />{renderRadioImage(h27_wash_160, 40, 160)}
                </label>
              </Grid>
              <Grid item md={1}>
                <label className="image_radio">
                  <Radio color="default" value="3" {...register(`${name}.washing`, rules)} />{renderRadioImage(h27_wash_161, 40, 161)}
                </label>
              </Grid>
              <Grid item md={1}>
                <label className="image_radio">
                  <Radio color="default" value="4" {...register(`${name}.washing`, rules)} />{renderRadioImage(h27_wash_150, 40, 150)}
                </label>
              </Grid>
              <Grid item md={1}>
                <label className="image_radio">
                  <Radio color="default" value="5" {...register(`${name}.washing`, rules)} />{renderRadioImage(h27_wash_151, 40, 151)}
                </label>
              </Grid>
              <Grid item md={1}>
                <label className="image_radio">
                  <Radio color="default" value="6" {...register(`${name}.washing`, rules)} />{renderRadioImage(h27_wash_140, 40, 140)}
                </label>
              </Grid>
              <Grid item md={1}>
                <label className="image_radio">
                  <Radio color="default" value="7" {...register(`${name}.washing`, rules)} />{renderRadioImage(h27_wash_141, 40, 141)}
                </label>
              </Grid>
              <Grid item md={1}>
                <label className="image_radio">
                  <Radio color="default" value="8" {...register(`${name}.washing`, rules)} />{renderRadioImage(h27_wash_142, 40, 142)}
                </label>
              </Grid>
              <Grid item md={1}>
                <label className="image_radio">
                  <Radio color="default" value="9" {...register(`${name}.washing`, rules)} />{renderRadioImage(h27_wash_130, 40, 130)}
                </label>
              </Grid>
              <Grid item md={1}>
                <label className="image_radio">
                  <Radio color="default" value="10" {...register(`${name}.washing`, rules)} />{renderRadioImage(h27_wash_131, 40, 131)}
                </label>
              </Grid>
              <Grid item md={1}>
                <label className="image_radio">
                  <Radio color="default" value="11" {...register(`${name}.washing`, rules)} />{renderRadioImage(h27_wash_132, 40, 132)}
                </label>
              </Grid>
              <Grid item md={1}>
                <label className="image_radio">
                  <Radio color="default" value="12" {...register(`${name}.washing`, rules)} />{renderRadioImage(h27_wash_110, 40, 110)}
                </label>
              </Grid>
              <Grid item md={1}>
                <label className="image_radio">
                  <Radio color="default" value="13" {...register(`${name}.washing`, rules)} />{renderRadioImage(h27_wash_100, 40, 100)}
                </label>
              </Grid>
            </Grid>

          </RadioGroup>
          <Button variant={"outlined"} onClick={() => unChecked(setWashing)}>クリア</Button>
        </div>

        <div className="radioWrap">
          <RadioGroup row value={bleaching} className="rowRadioGroup" onClick={e => changedSelected(e, setBleaching)}>
            <h3>漂白処理記号</h3>
            <Grid container justifyContent="flex-start" alignItems="center" spacing={2}>
              <Grid item md={1}>
                <label className="image_radio">
                  <Radio color="default" value="0" {...register(`${name}.bleaching`, rules)} />{renderRadioImage(h27_wash_220, 40, 220)}
                </label>
              </Grid>
              <Grid item md={1}>
                <label className="image_radio">
                  <Radio color="default" value="1" {...register(`${name}.bleaching`, rules)} />{renderRadioImage(h27_wash_210, 40, 210)}
                </label>
              </Grid>
              <Grid item md={1}>
                <label className="image_radio">
                  <Radio color="default" value="2" {...register(`${name}.bleaching`, rules)} />{renderRadioImage(h27_wash_200, 40, 200)}
                </label>
              </Grid>
            </Grid>
          </RadioGroup>
          <Button variant={"outlined"} onClick={() => unChecked(setBleaching)}>クリア</Button>
        </div>

        <div className="radioWrap">
          <RadioGroup row value={dry} className="rowRadioGroup" onClick={e => changedSelected(e, setDry)}>
            <h3>乾燥処理記号</h3>
            <Grid container justifyContent="flex-start" alignItems="center" spacing={2}>
              <Grid item md={1}>
                <label className="image_radio">
                  <Radio color="default" value="0" {...register(`${name}.dry`, rules)} />{renderRadioImage(h27_wash_320, 40, 320)}
                </label>
              </Grid>
              <Grid item md={1}>
                <label className="image_radio">
                  <Radio color="default" value="1" {...register(`${name}.dry`, rules)} />{renderRadioImage(h27_wash_310, 40, 310)}
                </label>
              </Grid>
              <Grid item md={1}>
                <label className="image_radio">
                  <Radio color="default" value="2" {...register(`${name}.dry`, rules)} />{renderRadioImage(h27_wash_300, 40, 300)}
                </label>
              </Grid>
            </Grid>
          </RadioGroup>
          <Button variant={"outlined"} onClick={() => unChecked(setDry)}>クリア</Button>
        </div>

        <div className="radioWrap">
          <RadioGroup row value={naturalDrying} className="rowRadioGroup" onClick={e => changedSelected(e, setNaturalDrying)}>
            <h3>自然乾燥処理記号</h3>
            <Grid container justifyContent="flex-start" alignItems="center" spacing={2}>
              <Grid item md={1}>
                <label className="image_radio">
                  <Radio color="default" value="0" {...register(`${name}.naturalDrying`, rules)} />{renderRadioImage(h27_wash_440, 40, 440)}
                </label>
              </Grid>
              <Grid item md={1}>
                <label className="image_radio">
                  <Radio color="default" value="1" {...register(`${name}.naturalDrying`, rules)} />{renderRadioImage(h27_wash_430, 40, 430)}
                </label>
              </Grid>
              <Grid item md={1}>
                <label className="image_radio">
                  <Radio color="default" value="2" {...register(`${name}.naturalDrying`, rules)} />{renderRadioImage(h27_wash_420, 40, 420)}
                </label>
              </Grid>
              <Grid item md={1}>
                <label className="image_radio">
                  <Radio color="default" value="3" {...register(`${name}.naturalDrying`, rules)} />{renderRadioImage(h27_wash_410, 40, 410)}
                </label>
              </Grid>
              <Grid item md={1}>
                <label className="image_radio">
                  <Radio color="default" value="4" {...register(`${name}.naturalDrying`, rules)} />{renderRadioImage(h27_wash_445, 40, 445)}
                </label>
              </Grid>
              <Grid item md={1}>
                <label className="image_radio">
                  <Radio color="default" value="5" {...register(`${name}.naturalDrying`, rules)} />{renderRadioImage(h27_wash_435, 40, 435)}
                </label>
              </Grid>
              <Grid item md={1}>
                <label className="image_radio">
                  <Radio color="default" value="6" {...register(`${name}.naturalDrying`, rules)} />{renderRadioImage(h27_wash_425, 40, 425)}
                </label>
              </Grid>
              <Grid item md={1}>
                <label className="image_radio">
                  <Radio color="default" value="7" {...register(`${name}.naturalDrying`, rules)} />{renderRadioImage(h27_wash_415, 40, 415)}
                </label>
              </Grid>
            </Grid>
          </RadioGroup>
          <Button variant={"outlined"} onClick={() => unChecked(setNaturalDrying)}>クリア</Button>
        </div>

        <div className="radioWrap">
          <RadioGroup row value={iron} className="rowRadioGroup" onClick={e => changedSelected(e, setIron)}>
            <h3>アイロン仕上げ処理記号</h3>
            <Grid container justifyContent="flex-start" alignItems="center" spacing={2}>
              <Grid item md={1}>
                <label className="image_radio">
                  <Radio color="default" value="0" {...register(`${name}.iron`, rules)} />{renderRadioImage(h27_wash_530, 40, 530)}
                </label>
              </Grid>
              <Grid item md={1}>
                <label className="image_radio">
                  <Radio color="default" value="1" {...register(`${name}.iron`, rules)} />{renderRadioImage(h27_wash_520, 40, 520)}
                </label>
              </Grid>
              <Grid item md={1}>
                <label className="image_radio">
                  <Radio color="default" value="2" {...register(`${name}.iron`, rules)} />{renderRadioImage(h27_wash_510, 40, 510)}
                </label>
              </Grid>
              <Grid item md={1}>
                <label className="image_radio">
                  <Radio color="default" value="3" {...register(`${name}.iron`, rules)} />{renderRadioImage(h27_wash_500, 40, 500)}
                </label>
              </Grid>
            </Grid>
          </RadioGroup>
          <Button variant={"outlined"} onClick={() => unChecked(setIron)}>クリア</Button>
        </div>

        <div className="radioWrap">
          <RadioGroup row value={dryCleaning} className="rowRadioGroup" onClick={e => changedSelected(e, setDryCleaning)}>
            <h3>ドライクリーニング処理記号</h3>
            <Grid container justifyContent="flex-start" alignItems="center" spacing={2}>
              <Grid item md={1}>
                <label className="image_radio">
                  <Radio color="default" value="0" {...register(`${name}.dryCleaning`, rules)} />{renderRadioImage(h27_wash_620, 40, 620)}
                </label>
              </Grid>
              <Grid item md={1}>
                <label className="image_radio">
                  <Radio color="default" value="1" {...register(`${name}.dryCleaning`, rules)} />{renderRadioImage(h27_wash_621, 40, 621)}
                </label>
              </Grid>
              <Grid item md={1}>
                <label className="image_radio">
                  <Radio color="default" value="2" {...register(`${name}.dryCleaning`, rules)} />{renderRadioImage(h27_wash_610, 40, 610)}
                </label>
              </Grid>
              <Grid item md={1}>
                <label className="image_radio">
                  <Radio color="default" value="3" {...register(`${name}.dryCleaning`, rules)} />{renderRadioImage(h27_wash_611, 40, 611)}
                </label>
              </Grid>
              <Grid item md={1}>
                <label className="image_radio">
                  <Radio color="default" value="4" {...register(`${name}.dryCleaning`, rules)} />{renderRadioImage(h27_wash_600, 40, 600)}
                </label>
              </Grid>
            </Grid>
          </RadioGroup>
          <Button variant={"outlined"} onClick={() => unChecked(setDryCleaning)}>クリア</Button>
        </div>

        <div className="radioWrap">
          <RadioGroup row value={wetCleaning} className="rowRadioGroup" onClick={e => changedSelected(e, setWetCleaning)}>
            <h3>ウェットクリーニング処理記号</h3>
            <Grid container justifyContent="flex-start" alignItems="center" spacing={2}>
              <Grid item md={1}>
                <label className="image_radio">
                  <Radio color="default" value="0" {...register(`${name}.wetCleaning`, rules)} />{renderRadioImage(h27_wash_710, 40, 710)}
                </label>
              </Grid>
              <Grid item md={1}>
                <label className="image_radio">
                  <Radio color="default" value="1" {...register(`${name}.wetCleaning`, rules)} />{renderRadioImage(h27_wash_711, 40, 711)}
                </label>
              </Grid>
              <Grid item md={1}>
                <label className="image_radio">
                  <Radio color="default" value="2" {...register(`${name}.wetCleaning`, rules)} />{renderRadioImage(h27_wash_712, 40, 712)}
                </label>
              </Grid>
              <Grid item md={1}>
                <label className="image_radio">
                  <Radio color="default" value="3" {...register(`${name}.wetCleaning`, rules)} />{renderRadioImage(h27_wash_700, 40, 700)}
                </label>
              </Grid>
            </Grid>
          </RadioGroup>
          <Button variant={"outlined"} onClick={() => unChecked(setWetCleaning)}>クリア</Button>
        </div>

      </div>
    )
  }, [ washing, bleaching, dry, naturalDrying, iron, dryCleaning, wetCleaning ])

  return (
    <div style={style} className={props["is_column"] ? "ir_sub_item column_item" : "ir_sub_item"}>
      {title ?
        <div className="input_title_wrap">
          <Title title={title} />
        </div>
        : null}
      <div>
        <FormControl variant="outlined">
          <Select
            displayEmpty
            labelId=""
            defaultValue={0}
            onChange={changedShowStatus}
            IconComponent={props => (
              <ExpandMoreIcon {...props} className={`material-icons ${props.className}`}>
              </ExpandMoreIcon>
            )}
          >
            <MenuItem value={0}>無し</MenuItem>
            <MenuItem value={1}>JIS L 0001（新）</MenuItem>
          </Select>
        </FormControl>
      </div>
      {description ? <Description description={description} /> : null}
      {isShow ? renderRadios : undefined}
      {caution ? <Caution caution={caution} /> : null}
    </div>
  )
}
