import * as React from "react"
import { FunctionComponent, useEffect, useState } from "react"
import { RouteProps } from "react-router"
import { Loading } from "../../atoms/Loading/Loading"
import { get } from "../../../common/utils"
import { SUB_CATEGORIES } from "../../../common/apiConstants"
import Step2 from "../../templates/AddProduct/step2/Step2"
import Dexie from "dexie"

const Step2Page: FunctionComponent<RouteProps> = (props) => {
  const [ data, setData ] = useState()
  const [ id, setId ] = useState(props.id)

  const emptyJson = {
    id: null,
    name: "",
    images: [ { image: "" }, ],
    movie: "",
    url: "",
    brand_name: "",
    brand_url: "",
    buyer_brand_name: "",
    buyer_brand_url: "",
    wholesale_price: null,
    broadcast_price: null,
    regular_price_type: "",
    regular_price: null,
    stock_status: "",
    stock: null,
    is_renewal: "0",
    is_selling: "1"
  }

  useEffect(() => {
    (async () => {
      if (id) {
        let paramObj = { params: {} }
        paramObj.params.id = props.location.search.replace("?id=", "")
        const data = await get(SUB_CATEGORIES, {})
        // @ts-ignore
        setData(data)
      } else {
        const db = new Dexie("vendinDB")
        db.version(1).stores({ productForm: 'formName', })
        db.open()
        db.productForm.get("step2").then(res => {
          if (res) {
            // @ts-ignore
            setData(JSON.parse(res.form))
          } else {
            // @ts-ignore
            setData(emptyJson)
          }
        }).catch(e => {
          console.log("getLocalDB e", e)
        })
      }
    })()
  }, [])

  return data ? (
    <Step2 {...data} />
  ) : <Loading />
}

export default Step2Page
