import * as React from "react"
import { FunctionComponent, useMemo } from "react"
import { Title } from "../../atoms/Title/Title"
import { Description } from "../../atoms/Description/Description"
import { Caution } from "../../atoms/Caution/Caution"
import { ISelectBoxForm } from "./ISelectBoxForm"
import { useFormContext } from "react-hook-form"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { FormControl, MenuItem, Select } from "@material-ui/core"

export const SelectBoxForm: FunctionComponent<ISelectBoxForm> = (props) => {
  const { register } = useFormContext()
  const { caution, description, id, title, name, value, placeholder, rules, style, selects, selectsValue } = props

  const renderRequired = () => {
    let retVal = null
    // @ts-ignore
    if (rules && "required" in rules && rules.required) {
      retVal = <span className="required">*</span>
    }
    return retVal
  }

  const generatePlaceholder = useMemo(() => {
    let options = []
    if (value) {
      options.push(<MenuItem key={`${id}_select_placeholder`} value={""} disabled>選択してください</MenuItem>)
    } else {
      if (placeholder) {
        options.push(<MenuItem key={`${id}_select_placeholder`} value={""} disabled>{placeholder}</MenuItem>)
      } else {
        options.push(<MenuItem key={`${id}_select_placeholder`} value={""} disabled>選択してください</MenuItem>)
      }
    }

    return options
  }, [ value ])

  const generateOptions = useMemo(() => {
    let options = []
    if (selects && selects.length > 0) {
      if (typeof (selects[0]) == "string") {
        options = selects.map((select, index) =>
          <MenuItem key={`${id}_select_${index}`} value={selectsValue ? selectsValue[index] : select}>{select}</MenuItem>
        )
      } else {
        options = selects.map((select, index) =>
          <MenuItem key={`${id}_select_${index}`} value={select.value}>{select.label}</MenuItem>
        )
      }
    } else {
      options.push(<MenuItem value={""}>選択できません</MenuItem>)
    }
    return options
  }, [ selects ])

  const renderSelects = useMemo(() => {
    return (
      <FormControl variant="outlined">
        <Select
          displayEmpty
          labelId=""
          id={id.toString()}
          defaultValue={value || ""}
          {...register(name, rules)}
          IconComponent={props => (
            <ExpandMoreIcon {...props} className={`material-icons ${props.className}`}>
            </ExpandMoreIcon>
          )}
        >
          {generatePlaceholder}
          {generateOptions}
        </Select>
      </FormControl>
    )
  }, [ value ])

  return (
    <div style={style} className={props["is_column"] ? "ir_sub_item column_item" : "ir_sub_item"}>
      {title ?
        <div className="input_title_wrap">
          <Title title={title} />
          {renderRequired()}
        </div>
        : null}
      {description ? <Description description={description} /> : null}
      {renderSelects}
      {caution ? <Caution caution={caution} /> : null}
    </div>
  )
}
