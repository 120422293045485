import { FunctionComponent } from "react"
import * as React from "react"
import "../../../styles/buyer/maker_list.css"
import MakerDetailSideBar from "../../organisms/MakerDetail/SideBar/MakerDetailSideBar"
import { ProductList } from "../../organisms/ProductList/ProductList"
import { IMakerProducts } from "./IMakerProducts"
import { CustomPagination } from "../../molecules/Pagination/Pagination"

const MakerProducts: FunctionComponent<IMakerProducts> = (props) => {
  const {total, data, last_page, current_page, category_name, loading, setPage} = props

  return (
    <main className="main_area_two_col">
      <div style={{order: 2}}>
        <ProductList total={total} data={data} categoryName={category_name} loading={loading} current_page={current_page} last_page={last_page} setPage={setPage} />
        <CustomPagination current_page={current_page} last_page={last_page} setPage={setPage} />
      </div>
      <MakerDetailSideBar {...props} current={"categories"} />
    </main>
  )
}

export default MakerProducts
