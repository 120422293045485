import * as React from "react"
import { FunctionComponent, useEffect, useState } from "react"
import { RouteProps } from "react-router"
import { Loading } from "../../atoms/Loading/Loading"
import { get } from "../../../common/utils"
import { PRODUCT_REGISTER_FORM, SUB_CATEGORIES } from "../../../common/apiConstants"
import Step4 from "../../templates/AddProduct/step4/Step4"
import Dexie from "dexie"

const Step4Page: FunctionComponent<RouteProps> = (props) => {
  const [ data, setData ] = useState()

  useEffect(() => {
    (async () => {
      const localFile = JSON.parse(localStorage.getItem("product_forms"))
      const db = new Dexie("vendinDB")
      db.version(1).stores({ productForm: 'formName', })
      db.open()
      db.productForm.get("step4").then(res => {
        if (res) {
          // @ts-ignore
          setData(Object.values(JSON.parse(res.form)))
        } else {
          // @ts-ignore
          setData(localFile.product_info.step4)
        }
      }).catch(e => {
        console.log("getLocalDB e", e)
      })
    })()
  }, [])

  return data ? (
      <main className="regist">
        <Step4 step4={data} />
      </main>
  ) : <Loading />
}

export default Step4Page
