import { FunctionComponent, useEffect, useState } from "react"
import * as React from "react"
import { IMakerTop } from "./IMakerTop"
import { MessageList } from "../../organisms/MessageList/MessageList"
import { DealingList } from "../../organisms/DealingList/DealingList"
import { ReviewProducts } from "../../molecules/ReviewProducts/ReviewProducts"
import "../../../styles/maker/maker_common.css"
import { makeStyles } from "@material-ui/core/styles"
import { Link, navigate } from "gatsby"
import { RELATIVE_PATH } from "../../../common/routePath"
import { deleteStore } from "../../../common/utils"

const useStyles = makeStyles(theme => ({
  // Some extra styling if you'd like
  link: {
    margin: "auto",
    color: "#FFFFFF",
    cursor: "pointer"
  },
}));

const MakerTop: FunctionComponent<IMakerTop> = (props) => {
  const { messageList, dealingList, reviewProductList } = props
  const classes = useStyles()

  const onMoveAddProduct = () => {
    // 新規作成のためlocalの商品一時情報は削除
    deleteStore()
    navigate(RELATIVE_PATH.STEP1)
  }

  return (
    <>
      <main className="regist">
        <section className="main_center">
          <div className="top_title_box">
            <h2 className="top_title_h2">最新メッセージ</h2>
            <Link to={RELATIVE_PATH.NOTIFICATIONS} className="top_title_link">メッセージ一覧管理へ</Link>
          </div>
          {messageList.length >= 1 ? (
            <MessageList messageList={messageList} />
          ) : <div style={{textAlign: "center", marginBottom: 20}}>最新のメッセージはありません</div>}
        </section>

        <section className="main_center">
          <div className="top_title_box">
            <h2 className="top_title_h2">商談中の商品</h2>
            <Link to={RELATIVE_PATH.DEALINGS} className="top_title_link">商談管理へ</Link>
          </div>
          {dealingList.length >= 1 ? (
            <DealingList dealingList={dealingList} />
          ) : <div style={{textAlign: "center", marginBottom: 20}}>商談中の商品はありません</div>}
        </section>
        <section className="main_center">
          <div className="top_title_box">
            <h2 className="top_title_h2">直近の商品審査状況</h2>
          </div>
          {reviewProductList.length >= 1 ? (
            <ReviewProducts reviewProductList={reviewProductList} is_admin={false} />
          ) : <div style={{textAlign: "center", marginBottom: 20}}>審査中の商品はありません</div>}
          <div className={"goods_new_entry_box"}>
            <div onClick={onMoveAddProduct} className={`goods_new_entry_link ${classes.link}`}>+ 商品新規登録</div>
          </div>
        </section>
      </main>
    </>
  )
}

export default MakerTop
