import { FunctionComponent, useEffect, useState } from "react"
import * as React from "react"
import "../../../styles/common.css"
import "../../../styles/maker/account_edit.css"
import { Button, CircularProgress, TextField } from "@material-ui/core"
import { FormProvider, Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { IPublicInfoForm } from "./IPublicInfoForm"
import { ImageForm } from "../../molecules/ImageForm/ImageForm"
import { TextAreaForm } from "../../molecules/TextAreaForm/TextAreaForm"
import { Loading } from "../../atoms/Loading/Loading"
import Backdrop from "@material-ui/core/Backdrop"
import Fade from "@material-ui/core/Fade"
import { TextForm } from "../../molecules/TextForm/TextForm"
import Modal from "@material-ui/core/Modal"

export const PublicInfoForm: FunctionComponent<IPublicInfoForm> = (props) => {
  const { id, logo, image, introduction, profile, appeal, loading } = props.publicInfo
  const [showModal, setShowModal] = useState(false)

  const methods = useForm({
    mode: "onBlur",
    resolver: yupResolver(
      yup.object().shape({
        logo: yup.string(),
        image: yup.string(),
        introduction: yup.string(),
        profile: yup.string(),
        appeal: yup.string()
      })
    )
  })
  const { handleSubmit, formState: { errors } } = methods

  const onSubmit = async (data) => {
    const res = await props.submitPublicInfo(data)
    setShowModal(res)
  }

  return (
    <div className="account_wrap">
      {props.loading ? <Loading /> : undefined}
      <FormProvider {...methods}>
        <form method="post" autoComplete="on" onSubmit={handleSubmit(onSubmit)}>
          <div className="account_pub_row account_row_border">
            <ImageForm
              id="logo_image"
              name="logo_image"
              title="ロゴ"
              value={logo}
              caution={"※形式はJPEG/PNGのみ\r\n※容量は最大2MBまで"}
              setForm={(index, url) => props.setLogoImage(index, url)}
            />
          </div>

          <div className="account_pub_row account_row_border">
            <ImageForm
              id="header_image"
              name="header_image"
              title="ヘッダーイメージ"
              value={image}
              caution={"※形式はJPEG/PNGのみ\r\n※容量は最大2MBまで"}
              setForm={(index, url) => props.setHeaderImage(index, url)}
            />
          </div>

          <div className="account_pub_row account_row_border">
            <TextAreaForm
              id="introduction"
              name="introduction"
              title="メーカー紹介"
              value={introduction}
            />
          </div>

          <div className="account_pub_row account_row_border">
            <TextAreaForm
              id="profile"
              name="profile"
              title="会社概要"
              value={profile}
            />
          </div>

          <div className="account_pub_row account_row_border">
            <TextAreaForm
              id="appeal"
              name="appeal"
              title="担当者アピール"
              value={appeal}
            />
          </div>

          <div style={{ display: "flex", justifyContent: "flex-end", margin: "10px 0 0 0" }}>
            <Button variant="contained" size="large" className="submitBtn" type="submit">更新</Button>
          </div>
        </form>
      </FormProvider>

      <Modal
        className="confirm_modal"
        open={showModal}
        onClose={() => setShowModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showModal}>
          <div className="modal_body" style={{width: 280}}>
            <h2 className="contact_title" style={{marginBottom: 30}}>公開情報を更新しました</h2>
            <Button
              variant="contained"
              size="large"
              className="login_button"
              onClick={() => setShowModal(false)}>
              閉じる
            </Button>
          </div>
        </Fade>
      </Modal>
    </div>
  )
}

export default PublicInfoForm
