import { FunctionComponent, useEffect, useMemo, useState } from "react"
import * as React from "react"
import "../../../../styles/common.css"
import "../../../../styles/product_view.css"
import { IStep2 } from "../../AddProduct/step2/IStep2"
import { renderText } from "../../../../common/utils"
import Backdrop from "@material-ui/core/Backdrop"
import Fade from "@material-ui/core/Fade"
import Modal from "@material-ui/core/Modal"
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { MoviePlayer } from "../../../molecules/MoviePlayer/MoviePlayer"
import { Image } from "../../../atoms/Image/Image"
import { Movie } from "../../../atoms/Movie/Movie"
import { OuterLink } from "../../../atoms/OuterLink/OuterLink"

const Step2View : FunctionComponent<IStep2> = (props, location) => {
  const { id, name, images, movie, url, brand_name, brand_url, order_brand_name, order_brand_url, wholesale_price, broadcast_price, regular_price_type, regular_price, stock_status, stock, is_renewal, is_selling } = props

  return (
    <section className="product_view_wrap">
      <div className="product_view_row">
        <div className="ir_head">商品名</div>
        <div className="view_content">
          <div className="view_item">{renderText(name)}</div>
        </div>
      </div>

      <div className="product_view_row">
        <div className="ir_head">商品写真</div>
        <div className="view_content">
          {images.length >= 1 ? (
            images.map((item, index) => (
            <div className="view_item has_img" key={`${index}_view_img`}>
              {item.image ? <div className="view_value_img"><Image url={item.image} /></div> : <div className="view_item">なし</div>}
            </div>
          ))) : <div className="view_item">なし</div>}
        </div>
      </div>

      <div className="product_view_row">
        <div className="ir_head">紹介動画</div>
        <div className="view_content">
          <div className="view_item">
            <div className="view_value">
              {movie ? (
                <Movie url={movie} />
              ) : <div className="view_item">なし</div>}
            </div>
          </div>
        </div>
      </div>

      <div className="product_view_row">
        <div className="ir_head">商品URL</div>
        <div className="view_content">
          <div className="view_item">
            <div className="view_value"><OuterLink url={url} title={url} /></div>
          </div>
        </div>
      </div>

      <div className="product_view_row">
        <div className="ir_head">ブランド情報</div>
        <div className="view_content">
          <div className="view_item">
            <div className="view_title">製造販売元</div>
            <div className="view_value">{brand_name}</div>
          </div>
          <div className="view_item">
            <div className="view_title">製造販売元URL</div>
            <div className="view_value"><OuterLink url={brand_url} title={brand_url} /></div>
          </div>
          <div className="view_item">
            <div className="view_title">発売元</div>
            <div className="view_value">{order_brand_name}</div>
          </div>
          <div className="view_item">
            <div className="view_title">発売元URL</div>
            <div className="view_value"><OuterLink url={order_brand_url} title={order_brand_url} /></div>
          </div>
        </div>
       </div>

      <div className="product_view_row">
        <div className="ir_head">価格情報</div>
        <div className="view_content">
          <div className="view_item">
            <div className="view_title">卸価格</div>
            <div className="view_value">{wholesale_price.toLocaleString()}円（税抜）</div>
          </div>
          <div className="view_item">
            <div className="view_title">TV通販の希望販売価格</div>
            <div className="view_value">{broadcast_price.toLocaleString()}円（税抜）</div>
          </div>
          <div className="view_item">
            <div className="view_title">定価（{Number(regular_price_type) ? "メーカー直販価格" : "メーカー希望小売価格"}）</div>
            <div className="view_value">{regular_price.toLocaleString()}円（税抜）</div>
          </div>
        </div>
      </div>

      <div className="product_view_row">
        <div className="ir_head">在庫情報</div>
        <div className="view_content">
          <div className="view_item">
            <div className="view_title">在庫状況</div>
            <div className="view_value">
              {Number(stock_status) === 2 ? "なし（再販予定なし）" : Number(stock_status) ? "なし（再販予定あり）" : "あり"}
            </div>
          </div>
          <div className="view_item">
            <div className="view_title">在庫数</div>
            <div className="view_value">{stock ? stock.toLocaleString() : "0"}個</div>
          </div>
        </div>
      </div>

      <div className="product_view_row">
        <div className="ir_head">販売状況</div>
        <div className="view_content">
          <div className="view_item">
            <div className="view_title">在庫状況</div>
            <div className="view_value">
              {Number(is_renewal) === 0 ? "新商品" : "リニューアル商品"}
            </div>
          </div>
          <div className="view_item">
            <div className="view_title">公開設定</div>
            <div className="view_value">
              {Number(is_selling) === 0 ? "未公開" : "公開"}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Step2View
