import "../../../../common/wdyr"
import { FunctionComponent } from "react"
import * as React from "react"
import { IStep11 } from "../../AddProduct/step11/IStep11"
import "../../../../styles/maker/maker_common.css"
import { Image } from "../../../atoms/Image/Image"
import { OuterLink } from "../../../atoms/OuterLink/OuterLink"


export const Step11View: FunctionComponent<IStep11> = (props) => {
  const { is_tv_achievement, is_approval, talent_name, talent_image, talent_movie_url, others } = props

  return (
    <section className="product_view_wrap">
      <div className="product_view_row">
        <div className="ir_head">TV通販の実績</div>
        <div className="view_content">
          <div className="view_item">{Number(is_tv_achievement) ? "あり":"なし"}</div>
        </div>
      </div>

      <div className="product_view_row">
        <div className="ir_head">メインタレント<br />出演可否</div>
        <div className="view_content">
          <div className="view_item">
            <div className="view_title">出演承諾</div>
            <div className="view_value">{Number(is_approval) ? "OK":"NG"}</div>
          </div>
          {Number(is_approval) ? (
            <>
              <div className="view_item">
                <div className="view_title">タレント名</div>
                <div className="view_value">{talent_name ? talent_name : "未記入"}</div>
              </div>
              <div className="view_item">
                <div className="view_title">写真</div>
                <div className="view_item has_img">
                  {talent_image ? <div className="view_value_img"><Image url={talent_image} /></div> : <div className="view_value">なし</div>}
                </div>
              </div>
              <div className="view_item">
                <div className="view_title">自己紹介動画URL</div>
                <div className="view_value">
                  <OuterLink url={talent_movie_url} title={talent_movie_url} />
                </div>
              </div>
            </>
          ) : undefined}
        </div>
      </div>

      <div className="product_view_row">
        <div className="ir_head">その他参考動画</div>
        <div className="view_content">
          {others.map((row, index) => (
            <div className="view_value" key={index}>
              <div className="view_item">
                <div className="view_title">{row.others_comment} </div>
                <div className="view_value">
                  <OuterLink url={row.others_movie} title={row.others_movie} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
export default Step11View