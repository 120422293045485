import "../../../../common/wdyr";
import { FunctionComponent } from "react"
import * as React from "react"
import { IStep12 } from "../../AddProduct/step12/IStep12"
import "../../../../styles/maker/maker_common.css"
import { changeMultipleLines } from "../../../../common/utils"


export const Step12View: FunctionComponent<IStep12> = (props) => {
  const {content} = props

  return (
    <section className="product_view_wrap">
      <div className="product_view_row">
        <div className="ir_head">その他の追加情報や<br />伝えたい内容があれば<br />ご記入ください</div>
        <div className="view_content">
          <div className="view_item">
            <div className="view_value">{content ? changeMultipleLines(content) : "未記入"}</div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default Step12View