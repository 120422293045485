import * as React from "react"
import { FunctionComponent, useEffect, useState } from "react"
import { RouteProps } from "react-router"
import { Loading } from "../../atoms/Loading/Loading"
import { FormProvider, useForm } from "react-hook-form"
import MemberList from "../../templates/ProfileSetting/MemberList/MemberList"
import { get, getUserType } from "../../../common/utils"
import { COMPANIES, SEARCH_USER } from "../../../common/apiConstants"
import { AdminMemberList } from "../../templates/ProfileSetting/AdminMemberList/AdminMemberList"
import { BUYER_ADMIN, MAKER_ADMIN } from "../../../common/constants"

const ProfileAdminMembers: FunctionComponent<RouteProps> = () => {
  const method = useForm({ mode: "onBlur" })
  const [data, setData] = useState(null)
  const [total, setTotal] = useState(0)
  const [companyType, setCompanyType] = useState("")
  const [company, setCompany] = useState("")
  const [companyList, setCompanyList] = useState([])
  const [userList, setUserList] = useState([])
  const [loading, setLoading] = useState(false)
  const [cleateFlg, setCreateFlg] = useState()

  useEffect(() => {
    (async () => {
      if (company !== "") {
        setLoading(true)
        let paramObj = {params: {}}
        paramObj.params.keyword = company
        const memberList = await get(SEARCH_USER, paramObj).catch(e => console.log("e", e))
        console.log("memberList.result", memberList)
        setData(memberList)
        setLoading(false)
      }
    })()
  }, [company, cleateFlg])

  const selectedType = async (type) => {
    setCompanyList([])
    setUserList([])
    setTotal(0)
    if (type !== "") {
      setLoading(true)
      let paramObj = {params: {}}
      paramObj.params.type = type
      const companies = await get(COMPANIES, paramObj).catch(e => console.log("e", e))
      setCompanyList(companies.data ? companies.data : "")
    } else {
      setCompany("")
    }
    setCompanyType(type)
    setLoading(false)
  }

  const onSearch = async (id) => {
    setUserList([])
    if (id !== "") {
      setLoading(true)
      let paramObj = {params: {}}
      paramObj.params.keyword = ""
      paramObj.params.company_id = id
      const userList = await get(SEARCH_USER, paramObj).catch(e => console.log("e", e))
      console.log("userList", userList)
      setUserList(userList.result ? userList.result : "")
      let cnt = 0
      if (userList.result) {
        userList.result.map((user, index) => {
          if (user.type == BUYER_ADMIN || user.type == MAKER_ADMIN) {
            cnt++
          }
        })
      }
      setTotal(cnt)
    }
    setCompany(id)
    setLoading(false)
  }

  return (
    <main className="regist">
      <AdminMemberList
        {...data}
        loading={loading}
        total={total}
        company_id={company}
        company_type={companyType}
        companyList={companyList}
        userList={userList}
        selectedType={e => selectedType(e)}
        onSearch={e => onSearch(e)}
        setCreateFlg={e => setCreateFlg(e)}
      />
    </main>
  )
}

export default ProfileAdminMembers
