import { FunctionComponent, useEffect, useState } from "react"
import * as React from "react"
import "../../../styles/maker/maker_common.css"
import { IProducts } from "./IProducts"
import { ProductList } from "../../organisms/ProductList/ProductList"
import ProductListSideBar from "../../organisms/ProductList/sidebar/ProductListSideBar"
import { CustomPagination } from "../../molecules/Pagination/Pagination"

const Products: FunctionComponent<IProducts> = (props,location) => {
  const { products, loading, categoryName ,setKeyword, setPriceStart, setPriceEnd, searchStatus, setSearchStatus, questions, sort, setSort, onGetProducts, setPage } = props

  return (
    <>
      <main className="main_area_two_col">
        <div style={{order: 2}}>
          <ProductList {...products} loading={loading} categoryName={categoryName} sort={sort} setSort={setSort} setPage={setPage} />
          {products ? (
            <CustomPagination current_page={products.current_page} last_page={products.last_page} setPage={setPage} />
          ) : (
            <CustomPagination current_page={1} last_page={1} setPage={setPage} />
          )}
        </div>

        <ProductListSideBar
          current={'aaa'}
          setKeyword={setKeyword}
          setPriceStart={setPriceStart}
          setPriceEnd={setPriceEnd}
          searchStatus={searchStatus}
          setSearchStatus={e => setSearchStatus(e)}
          onGetProducts={onGetProducts}
        />
      </main>
    </>
  )
}

export default Products
