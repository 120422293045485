import "../../../../common/wdyr"
import { FunctionComponent,  useMemo, useState } from "react"
import * as React from "react"
import { FormProvider, useForm } from "react-hook-form"
import "../../../../styles/maker/maker_common.css"
import { RELATIVE_PATH } from "../../../../common/routePath"
import { Link, navigate } from "gatsby"

export const Regist: FunctionComponent = (props) => {
  const methods = useForm({
    mode: "onChange",
  })

  return (
    <section className="main_area_full main_center step regist">
      <h2 className="h2_2">商品新規登録 - 完了</h2>
      <FormProvider {...methods}>
          <div className="ir_box">
            <div className="ir_sub_title2">
              <h4 className="regist_content2">登録ありがとうございました。<br /> 審査への提出が完了しました。 </h4>
            </div>
            <button className="regist_check_btn" onClick={() => navigate(RELATIVE_PATH.CREATED_PRODUCTS)}>審査状況の確認</button>
            <div className="regist_content2"><Link to={RELATIVE_PATH.MAKER_TOP}>TOPへ戻る</Link></div>
          </div>
      </FormProvider>
    </section>
  )
}

export default Regist
