import * as React from "react"
import { FunctionComponent, useState } from "react"
import { Button, TextareaAutosize } from "@material-ui/core"
import { INotificationDetail } from "./INotificationDetail"
import * as moment from "moment"
import { Loading } from "../../../atoms/Loading/Loading"
import { del, isAdmin } from "../../../../common/utils"
import { MESSAGE_NOTICE_DELETE } from "../../../../common/apiConstants"
import { navigate } from "gatsby"
import { RELATIVE_PATH } from "../../../../common/routePath"
import Modal from "@material-ui/core/Modal"
import Backdrop from "@material-ui/core/Backdrop"
import Fade from "@material-ui/core/Fade"

export const NotificationDetail: FunctionComponent<INotificationDetail> = (props) => {
  const { created_at, updated_user, title, message } = props
  const [loading, setLoading] = useState(false)
  const [ showModal, setShowModal ] = useState(false)
  const [ complete, setComplete ] = useState(false)
  let retVal = undefined

  const deleteMessage = async () => {
    setShowModal(false)
    setLoading(true)
    let paramObj = {params: {}}
    paramObj.params.id = props.id
    await del(MESSAGE_NOTICE_DELETE, paramObj).then(response => {
      setComplete(true)
      setShowModal(true)
    }).catch(e => {
      alert(e)
    }).finally(() => setLoading(false))
  }

  retVal = (
    <section className="main_area notification_list">
      <div className="mp_product_title">
        <div className="list_title_box">
          <h2 className="top_title_h2">お知らせ詳細</h2>
        </div>
      </div>
      {message ? (
        <>
          {loading ? <Loading /> : undefined}
          <div className={isAdmin() ? "admin_notification_view" : ""}>
            <div className="detail_wrap">
              <div><span>お知らせ日時：</span>{moment(created_at).format("YYYY/MM/DD HH:mm")}</div>
              <div><span>From：</span>{updated_user.name}</div>
            </div>
            <h3>件名：{title}</h3>
            <div className="notification_body">
              <TextareaAutosize minRows={10} defaultValue={message} disabled />
            </div>
            {isAdmin() ? (
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button variant="contained" size="large" className="submitBtn" onClick={() => setShowModal(true)}>削除</Button>
              </div>
            ) : undefined}
          </div>
        </>
      ) : <Loading />}

      <Modal
        className="confirm_modal "
        open={showModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showModal}>
          <div className="modal_body">
            <h2 id="transition-modal-title">{complete ? "削除しました。一覧画面へ戻ります。" : "削除しますか？"}</h2>
            <span>
              {complete ? (
                <Button className="positive" variant="contained" onClick={() => navigate(RELATIVE_PATH.NOTIFICATIONS)}>はい</Button>
              ) : (
                <>
                  <Button className="positive" variant="contained" onClick={deleteMessage}>はい</Button>
                  <Button className="positive" variant="contained" onClick={() => setShowModal(false) }>いいえ</Button>
                </>
              )}
            </span>
          </div>
        </Fade>
      </Modal>
    </section>
  )

  return retVal
}