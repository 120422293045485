import * as React from "react"
import { FunctionComponent, useEffect, useState } from "react"
import { RouteProps } from "react-router"
import { Loading } from "../../atoms/Loading/Loading"
import Dealings from "../../templates/Message/DealingList/Dealings"
import { get } from "../../../common/utils"
import { DEALINGS } from "../../../common/apiConstants"

const DealingsPage: FunctionComponent<RouteProps> = () => {
  const [data, setData] = useState()
  const [status, setStatus] = useState(0)
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    (async () => {
      setLoading(true)
      let paramObj = {params: {}}
      paramObj.params.status = status
      paramObj.params.page = page
      const result = await get(DEALINGS, paramObj).catch(e => console.log("e", e))
      // @ts-ignore
      setData(result)
      setLoading(false)
    })()
  }, [page, status])

  const changePagination = (page) => {
    setPage(page)
  }
  const onSearch = (status) => {
    setStatus(status)
  }

  return data ? (
    <Dealings {...data} status={status} loading={loading} changePagination={e => changePagination(e)} onSearch={e => onSearch(e)} />
  ) : <Loading />
}

export default DealingsPage
