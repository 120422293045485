import * as React from "react"
import "../../../../../styles/common.css"
import {FunctionComponent} from "react";
import {NEWS_TYPE} from "../../../../../common/constants"
import * as moment from "moment"
import FavoriteImg from "../../../../../images/buyer/fav_no.svg"
import {IBuyerHeadBottom} from "./IBuyerHeadBottom";
import { RELATIVE_PATH } from "../../../../../common/routePath"
import { Link } from "gatsby"

const BuyerHeadBottom:FunctionComponent<IBuyerHeadBottom> = (props) => {
    const { news_release, showSideBar } = props

    return (
        <div className="head_lower_wrap">
            <div className="head_lower_box">
                <div className="h_side_btn_wrap">
                    <div className="h_side_btn" onClick={showSideBar} color="initial">カテゴリから探す</div>
                </div>
                <div className="h_news_wrap">
                    <div className="h_n_cate">{NEWS_TYPE[news_release["type"]]}</div>
                    <div className="h_n_day">{news_release["created_at"] ? moment(news_release["created_at"]).format("M/DD") : ""}</div>
                    <Link to={RELATIVE_PATH.NOTIFICATION_DETAIL + "/?id" + news_release.id} className="h_n_title">{news_release["title"]}</Link>
                    <Link to={RELATIVE_PATH.NOTIFICATIONS} className="h_n_list_link">お知らせ一覧</Link>
                </div>
                <div className="h_list_box">
                    <Link to={RELATIVE_PATH.FAVORITES} className="h_l_favo"><img src={FavoriteImg} alt="" className="product_fav fav_no" />お気に入り一覧</Link>
                    <Link to={RELATIVE_PATH.MAKER_LIST} className="h_l_maker">掲載メーカー一覧</Link>
                </div>
            </div>
        </div>
    )
}

export default BuyerHeadBottom