import * as React from "react"
import { FunctionComponent, useState } from "react"
import {
  Button,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableBody from "@material-ui/core/TableBody"
import TableContainer from "@material-ui/core/TableContainer"
import { withStyles } from "@material-ui/core/styles"
import TableCell from "@material-ui/core/TableCell"
import Backdrop from "@material-ui/core/Backdrop"
import Fade from "@material-ui/core/Fade"
import Modal from "@material-ui/core/Modal"
import { ICompanyList } from "./ICompanyList"
import { Loading } from "../../atoms/Loading/Loading"
import { COMPANY_TYPES, WITHDRAW_TYPES } from "../../../common/constants"
import { CustomPagination } from "../../molecules/Pagination/Pagination"
import { Controller, useForm, FormProvider } from "react-hook-form"
import { isBrowser, post } from "../../../common/utils"
import * as moment from 'moment-timezone'
import { COMPANY_SUBSCRIPTION, FORCE_WITHDRAW } from "../../../common/apiConstants"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

moment.tz.setDefault('Asia/Tokyo') // タイムゾーンをJSTに設定


const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell)

export const CompanyList: FunctionComponent<ICompanyList> = (props) => {
  const {
    total,
    data,
    loading,
    setLoading,
    current_page,
    companyType,
    last_page,
    setCompanyType,
    enableType,
    setEnableType,
    setPage,
    searchCompany
  } = props
  const [ editModal, setEditModal ] = useState(false)
  const [ editEnableModal, setEditEnableModal ] = useState(false)
  const [ editId, setEditId ] = useState()
  const [ editFrom, setEditFrom ] = useState("")
  const [ editTo, setEditTo ] = useState("")
  const [ editRemarks, setEditRemarks ] = useState("")
  const [ type, setType ] = useState(null)
  let retVal = undefined
  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(
      yup.object().shape({
        from: yup.mixed().test('match', '正しい日付を入力してください', function (from) {
          return from ? moment(from ? from : editFrom).isBefore(moment(this.parent.to ? this.parent.to : editTo)) : editFrom ? true : false
        }),
        to: yup.mixed().test('match', '正しい日付を入力してください', function (to) {
          return to ? moment(to ? to : editTo).isAfter(moment(this.parent.from ? this.parent.from : editFrom)) : editTo ? true : false
        }),
      })
    )
  })
  const { handleSubmit, control, formState: { errors } } = methods

  const onSubmit = async (data) => {
    setLoading(true)
    let paramObj = {}
    paramObj.id = editId
    paramObj.from = moment(new Date(data.from ? data.from : editFrom)).add(1, "days").toDate()
    paramObj.to = moment(new Date(data.to ? data.to : editTo)).add(1, "days").toDate()
    paramObj.remarks = editRemarks ? editRemarks : ""
    await post(COMPANY_SUBSCRIPTION, paramObj).catch(e => {
      console.log(e.message)
    }).finally(() => {
      setEditModal(false)
      setEditId(null)
      setEditFrom("")
      setEditTo("")
      setEditRemarks("")
      setLoading(false)
      isBrowser ? window.location.reload() : false
    })
  }

  const onWithdraw = async () => {
    setLoading(true)
    let paramObj = {}
    paramObj.id = editId
    await post(FORCE_WITHDRAW, paramObj).catch(e => {
      console.log(e.message)
    }).finally(() => {
      setEditEnableModal(false)
      setLoading(false)
      isBrowser ? window.location.reload() : false
    })
  }

  const showEditModal = (e, row) => {
    setEditId(row.id)
    setType(row.type)
    setEditFrom(moment.utc(row.from).format("YYYY-MM-DD"))
    setEditTo(moment.utc(row.to).format("YYYY-MM-DD"))
    setEditRemarks(row.remarks)
    setEditModal(true)
  }
  const closeEditModal = () => {
    setEditId(null)
    setEditFrom(null)
    setEditTo(null)
    setEditRemarks("")
    setEditModal(false)
  }

  const showEditEnableModal = (e, row) => {
    setEditId(row.id)
    setEditEnableModal(true)
  }

  const getStatusStyle = (status) => {
    if (status == 0) {
      return "open"
    } else if (status == 1) {
      return "remand"
    }
    return ""
  }

  retVal = (
    <section className="main_center admin_member">
      {loading ? <Loading /> : undefined}
      <div className="mp_product_title dealing">
        <div className="list_title_box">
          <h2 className="top_title_h2">会社一覧</h2>
          <div className="search_list_num"><span>{total ? total : 0}</span>件見つかりました</div>
        </div>
      </div>

      <div className="search_status">
        <FormControl variant="outlined" className="select_company_type">
          <Select
            displayEmpty
            labelId=""
            id=""
            value={companyType}
            onChange={e => setCompanyType(e.target.value)}
            IconComponent={props => (
              <ExpandMoreIcon {...props} className={`material-icons ${props.className}`}>
              </ExpandMoreIcon>
            )}
          >
            <MenuItem value="">
              <em>選択して下さい</em>
            </MenuItem>
            <MenuItem value={0}>バイヤー</MenuItem>
            <MenuItem value={1}>メーカー</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="outlined" className="select_company_type">
          <Select
            displayEmpty
            labelId=""
            id=""
            value={enableType}
            onChange={e => setEnableType(e.target.value)}
            IconComponent={props => (
              <ExpandMoreIcon {...props} className={`material-icons ${props.className}`}>
              </ExpandMoreIcon>
            )}
          >
            <MenuItem value="">
              <em>選択して下さい</em>
            </MenuItem>
            <MenuItem value={1}>有効期限内</MenuItem>
            <MenuItem value={0}>期限切れ</MenuItem>
          </Select>
        </FormControl>
        <Button className="submitBtn" onClick={() => searchCompany()}>検索</Button>
      </div>

      <div style={{ marginBottom: 20 }}>
        {data ? (
          <TableContainer>
            <Table className="vendin_table" aria-label="vendin_table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">No.</StyledTableCell>
                  <StyledTableCell align="left">会社名</StyledTableCell>
                  <StyledTableCell align="left">会社種別</StyledTableCell>
                  <StyledTableCell align="left">契約開始日付</StyledTableCell>
                  <StyledTableCell align="left">契約終了日付</StyledTableCell>
                  <StyledTableCell align="left">契約ステータス</StyledTableCell>
                  <StyledTableCell align="left"></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, index) => {
                  return (
                    <TableRow key={row.id}>
                      <StyledTableCell align="left">{row.id}</StyledTableCell>
                      <StyledTableCell align="left">{row.name}</StyledTableCell>
                      <StyledTableCell align="left">{COMPANY_TYPES[row.type]}</StyledTableCell>
                      <StyledTableCell align="left">{moment.utc(row.from).format("YYYY年MM月DD日")}</StyledTableCell>
                      <StyledTableCell align="left">{moment.utc(row.to).format("YYYY年MM月DD日")}</StyledTableCell>
                      <StyledTableCell align="left">
                        <div
                          className={`goods_table_status ${getStatusStyle(row.withdraw)}`}>{WITHDRAW_TYPES[row.withdraw]}</div>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Button
                          onClick={(e) => showEditModal(e, row)}
                          className="submitBtn"
                          disabled={row.withdraw ? true : false}
                          style={{ marginRight: 15 }}>変更</Button>
                        <Button
                          onClick={(e) => showEditEnableModal(e, row)}
                          disabled={row.withdraw ? true : false}
                          className="submitBtn">退会</Button>
                      </StyledTableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : <div style={{ textAlign: "center" }}>条件に一致する会社は見つかりませんでした</div>}
      </div>
      <CustomPagination current_page={current_page} last_page={last_page} setPage={setPage} />

      <Modal
        className="confirm_modal"
        open={editModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={editModal}>
          <div className={type ? "modal_body admin_company_modal" : "modal_body"}>
            <h2 id="transition-modal-title">契約期間編集</h2>
            <div>
              <FormProvider {...methods}>
                <form method="post" autoComplete="on" onSubmit={handleSubmit(onSubmit)}>
                  <div className="account_row">
                    <div className="account_title">契約開始日付</div>
                    <div className="account_input">
                      <Controller
                        name="from"
                        control={control}
                        render={({ field }) => <TextField
                          id="from"
                          defaultValue={editFrom}
                          onChange={e => (setEditFrom(e.target.value))}
                          variant="outlined"
                          color="secondary"
                          size="small"
                          type="date"
                          error={errors.from?.message}
                          helperText={errors.from?.message}
                          {...field} />}
                      />
                    </div>
                  </div>
                  <div className="account_row">
                    <div className="account_title">契約終了日付</div>
                    <div className="account_input">
                      <Controller
                        name="to"
                        control={control}
                        render={({ field }) => <TextField
                          id="to"
                          variant="outlined"
                          defaultValue={editTo}
                          onChange={e => (setEditTo(e.target.value))}
                          color="secondary"
                          size="small"
                          type="date"
                          error={errors.to?.message}
                          helperText={errors.to?.message}
                          {...field} />}
                      />
                    </div>
                  </div>
                  {type ? (
                    <div className="account_row">
                      <div className="account_title">備考</div>
                      <div className="account_input">
                      <textarea
                        id="remarks"
                        defaultValue={editRemarks}
                        onBlur={e => (setEditRemarks(e.target.value))}
                      />
                      </div>
                    </div>
                  ) : undefined}
                  <Button className="positive" variant="contained" type={"submit"}>保存</Button>
                  <Button className="positive" variant="contained" onClick={() => closeEditModal()}>閉じる</Button>
                </form>
              </FormProvider>
            </div>
          </div>
        </Fade>
      </Modal>

      <Modal
        className="confirm_modal"
        open={editEnableModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={editEnableModal}>
          <div className="modal_body">
            <h2 id="transition-modal-title">退会処理を行います。よろしいですか？</h2>
            <div>※退会した会社の商品、商談内容等は削除されます。</div>
            <div style={{ paddingTop: 15 }}>
              <Button className="positive" variant="contained" onClick={() => onWithdraw()}>はい</Button>
              <Button className="positive" variant="contained" onClick={() => {
                setEditId(null)
                setEditEnableModal(false)
              }}>いいえ</Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </section>
  )

  return retVal
}