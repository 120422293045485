import * as React from "react"
import { FunctionComponent } from "react"
import { IProfileSideBar } from "./IProfileSideBar"
import { RELATIVE_PATH } from "../../../../common/routePath"
import { getUserType, isCompanyAdmin } from "../../../../common/utils"
import { ADMIN, BUYER, BUYER_ADMIN, MAKER, MAKER_ADMIN } from "../../../../common/constants"
import { Link } from "gatsby"

const ProfileSideBar: FunctionComponent<IProfileSideBar> = (props) => {
  const { current } = props

  const renderSideContent = () => {
    const userType = getUserType()
    let topPath
    if (parseInt(userType) === MAKER_ADMIN) {
      topPath = (
        <>
          <li className={current === "public_info" ? "side_item active" : "side_item"}>
            <Link to={RELATIVE_PATH.COMPANY} className="side_link">公開情報設定</Link>
          </li>
          <li className={current === "brand_info" ? "side_item active" : "side_item"}>
            <Link to={RELATIVE_PATH.COMPANY_BRAND} className="side_link">ブランド情報設定</Link>
          </li>
        </>
      )
    }
    return topPath
  }

  return (
    <section className="side_area">
      <h2 className="side_title">プロフィール設定</h2>
      <ul className="side_bar">
        <li className={current === "account" ? "side_item active" : "side_item"}>
          <Link to={RELATIVE_PATH.ACCOUNT} className="side_link">アカウント情報</Link>
        </li>
        {isCompanyAdmin() ? (
          <>
            <li className={current === "member" ? "side_item active" : "side_item"}>
              <Link to={RELATIVE_PATH.MEMBER} className="side_link">担当者アカウント情報</Link>
            </li>
            {renderSideContent()}
          </>
        ) : undefined}
      </ul>
    </section>
  )
}

export default ProfileSideBar
