import { FunctionComponent } from "react"
import * as React from "react"
import { MakerDetail } from "../../organisms/MakerDetail/MakerDetail"
import "../../../styles/buyer/maker_list.css"
import MakerDetailSideBar from "../../organisms/MakerDetail/SideBar/MakerDetailSideBar"

const Maker: FunctionComponent = (props) => {

  return (
    <main className="main_area_two_col">
      <MakerDetail {...props} />
      <MakerDetailSideBar  {...props} current={"profile_top"} />
    </main>
  )
}

export default Maker
