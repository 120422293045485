import * as React from "react"
import { FunctionComponent, useState } from "react"
import { IPagination } from "./IPagination"
import Pagination from '@material-ui/lab/Pagination'

export const CustomPagination: FunctionComponent<IPagination> = (props) => {
  const { current_page, last_page, setPage } = props
  const [pageVal, setPageVal] = useState(1)

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageVal(value)
    setPage(value)
  }

  return (
    <Pagination count={last_page} page={current_page} defaultPage={1} onChange={handleChange} className="custom_pagination" />
  )
}
