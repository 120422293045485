import * as React from "react"
import "../../../../../styles/common.css"
import {FunctionComponent} from "react"
import closeImage from "../../../../../images/common/batu.svg"
import IBuyerSideBar from "./IBuyerSideBar"
import { Slide } from "@material-ui/core"
import TreeView from '@material-ui/lab/TreeView'
import TreeItem from '@material-ui/lab/TreeItem'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import { RELATIVE_PATH } from "../../../../../common/routePath"
import { Link, navigate } from "gatsby"
import Contact from "../contact/Contact"

const BuyerSideBar: FunctionComponent<IBuyerSideBar> = (props) => {
    const {is_open, product_count, categorys, closeSidebar} = props

    return (
        <Slide direction="right" in={is_open} mountOnEnter unmountOnExit>
        <section className="side_menu buyer">
            <div className="side_menu_wrap">
                <div onClick={closeSidebar}><img src={closeImage} alt="" className="sm_batu" /></div>
                <h2 className="sm_title">すべての商品（<span>{product_count}</span>）</h2>
                <TreeView
                    className="sm_list"
                    defaultCollapseIcon={<RemoveIcon />}
                    defaultExpandIcon={<AddIcon />}
                >
                    {categorys.map((item, index) => {
                        if (item["sub_categories"]) {
                            let parentCategory = (
                                <TreeItem nodeId={`side_bar_${index}`} key={`side_key_${index}`} label={item["title"]} className="sm_list">
                                    {item["sub_categories"].map((child_item, child_index) => (
                                        <TreeItem
                                          nodeId={`child_side_bar_${child_index}`}
                                          key={`child_side_key_${child_index}`}
                                          label={child_item["title"]}
                                          className="child_item"
                                          onClick={() => {
                                              closeSidebar()
                                              navigate(RELATIVE_PATH.PRODUCTS, {state:{sub_category_id: child_item["id"], category_name: child_item["title"]}})
                                          }}
                                        />
                                    ))}
                                </TreeItem>
                            )
                            return parentCategory
                        } else {
                            return <TreeItem nodeId={`side_bar_${index}`} key={`side_key_${index}`} label={item["title"]} />
                        }
                    })}
                </TreeView>
                <h2 className="sm_title">メッセージ管理</h2>
                <ul className="sm_list">
                    <li className="sm_innter_item"><Link to={RELATIVE_PATH.NOTIFICATIONS} onClick={closeSidebar}>お知らせ</Link></li>
                    <li className="sm_innter_item"><Link to={RELATIVE_PATH.DEALINGS} onClick={closeSidebar}>商談</Link></li>
                    <li className="sm_innter_item"><Contact /></li>
                </ul>
            </div>
        </section>
        </Slide>
    )
}

export default BuyerSideBar