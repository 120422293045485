import { FunctionComponent, useEffect, useMemo, useState } from "react"
import * as React from "react"
import "../../../styles/product_view.css"
import { IProduct } from "./IProduct"
import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, Grid } from "@material-ui/core"
import { OuterLink } from "../../atoms/OuterLink/OuterLink"
import * as moment from "moment"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ReportProblemIcon from '@material-ui/icons/ReportProblem'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
import ZoomInIcon from '@material-ui/icons/ZoomIn'
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import Step2View from "../ProductView/step2/Step2View"
import ProductView from "../ProductView/ProductView"
import PlayArrowIcon from "@material-ui/icons/PlayArrow"
import Modal from "@material-ui/core/Modal"
import Backdrop from "@material-ui/core/Backdrop"
import Fade from "@material-ui/core/Fade"
import { MoviePlayer } from "../../molecules/MoviePlayer/MoviePlayer"
import { Movie } from "../../atoms/Movie/Movie"
import {
  addOnlyLocalDB, changeMultipleLines,
  deleteStore,
  get,
  getLocalUser,
  getUserType,
  isBrowser,
  post,
  renderText
} from "../../../common/utils"
import { FormProvider, useForm } from "react-hook-form"
import { TextAreaForm } from "../../molecules/TextAreaForm/TextAreaForm"
import Step5View from "../ProductView/step5/Step5View"
import Step6View from "../ProductView/step6/Step6View"
import Step7View from "../ProductView/step7/Step7View"
import { navigate } from "gatsby"
import Step8View from "../ProductView/step8/Step8View"
import Step9View from "../ProductView/step9/Step9View"
import Step10View from "../ProductView/step10/Step10View"
import Step11View from "../ProductView/step11/Step11View"
import Step12View from "../ProductView/step12/Step12View"
import AnchorLink from 'react-anchor-link-smooth-scroll'
import {
  DEALING_START, DOCUMENT_REQUEST,
  PRODUCT_ANSWER, PRODUCT_DELETE,
  PRODUCT_QUESTION,
  PRODUCT_REGISTER_FORM,
  PRODUCT_REVIEW
} from "../../../common/apiConstants"
import { ADMIN, BUYER, BUYER_ADMIN, MAKER, MAKER_ADMIN } from "../../../common/constants"
import { RELATIVE_PATH } from "../../../common/routePath"
import * as _ from "lodash"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: 400,
      borderRadius: 5,
      borderColor: "#ccc"
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },
    accountBlock: {
      marginBottom: "2rem",
      width: '100%',
    },
    heading: {
      fontWeight: theme.typography.fontWeightBold,
    },
  }),
)

const Product: FunctionComponent<IProduct> = (props, location) => {
  const { company, product_info, questions, review, isDealing } = props
  const [ currentImage, setCurrentImage ] = useState(product_info.step2.images.length >= 1 ? product_info.step2.images[0]["image"] : null)
  const [ showMore, setShowMore ] = useState(false)
  const [ expandPanel, setExpandPanel ] = useState<string | false>(false)
  const [ imageModal, setImageModal ] = useState(false)
  const [ imageUrl, setImageUrl ] = useState()
  const [ showDealingModal, setShowDealingModal ] = useState(false)
  const [ dealingSuccess, setDealingSuccess ] = useState(false)
  const [ documentLoading, setDocumentLoading ] = useState(false)
  const [ documentSuccess, setDocumentSuccess ] = useState(false)
  const [ showDocumentModal, setShowDocumentModal ] = useState(false)
  const [ showQaModal, setShowQaModal ] = useState(false)
  const [ questionValue, setQuestionValue ] = useState("")
  const [ showDeleteModal, setShowDeleteModal ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const localUser = getLocalUser()
  const userType = getUserType()
  const methods = useForm({
    mode: "onBlur",
  })
  console.log("product_info", product_info)

  const { handleSubmit } = methods
  const classes = useStyles()

  const getDiscountRate = () => {
    return (1 - (product_info.step2.broadcast_price / product_info.step2.regular_price)) * 100
  }
  const getDiffPrice = () => {
    return product_info.step2.regular_price - product_info.step2.broadcast_price
  }

  const showImageModal = (url) => {
    setImageUrl(url)
    setImageModal(true)
  }

  const formatVariableForm = (stepForm) => {
    let copyForm = _.cloneDeep(stepForm)
    let valueObj = Object.values(copyForm)

    if (valueObj.length >= 1) {
      valueObj.forEach((dataRow, index) => {
        let copyRow = Object.values(dataRow["forms"])
        copyRow.forEach((form, idx) => {
          form["attributes"].forEach((item, i) => {
            item.style = item.style ? JSON.parse(item.style) : ""
            item.rules = item.rules ? JSON.parse(item.rules) : ""
            item.selects = item.selects ? JSON.parse(item.selects) : ""
            if (item.input_type === "4") {
              item.value = item.value.split(",")
            } else if (item.input_type === "2" || item.input_type === "3") {
              item.value = item.value.toString()
            }
            // console.log(item.name, item.value, typeof item.value)
          })
        })
      })
    }
    return copyForm
  }

  const onEditProductInfo = async () => {
    // localDBのデータを一回クリア
    await deleteStore()

    // localStrageの「product_forms」を削除
    localStorage.removeItem("product_forms")

    // カテゴリに応じた「product_forms」をlocalstrageへ保存
    let paramObj = { params: {} }
    paramObj.params.id = Number(product_info.step1["category"])
    const data = await get(PRODUCT_REGISTER_FORM, paramObj).catch(e => console.log("PRODUCT_REGISTER_FORM", e))
    localStorage.setItem("product_forms", JSON.stringify(data))

    // propsのproduct_infoをlocalDBへ保存
    const infoKeys = Object.keys(product_info)
    infoKeys.map(async (key, index) => {
      let copyData
      if (key === "step3" || key === "step4") {
        console.log("onEditProductInfo", product_info[key])
        copyData = formatVariableForm(product_info[key])
        console.log("onEditProductInfo copyData", copyData)
      } else {
        copyData = product_info[key]
      }
      await addOnlyLocalDB(key, JSON.stringify(copyData))
    })

    navigate(RELATIVE_PATH.STEP1, { state: { is_edit: true } })
  }

  const renderProductView = (data: JSX.Element, title, panel) => {
    return (
      <Accordion expanded={expandPanel === panel} className="accordion_body" key={panel}>
        <AccordionSummary onClick={() => expandPanel === panel ? setExpandPanel("") : setExpandPanel(panel)}>
          <div className="accordion_title">
            {expandPanel === panel ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />}
            <div className="accordion_title_area">
              <label className={classes.heading}
                     style={review && review[panel] ? { color: "red" } : { color: "black" }}>
                {title}
              </label>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails className="input_approve_memo">
          <div>{data}</div>
          {review && review[panel] ? (
            <div className="approve_memo">
              <h4>管理者記入欄</h4>
              <div className="ir_sub_item">
                <textarea
                  id={`approve_memo_${panel}`}
                  name={`approve_memo_${panel}`}
                  value={review ? review[panel] : undefined}
                  disabled={true}
                  style={{ backgroundColor: "#fafafa" }}
                />
              </div>
            </div>
          ) : undefined}
        </AccordionDetails>
      </Accordion>
    )
  }

  const onDealing = async () => {
    setLoading(true)
    let paramObj = {}
    paramObj.product_id = product_info.step2.id
    await post(DEALING_START, paramObj).then(response => {
      console.log("DEALING_START　success", response)
      setDealingSuccess(true)
      // navigate(RELATIVE_PATH)
    }).catch(e => {
      console.log("DEALING_START", e)
      setDealingSuccess(false)
    }).finally(() => {
      setLoading(false)
    })
  }

  const onPostQuestion = async data => {
    // setLoading(true)
    let paramObj = {}
    paramObj.product_id = product_info.step2.id
    paramObj.message = data.add_question
    console.log("onPost", paramObj)
    await post(PRODUCT_QUESTION, paramObj).then(response => {
      console.log("PRODUCT_QUESTION　success", response)
      setQuestionValue("")
      setShowQaModal(true)
    }).catch(e => {
      console.log("PRODUCT_QUESTION", e)
    }).finally(() => {
      setLoading(false)
    })
  }

  const renderEditButton = () => {
    let rtnDom = undefined
    if (Number(userType) === MAKER || Number(userType) === MAKER_ADMIN) {
      rtnDom = (
        <section className="main_center" style={{ textAlign: "center" }}>
          <Button
            className="product_edit_btn"
            variant="contained"
            onClick={() => onEditProductInfo()}
            disabled={product_info.step2["approval_status"] === "0"}
          >
            商品情報を編集する
          </Button>
        </section>
      )
    }

    return rtnDom
  }

  const renderDeleteButton = () => {
    let rtnDom = undefined
    if (Number(userType) === ADMIN || Number(userType) === MAKER_ADMIN) {
      rtnDom = (
        <section className="main_center" style={{ textAlign: "center" }}>
          <Button
            className="product_delete_btn"
            variant="contained"
            onClick={() => setShowDeleteModal(true)}
            disabled={product_info.step2["is_selling"] === 0}
          >
            商品を非公開にする
          </Button>
        </section>
      )
    }
    return rtnDom
  }

  //非公開表示
  const renderUnSelling = () => {
    let retVal = undefined

    if (product_info.step2["is_selling"] === 0) {
      retVal = (<div className="product_un_selling">非公開</div>)
    }
    return retVal
  }

  const onPostDelete = async () => {
    setLoading(true)
    let paramObj = {}
    paramObj.id = product_info.step2.id
    await post(PRODUCT_DELETE, paramObj).then(response => {
      console.log("PRODUCT_DELETE　success", response)
    }).catch(e => {
      console.log("PRODUCT_DELETE", e)
    }).finally(() => {
      setShowDeleteModal(false)
      setLoading(false)
      isBrowser ? window.location.reload() : false
    })
  }

  const renderQuestionText = useMemo(() => {
    return (
      <TextAreaForm
        id={`add_question`}
        name={`add_question`}
        value={questionValue}
      />
    )
  }, [ questionValue ])

  const onSubmitDocument = async () => {
    setDocumentLoading(true)
    let paramObj = {}
    paramObj.product_id = product_info.step2.id
    await post(DOCUMENT_REQUEST, paramObj).then(response => {
      console.log("DOCUMENT_REQUEST　success", response)
      setDocumentSuccess(true)
    }).catch(e => {
      console.log("DEALING_START", e)
      setDocumentSuccess(false)
    }).finally(() => {
      setDocumentLoading(false)
    })
  }


  return (
    <main className="regist">
      <section className="main_center product_detail product_detail_top">
        <Grid container justifyContent="space-around">
          <Grid item md={5}>
            <Grid container spacing={2}>
              <Grid item md={3} className="sub_images">
                {product_info.step2.images.map((item, index) => (
                  <div className="view_item has_img" key={`${index}_view_img`}>
                    <div className="view_value_img">
                      <div><img className={currentImage === item.image ? "current" : ""} src={item.image}
                                onClick={() => setCurrentImage(item.image)} /></div>
                    </div>
                  </div>
                  ))}
                {product_info.step2.movie ? (
                  <div className="view_item has_img">
                    <div className="view_value_img has_movie">
                      <Movie url={product_info.step2.movie} />
                    </div>
                  </div>
                ) : undefined}
              </Grid>
              <Grid item md={9} className="current_image">
                {currentImage ? (
                  <>
                    <ZoomInIcon className="zoom_icon" onClick={() => showImageModal(currentImage)} />
                    <div><img src={currentImage} /></div>
                    {renderUnSelling()}
                  </>
                ) : (
                  <>
                    <div style={{textAlign: "center"}}>商品画像なし</div>
                    {renderUnSelling()}
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid item md={6} className="product_right_info">
            <h3>{product_info.step2.name}</h3>
            <div className="product_id">No.{product_info.step2.id}</div>
            <div className="brand_name"><OuterLink url={product_info.step2.order_brand_url}
                                                   title={product_info.step2.order_brand_name} />
            </div>
            <div className="regular_price">希望小売価格：<span>￥{product_info.step2.regular_price.toLocaleString()}（税抜）</span>
            </div>
            <div className="sale_price">
              <div className="label">放送当日希望販売価格</div>
              ￥{product_info.step2.broadcast_price.toLocaleString()}（税抜）
              <div className="diff_text">
                {Number(product_info.step2.regular_price_type) ? "メーカー直販価格" : "メーカー希望小売価格"}
                より{Math.round(getDiscountRate())}%（￥{getDiffPrice()}）お得
              </div>
            </div>
            <div className="stock_wrap">
              <Grid container>
                <Grid item md={"auto"} className="stock">
                  <div>在庫：<span className="stock_result">{product_info.step2.stock ? "○" : "×"}</span><span
                    className="now_date">{moment().format("YYYY/M/D")}更新時点</span></div>
                </Grid>
              </Grid>
            </div>
            <div className={showMore ? "sales_achievements_wrap open" : "sales_achievements_wrap"}>
              <div className={showMore ? "overlay_box open" : "overlay_box"} />
              <div className="achievement">
                {changeMultipleLines(product_info.step6.sales_title ? product_info.step6.sales_title : "")}
              </div>
            </div>
            <div className="read_more_block">
              {showMore ? (
                <div className="read_more" onClick={() => setShowMore(false)}>閉じる<ExpandLessIcon fontSize={"small"} />
                </div>
              ) : (
                <div className="read_more" onClick={() => setShowMore(true)}>続きを読む<ExpandMoreIcon fontSize={"small"} />
                </div>
              )}
            </div>
            <div className="maker_block">
              <Grid container justifyContent="space-evenly" alignItems="center">
                <Grid item md={3}>
                  <div><img src={company.logo} width={110} /></div>
                </Grid>
                <Grid item md={3}>
                  <Button
                    className="application_btn"
                    variant="contained"
                    disabled={(Number(userType) !== BUYER && Number(userType) !== BUYER_ADMIN) || isDealing}
                    onClick={() => setShowDealingModal(true)}>商談申込</Button>
                </Grid>
                <Grid item md={3}>
                  <Button
                    className="application_btn"
                    variant="contained"
                    disabled={Number(userType) !== BUYER && Number(userType) !== BUYER_ADMIN}
                    onClick={() => setShowDocumentModal(true)}>資料請求</Button>
                </Grid>
                <Grid item md={3}>
                  <AnchorLink href="#qaArea">
                    <Button
                      className="application_btn"
                      disabled={Number(userType) !== BUYER && Number(userType) !== BUYER_ADMIN}
                      variant="contained">質問する</Button>
                  </AnchorLink>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>

        <Modal
          className="confirm_modal"
          open={showDealingModal}
          onClose={() => setShowDealingModal(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={showDealingModal}>
            <div className="modal_body">
              {dealingSuccess ? (
                <h2 id="transition-modal-title">商談申し込み完了</h2>
              ) : (
                <>
                  <h2 id="transition-modal-title">商談申し込みします。よろしいですか？</h2>
                  <Button
                    className="positive"
                    variant="contained"
                    onClick={() => onDealing()}
                    startIcon={documentLoading ? <CircularProgress size={20} color={"inherit"} /> : null}
                  >はい</Button>
                </>
              )}
              <Button className="negation" variant="outlined" onClick={() => {
                setShowDealingModal(false)
                setDealingSuccess(false)
              }}>閉じる</Button>
            </div>
          </Fade>
        </Modal>

        <Modal
          className="confirm_modal"
          open={showDocumentModal}
          onClose={() => setShowDocumentModal(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={showDocumentModal}>
            <div className="modal_body">
              {documentSuccess ? (
                <>
                  <h2 id="transition-modal-title">資料のご請求ありがとうございました</h2>
                  <Button className="negation" variant="outlined" onClick={() => setShowDocumentModal(false)}>閉じる</Button>
                </>
              ) : (
                <>
                  <h2 id="transition-modal-title">{product_info.step2.name}の資料請求をします。よろしいですか？</h2>
                  <Button className="positive" variant="contained" onClick={() => onSubmitDocument()}>はい</Button>
                  <Button className="negation" variant="outlined" onClick={() => setShowDocumentModal(false)}>いいえ</Button>
                </>
              )}
            </div>
          </Fade>
        </Modal>
      </section>

      <section className="main_center product_detail">
        <div className={classes.accountBlock}>
          {product_info.step2 ? renderProductView(<Step2View {...product_info.step2} />, "商品基本情報", "step2") : undefined}
          {product_info.step3 ? renderProductView(<ProductView {...product_info.step3} />, "商品仕様", "step3") : undefined}
          {product_info.step4 && (Number(userType) !== BUYER && Number(userType) !== BUYER_ADMIN) ? renderProductView(
            <ProductView {...product_info.step4} />, "商品分類別情報", "step4") : undefined}
          {product_info.step6 ? renderProductView(
            <Step6View {...product_info.step6} />, "セールスポイント - 商品概要", "step6") : undefined}
          {product_info.step7 ? renderProductView(
            <Step7View {...product_info.step7} />, "セールスポイント - 効果", "step7") : undefined}
          {product_info.step8 ? renderProductView(
            <Step8View {...product_info.step8} />, "セールスポイント - 実績", "step8") : undefined}
          {product_info.step9 ? renderProductView(
            <Step9View {...product_info.step9} />, "セールスポイント - 基本", "step9") : undefined}
          {product_info.step10 ? renderProductView(
            <Step10View {...product_info.step10} />, "セールスポイント - 実演", "step10") : undefined}
          {product_info.step11 ? renderProductView(
            <Step11View {...product_info.step11} />, "セールスポイント - 出演情報", "step11") : undefined}
          {product_info.step5 ? renderProductView(
            <Step5View {...product_info.step5} />, "返品・サポート情報", "step5") : undefined}
          {product_info.step12 ? renderProductView(
            <Step12View {...product_info.step12} />, "その他", "step12") : undefined}
        </div>
      </section>

      {renderEditButton()}

      <section className="main_center product_detail questions">
        <h3>質問一覧</h3>
        <div className="question_wrap">
          {questions ? questions.map((question, i) => {
            if (question.answer !== null && question.answer !== undefined) {
              return (
                <div className="question_item" key={`${i}_question`}>
                  <div className="question"><span>Q</span>{renderText(question.question)}</div>
                  <div className="answer"><span>回答：</span>{renderText(question.answer)}</div>
                </div>
              )
            }
          }) : undefined}

          <h4 id="qaArea">質問を投稿する</h4>
          <div className="question_form_caution">※質問に対する回答後、質問は全てのバイヤーに公開されます。<br />
            ※質問者は匿名となり、メーカーに公開されません。<br />※管理者が不適切と判断した場合は、削除する場合がございます。あらかじめご了承ください。
          </div>
          <FormProvider {...methods}>
            <form className="question_form" onSubmit={handleSubmit(onPostQuestion)}>
              {renderQuestionText}
              <div className="submit_buttons">
                <Button className="addBtn" type="submit"
                        disabled={Number(userType) !== BUYER && Number(userType) !== BUYER_ADMIN}>投稿する</Button>
              </div>
            </form>
          </FormProvider>
        </div>

        <Modal
          className="confirm_modal"
          open={showQaModal}
          onClose={() => setShowQaModal(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={showQaModal}>
            <div className="modal_body">
              <h2 id="transition-modal-title">ご質問ありがとうございます。<br />頂いたご質問は回答後に表示されます。</h2>
              <Button className="negation" variant="outlined" onClick={() => {
                isBrowser ? window.location.reload() : false
              }}>閉じる</Button>
            </div>
          </Fade>
        </Modal>
      </section>

      {renderDeleteButton()}

      <Modal
        className="confirm_modal"
        open={imageModal}
        onClose={() => setImageModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={imageModal}>
          <div className="modal_body">
            <div className="movie_wrap">
              <img src={imageUrl} style={{maxWidth: "800px"}} />
            </div>
          </div>
        </Fade>
      </Modal>

      <Modal
        className="confirm_modal"
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showDeleteModal}>
          <div className="modal_body">
            <h2 id="transition-modal-title">商品を非公開にします。よろしいですか？</h2>
            <Button className="positive" variant="contained" onClick={() => onPostDelete()}>はい</Button>
            <Button className="negation" variant="outlined" onClick={() => setShowDeleteModal(false)}>いいえ</Button>
          </div>
        </Fade>
      </Modal>

    </main>

  )
}

export default Product
