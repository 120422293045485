import * as React from "react"
import { FunctionComponent, useState } from "react"
import { IMessageList } from "./IMessageList"
import { Message } from "../../molecules/Message/Message"

export const MessageList: FunctionComponent<IMessageList> = (props) => {
  const { messageList } = props

  return (
    <div className="top_message_box">
      {messageList.map((message, index) => (
        <Message key={`${index}_custom_form`} {...message} />
      ))}
    </div>
  )
}
