import * as React from "react"
import { Router } from "@reach/router"
import Layout from "../../src/components/pages/layout"
import Login from "../../src/components/pages/login"
import AdminTop from "../../src/components/pages/admin/top"
import MakerTop from "../../src/components/pages/maker/top"
import BuyerTop from "../../src/components/pages/buyer/top"
import ProfileAccount from "../components/pages/profile/profileAccount"
import ProfileMembers from "../components/pages/profile/profileMembers"
import Products from "../components/pages/products/products"
import { PrivateRoute } from "../components/privateRoute"
import { useDispatch, useSelector } from "react-redux"
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import { Button } from "@material-ui/core"
import { navigate } from "gatsby"
import { SESSION_SUCCESS } from "../store/auth/actionType"
import { FROM_APP_PATH } from "../common/routePath"
import { useCommonData } from "../common/customHooks"
import NotificationsPage from "../components/pages/message/notifications"
import DealingsPage from "../components/pages/message/dealings"
import ApprovalsPage from "../components/pages/message/approvals"
import QuestionsPage from "../components/pages/message/questions"
import QuestionPage from "../components/pages/message/question"
import NotificationPage from "../components/pages/message/notification"
import Makers from "../components/pages/makerList/makers"
import makerView from "../../src/components/pages/makerDetail/makerView"
import MakerProductsView from "../components/pages/makerDetail/makerProdcutsView"
import Step1Page from "../components/pages/addProduct/step1Page"
import Step2Page from "../components/pages/addProduct/step2Page"
import Step3Page from "../components/pages/addProduct/step3Page"
import Step4Page from "../components/pages/addProduct/step4Page"
import NotificationEditPage from "../components/pages/message/notificationEdit"
import DealingPage from "../components/pages/message/dealing"
import ProfilePublicInfo from "../components/pages/profile/profilePublicInfo"
import ProfileAdminMembers from "../components/pages/profile/profileAdminMembers"
import Step5Page from "../components/pages/addProduct/step5Page"
import Step6Page from "../components/pages/addProduct/step6Page"
import Step7Page from "../components/pages/addProduct/step7Page"
import Step8Page from "../components/pages/addProduct/step8Page"
import Step9Page from "../components/pages/addProduct/step9Page"
import Step10Page from "../components/pages/addProduct/step10Page"
import Step11Page from "../components/pages/addProduct/step11Page"
import Step12Page from "../components/pages/addProduct/step12Page"
import Step13Page from "../components/pages/addProduct/step13Page"
import RegistPage from "../components/pages/addProduct/RegistPage"
import FavoritesPage from "../components/pages/favoriteList/favorites"
import Product from "../components/pages/product/product"
import ApprovePage from "../components/pages/approve/approvePage"
import CreatedProductsPage from "../components/pages/products/createdProducts"
import { isBrowser } from "../common/utils"
import { useEffect, useState } from "react"
import ProfileBrandInfo from "../components/pages/profile/profileBrandInfo"
import Companies from "../components/pages/companies/companies"
import { Helmet } from "react-helmet"

const App = (props) => {
  const [commonData] = useCommonData()
  const dispatch = isBrowser ? useDispatch() : null
  const stateUser = isBrowser ? useSelector((state) => state) : null
  const [headerData, setHeaderData] = useState()
  let is_session_available
  let on_logout
  if (isBrowser) {
    is_session_available = stateUser.user.is_session_available
    on_logout = stateUser.user.on_logout
  }

  const onClose = async () => {
    await navigate("/")
    dispatch({
      type: SESSION_SUCCESS
    })
  }

  useEffect(() => {
    if (!stateUser.user.on_logout) {
      const commonData = JSON.parse(localStorage.getItem("commonData"))
      setHeaderData(commonData.header)
    }
  }, [props])

  return is_session_available && !on_logout ? (
    <>
      <Modal
        className="confirm_modal"
        open={is_session_available}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={is_session_available}>
          <div className="modal_body">
            <h2 id="transition-modal-title">セッションの有効期限が切れました</h2>
            <Button className="positive" variant="contained" onClick={onClose}>OK</Button>
          </div>
        </Fade>
      </Modal>
    </>
  ) : commonData ? (
    <>
      <Helmet>
        <title>VENDIN</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Layout
        header={headerData}
        header_lower={commonData.header_lower}
        side_bar={commonData.side_bar}
      >
        <Router basepath={"/app"}>
          <Login path={"/login"} />
          <PrivateRoute component={AdminTop} path={FROM_APP_PATH.ADMIN_TOP} />
          <PrivateRoute component={MakerTop} path={FROM_APP_PATH.MAKER_TOP} />
          <PrivateRoute component={BuyerTop} path={FROM_APP_PATH.BUYER_TOP} />
          <PrivateRoute component={Makers} path={FROM_APP_PATH.MAKER_LIST} />
          <PrivateRoute component={makerView} path={FROM_APP_PATH.MAKER} />
          <PrivateRoute component={MakerProductsView} path={FROM_APP_PATH.MAKER_PRODUCT} />
          <PrivateRoute component={ProfileAccount} path={FROM_APP_PATH.ACCOUNT} />
          <PrivateRoute component={ProfileMembers} path={FROM_APP_PATH.MEMBER} />
          <PrivateRoute component={ProfilePublicInfo} path={FROM_APP_PATH.COMPANY} />
          <PrivateRoute component={ProfileBrandInfo} path={FROM_APP_PATH.COMPANY_BRAND} />
          <PrivateRoute component={Companies} path={FROM_APP_PATH.ADMIN_COMPANY} />
          <PrivateRoute component={ProfileAdminMembers} path={FROM_APP_PATH.ADMIN_LIST} />
          <PrivateRoute component={NotificationsPage} path={FROM_APP_PATH.NOTIFICATIONS} />
          <PrivateRoute component={NotificationPage} path={FROM_APP_PATH.NOTIFICATION_DETAIL} />
          <PrivateRoute component={NotificationEditPage} path={FROM_APP_PATH.NOTIFICATION_DETAIL_EDIT} />
          <PrivateRoute component={DealingsPage} path={FROM_APP_PATH.DEALINGS} />
          <PrivateRoute component={DealingPage} path={FROM_APP_PATH.DEALING_DETAIL} />
          <PrivateRoute component={ApprovalsPage} path={FROM_APP_PATH.APPROVALS} />
          <PrivateRoute component={ApprovePage} path={FROM_APP_PATH.APPROVAL} />
          <PrivateRoute component={QuestionsPage} path={FROM_APP_PATH.QUESTIONS} />
          <PrivateRoute component={QuestionPage} path={FROM_APP_PATH.QUESTION} />
          <PrivateRoute component={CreatedProductsPage} path={FROM_APP_PATH.CREATED_PRODUCTS} />
          <PrivateRoute component={Products} path={FROM_APP_PATH.PRODUCTS} />
          <PrivateRoute component={Product} path={FROM_APP_PATH.PRODUCT} />
          <PrivateRoute component={FavoritesPage} path={FROM_APP_PATH.FAVORITES} />
          <PrivateRoute component={Step1Page} path={FROM_APP_PATH.STEP1} />
          <PrivateRoute component={Step2Page} path={FROM_APP_PATH.STEP2} />
          <PrivateRoute component={Step3Page} path={FROM_APP_PATH.STEP3} />
          <PrivateRoute component={Step4Page} path={FROM_APP_PATH.STEP4} />
          <PrivateRoute component={Step5Page} path={FROM_APP_PATH.STEP5} />
          <PrivateRoute component={Step6Page} path={FROM_APP_PATH.STEP6} />
          <PrivateRoute component={Step7Page} path={FROM_APP_PATH.STEP7} />
          <PrivateRoute component={Step8Page} path={FROM_APP_PATH.STEP8} />
          <PrivateRoute component={Step9Page} path={FROM_APP_PATH.STEP9} />
          <PrivateRoute component={Step10Page} path={FROM_APP_PATH.STEP10} />
          <PrivateRoute component={Step11Page} path={FROM_APP_PATH.STEP11} />
          <PrivateRoute component={Step12Page} path={FROM_APP_PATH.STEP12} />
          <PrivateRoute component={Step13Page} path={FROM_APP_PATH.STEP13} />
          <PrivateRoute component={RegistPage} path={FROM_APP_PATH.FINISH} />
        </Router>
      </Layout>
    </>
  ) : null
}

export default App
