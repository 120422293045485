import * as React from 'react'
import { FunctionComponent } from 'react'
import { withStyles, Theme, createStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { IReviewProductList } from "./IReviewProductList"
import * as moment from "moment"
import { APPROVAL_TYPES, SELLING_TYPES } from "../../../common/constants"
import { Link } from "@material-ui/core"
import "../../../styles/common.css"
// @ts-ignore
import MailIcon from "../../../images/common/mail.svg"
import { navigate } from "gatsby"
import { RELATIVE_PATH } from "../../../common/routePath"
import { getSellingStatusStyle } from "../../../common/utils"

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }),
)(TableCell)

const getStatusStyle = (status) => {
  if (status == 0) {
    return "midst"
  } else if (status == 1) {
    return "remand"
  } else if (status == 2) {
    return "open"
  } else if (status == 99) {
    return "draft"
  }
  return ""
}

export const ReviewProducts: FunctionComponent<IReviewProductList> = (props) => {
  const { reviewProductList, is_admin } = props

  const rowClick = (event, row) => {
    console.log(event, row)
    navigate(is_admin ? RELATIVE_PATH.APPROVAL : RELATIVE_PATH.PRODUCT, {state:{product_id: row.id}})
  }

  return (
    <TableContainer>
      <Table className="vendin_table" aria-label="vendin_table">
        <TableHead>
          <TableRow>
            <StyledTableCell>No.</StyledTableCell>
            <StyledTableCell align="left">商品名</StyledTableCell>
            <StyledTableCell align="left">申請日</StyledTableCell>
            {is_admin ? (
              <>
                <StyledTableCell align="left">申請者会社名</StyledTableCell>
                {/*<StyledTableCell align="left">申請者</StyledTableCell>*/}
              </>
            ) : undefined }
            <StyledTableCell align="left">審査ステータス</StyledTableCell>
            <StyledTableCell align="left">公開ステータス</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {reviewProductList.map((row) => (
            <TableRow key={row.id} style={{cursor: "pointer"}} onClick={(e) => rowClick(e, row)}>
              <StyledTableCell component="th" scope="row">{row.id}</StyledTableCell>
              <StyledTableCell align="left">
                <div className="img_with_text">
                  {row.images && row.images.length >= 1 ? <img src={row.images[0].image} width={40} /> : undefined}
                  <span className="bold">{row.name}</span>
                </div>
              </StyledTableCell>
              <StyledTableCell align="left">{moment(row.created_at).format("YYYY/MM/DD hh:mm")}</StyledTableCell>
              {is_admin ? (
                <>
                  <StyledTableCell align="left">{row.company.name}</StyledTableCell>
                  {/*<StyledTableCell align="left">{row.user.name}</StyledTableCell>*/}
                </>
              ) : undefined }
              <StyledTableCell align="left" className={"goods_table_stauts_cell"}>
                <div className={`goods_table_status ${getStatusStyle(row.approval_status)}`}>{APPROVAL_TYPES[row.approval_status]}</div>
              </StyledTableCell>
              <StyledTableCell align="left" className={"goods_table_stauts_cell"}>
                <div className={`goods_table_status ${getSellingStatusStyle(row.is_selling)}`}>{SELLING_TYPES[row.is_selling]}</div>
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}