import * as React from "react"
import "../../../../styles/common.css"
import { FunctionComponent, useState } from "react"
import { IHeader } from "./IHeader"
import MessageImg from "../../../../images/common/manage.svg"
import MailImg from "../../../../images/common/mail.svg"
import AccountImg from "../../../../images/common/account.svg"
import Logo from "../../../../images/common/vendin.svg"
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined'
import { Button, CircularProgress } from "@material-ui/core"
import { useDispatch } from "react-redux"
import { LOGOUT_OK } from "../../../../store/auth/actionType"
import { appLogout, deleteStore, getUserType } from "../../../../common/utils"
import { Link, navigate } from "gatsby"
import Backdrop from "@material-ui/core/Backdrop"
import Fade from "@material-ui/core/Fade"
import Modal from "@material-ui/core/Modal"
import { RELATIVE_PATH } from "../../../../common/routePath"
import { ADMIN, BUYER, BUYER_ADMIN, MAKER, MAKER_ADMIN } from "../../../../common/constants"

const HeaderComponent: FunctionComponent<IHeader> = (props) => {
  const { categories, unread_dealing, unread_message, is_admin } = props
  const [ isLogoutModal, setIsLogoutModal ] = useState(false)
  const [ isLoading, setIsLoading ] = useState(false)
  const [ categoryId, setCategoryId ] = useState()
  const [ categoryName, setCategoryName ] = useState()
  const [ keyword, setKeyword ] = useState()
  const dispatch = useDispatch()

  const logout = async () => {
    setIsLoading(true)
    await appLogout().then(responce => {
      navigate("/")
      dispatch({
        type: LOGOUT_OK
      })
      deleteStore()
      localStorage.removeItem("product_forms")
    })
    setIsLoading(false)
  }

  const getTopLink = () => {
    const userType = getUserType()
    let topPath
    switch (parseInt(userType)) {
      case BUYER:
      case BUYER_ADMIN:
        topPath = RELATIVE_PATH.BUYER_TOP
        break
      case MAKER:
      case MAKER_ADMIN:
        topPath = RELATIVE_PATH.MAKER_TOP
        break
      case ADMIN:
        topPath = RELATIVE_PATH.ADMIN_TOP
        break
    }
    return topPath
  }

  const onChangeCategory = (event) => {
    const categorys = event.target.value.split("_")
    setCategoryId(categorys[0])
    setCategoryName(categorys[1])
  }

  const onSearchProducts = () => {
    navigate(RELATIVE_PATH.PRODUCTS, {state:{category_id: categoryId, category_name: categoryName, word: keyword}})
  }

  const onSearchText = (e) => {
    setKeyword(e.target.value)
  }

  return (
    <>
      <div className="head_upper_wrap">
        <div className="head_upper_box">
          <Link to={getTopLink()} className="h_top_link">
            <img src={Logo} alt="VENDIN" width={170} />
          </Link>
          <form action="get" className="h_search_wrap">
            <select name="cate" className="h_s_category" onChange={onChangeCategory}>
              <option value="">全てのカテゴリ</option>
              {categories.map((item, index) => <option key={`${index}_custom_form`}
                                                       value={item["id"] + "_" + item["title"]}>{item["title"]}</option>)}
            </select>
            <input type="text" className="h_s_word" name="s" onBlur={onSearchText} />
            <input type="button" className="h_s_submit" value="検索" onClick={() => onSearchProducts()} />
          </form>
          <div className="h_menu_wrap">
            {is_admin ? (
              <>
                <div style={{width: 130}} />
                <Link to={RELATIVE_PATH.APPROVALS} color="inherit" className="h_menu_item">
                  <AssignmentTurnedInOutlinedIcon style={{ fontSize: "35px", color: "#4B4B4B" }} className="h_menu_img" />
                </Link>
                <div style={{width: 20}} />
              </>
            ) : (
              <>
                <Link to={RELATIVE_PATH.DEALINGS} color="inherit" className="h_menu_item">
                  <span>{unread_dealing}</span><img src={MessageImg} alt="" className="h_menu_img" />
                </Link>
                <Link to={RELATIVE_PATH.NOTIFICATIONS} color="inherit" className="h_menu_item">
                  <span>{unread_message}</span>
                  <img src={MailImg} alt="" className="h_menu_img" />
                </Link>
                <Link to={RELATIVE_PATH.ACCOUNT} color="inherit" className="h_menu_item">
                  <img src={AccountImg} alt="" className="h_menu_img" />
                </Link>
              </>
            )}
            <Button className="logout_button" variant="outlined" onClick={() => setIsLogoutModal(true)}>ログアウト</Button>
          </div>
        </div>
      </div>

      <Modal
        className="confirm_modal"
        open={isLogoutModal}
        onClose={() => setIsLogoutModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isLogoutModal}>
          <div className="modal_body">
            <h2 id="transition-modal-title">ログアウトします。よろしいですか？</h2>
            <div>
              {isLoading ? <CircularProgress size={20} className="spinner" /> : undefined}
            </div>
            <Button className="positive" variant="contained" onClick={logout}>はい</Button>
            <Button className="negation" variant="outlined" onClick={() => setIsLogoutModal(false)}>いいえ</Button>
          </div>
        </Fade>
      </Modal>
    </>
  )
}

export default HeaderComponent