import { FunctionComponent, useEffect, useState } from "react"
import * as React from "react"
import { IPublicInfo } from "./IPublicInfo"
import "../../../../styles/maker/maker_common.css"
import PublicInfoForm from "../../../organisms/PublicInfoForm/PublicInfoForm"
import ProfileSideBar from "../../../organisms/common/profile/ProfileSideBar"
import { isCompanyAdmin } from "../../../../common/utils"

const PublicInfo: FunctionComponent<IPublicInfo> = (props) => {
  const is_company_admin = isCompanyAdmin()

  return (
    <>
      <main className="main_area_two_col">
        <section className="main_area">
          <h2 className="h2_1">公開情報設定<span className="must_title">必須項目</span></h2>
          <PublicInfoForm {...props} />
        </section>

        <ProfileSideBar current={"public_info"} is_company_admin={is_company_admin} />
      </main>
    </>
  )
}

export default PublicInfo
