import * as React from "react"
import { FunctionComponent, useEffect, useState } from "react"
import { RouteProps } from "react-router"
import { account } from "../../../common/data/sampleData"
import Account from "../../templates/ProfileSetting/Account/Account"
import { useCommonData } from "../../../common/customHooks"
import { Loading } from "../../atoms/Loading/Loading"
import { FormProvider, useForm } from "react-hook-form"
import FavoriteList from "../../templates/FavoriteListPage/FavoriteList"
import { get } from "../../../common/utils"
import { FAVORITE, FAVORITE_DELETE, FAVORITES, MESSAGE_NOTICES } from "../../../common/apiConstants"
import Notifications from "../../templates/Message/NotificationList/Notifications"

const Favorites: FunctionComponent<RouteProps> = () => {
  const [page, setPage] = useState(1)
  const [FavoritesData, setFavoritesData] = useState(null)
  const [deletelId, setDeleteId] = useState()

  useEffect(() => {
    (async () => {
      const result = await get(FAVORITES, {}).catch(e => console.log("FAVORITES", e))
      setFavoritesData(result)
    })()
  }, [page, deletelId])

  return FavoritesData ? (
    <FavoriteList favoriteProductList={FavoritesData} setDeleteId={(e) => setDeleteId(e)} />
  ): <Loading />
}


export default Favorites
