import * as React from "react"
import "../../../../../styles/common.css"
import { FunctionComponent, useEffect, useState } from "react"
import closeImage from "../../../../../images/common/batu.svg"
import { IAdminSideBar } from "./IAdminSideBar"
import { Slide } from "@material-ui/core"
import { RELATIVE_PATH } from "../../../../../common/routePath"
import { Link } from "gatsby"

const AdminSideBar: FunctionComponent<IAdminSideBar> = (props) => {
  const { is_open, closeSidebar } = props

  return (
    <Slide direction="right" in={is_open} mountOnEnter unmountOnExit>
      <section className="side_menu">
        <div className="side_menu_wrap">
          <div onClick={closeSidebar}><img src={closeImage} alt="" className="sm_batu" /></div>
          <h2 className="sm_title">システム管理</h2>
          <ul className="sm_list">
            <li className="sm_innter_item"><Link to={RELATIVE_PATH.APPROVALS} onClick={() => closeSidebar(true)}>申請</Link></li>
            <li className="sm_innter_item"><Link to={RELATIVE_PATH.ADMIN_COMPANY} onClick={() => closeSidebar(true)}>会社管理</Link></li>
            <li className="sm_innter_item"><Link to={RELATIVE_PATH.ADMIN_LIST} onClick={() => closeSidebar(true)}>会社管理者</Link></li>
            <li className="sm_innter_item"><Link to={RELATIVE_PATH.NOTIFICATIONS} onClick={() => closeSidebar(true)}>お知らせ</Link></li>
            <li className="sm_innter_item"><Link to={RELATIVE_PATH.MAKER_LIST} onClick={() => closeSidebar(true)}>メーカー</Link></li>
          </ul>
        </div>
      </section>
    </Slide>
  )
}

export default AdminSideBar
