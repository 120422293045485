import * as React from "react"
import { FunctionComponent, useEffect, useState } from "react"
import { RouteProps } from "react-router"
import { makerTop } from "../../../common/data/sampleData"
import "../../../styles/common.css"
import { Loading } from "../../atoms/Loading/Loading"
import AdminTop from "../../templates/AdminTop/AdminTop"
import { get, getUserType } from "../../../common/utils"
import { DEALINGS, PRODUCTS } from "../../../common/apiConstants"
import { NOT_APPROVE } from "../../../common/constants"

// markup
const Top: FunctionComponent<RouteProps> = () => {
  const [ data, setData ] = useState()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    (async () => {
      setLoading(true)
      let paramObj = {params: {}}
      paramObj.params.page = 1
      paramObj.params.with = 0
      paramObj.params.approve = NOT_APPROVE
      await get(PRODUCTS, paramObj).then(response => {
        setData(response.data)
      }).finally(() => {
        setLoading(false)
      })
      // // @ts-ignore
      // setData(data)
    })()
  }, [])

  return <AdminTop data={data} loading={loading} />
}

export default Top
