import "../../../../common/wdyr"
import { FunctionComponent, useEffect, useMemo, useState } from "react"
import * as React from "react"
import { Controller, FormProvider, useForm } from "react-hook-form"
import { ButtonComponent } from "../../../atoms/Button/Button"
import "../../../../styles/maker/maker_common.css"
import { IStep12 } from "./IStep12"
import { TextAreaForm } from "../../../molecules/TextAreaForm/TextAreaForm"
import { addLocalDB, onDraftSubmit } from "../../../../common/utils"
import { RELATIVE_PATH } from "../../../../common/routePath"
import DraftModal from "../../../organisms/common/modal/DraftModal"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { PHONE_REG_EXP, POST_REG_EXP } from "../../../../common/yupConstants"

export const Step12: FunctionComponent<IStep12> = (props) => {
  const [others, setOthers]= useState(props.content)
  const [ isDraft, setIsDraft ] = React.useState(false)
  const [ showDraftModal, setShowDraftModal ] = React.useState(false)
  const [ rtnProductId, setRtnProductId] = useState()
  const [loading, setLoading] = useState(false)
  const methods = useForm({
    mode: "onBlur",
    resolver: yupResolver(
      yup.object().shape({
        sales_point_body: yup.string().max(1000,"1000文字以内"),
      })
    )
  })

  const { handleSubmit, formState: { errors } } = methods
  const onClick = async (data) => {
    const dataObj = JSON.stringify({
      id: props.id ? props.id : "",
      product_id: props.product_id ? props.product_id : "",
      content: data.sales_point_body,
    })
    if (isDraft) {
      setShowDraftModal(true)
      await onDraftSubmit("step12", dataObj).then(res => {
        // @ts-ignore
        setRtnProductId(res ? res["product_id"] : props.product_id)
      }).catch(e => console.log("onDraftSubmit", e)).finally(() => setLoading(false))
    } else {
      await addLocalDB("step12", dataObj, RELATIVE_PATH.STEP13)
    }
  }

  return (
    <section className="main_area_full main_center step step12">
      <h2 className="h2_2">商品新規登録 - セールス情報
        <div className="step">STEP <span className="big">12</span>/ <span>13</span></div>
      </h2>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onClick)}>
          <div className="ir_box">
            <h3 className="rh3">セールスポイント基本<span className="must">必須項目</span></h3>
            <div className="step12_wrap">
              <h4 className="rh4">その他の追加情報や伝えたい内容があればご記入ください</h4>
              <div className="item_full_wrap">
                <TextAreaForm
                  id="sales_point_body"
                  name="sales_point_body"
                  value={others}
                  placeholder="例）XXXXXXXXXXXXXXXXXXX"
                />
              </div>
            </div>
          </div>
          <div className="form_bottom_btn_wrap">
            <div className="fbbw_box">
              <ButtonComponent text={"下書き保存"} type={"submit"} onClick={() => setIsDraft(true)} />
              <ButtonComponent text={"次へ進む"} type={"submit"} onClick={() => setIsDraft(false)} />
            </div>
          </div>
        </form>
      </FormProvider>

      <DraftModal
        productId={rtnProductId}
        is_show={showDraftModal}
        loading={loading}
        setLoading={e => setLoading(e)}
        set_is_show={e => setShowDraftModal(e)}
      />
    </section>
  )
}

export default Step12
