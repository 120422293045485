import { FunctionComponent, useEffect, useMemo, useState } from "react"
import * as React from "react"
import "../../../styles/maker/maker_common.css"
import ProductViewItem from "../../organisms/ProductViewItem/ProductViewItem"

const ProductView: FunctionComponent = (props, location) => {
  return (
    <section className="product_view_wrap">
      {Object.values(props).map((formObj, index) => {
        return formObj.forms.map((value, index) => <ProductViewItem key={`${index}_custom_form`} {...value} />)
      })}
    </section>
  )
}

export default ProductView
