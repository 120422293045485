import * as React from "react"
import { FunctionComponent, useEffect, useState } from "react"
import Header from "../organisms/common/header/Header"
import AdminHeadBottom from "../organisms/common/header/admin/AdminHeadBottom"
import AdminSideBar from "../organisms/common/sidebar/admin/AdminSideBar"
import BuyerHeadBottom from "../organisms/common/header/buyer/BuyerHeadBottom"
import BuyerSideBar from "../organisms/common/sidebar/buyer/BuyerSideBar"
import Footer from "../organisms/common/footer/Footer"
import { ILayout } from "./ILayout"
import { Backdrop, Switch } from "@material-ui/core"
import { useBreadcrumb } from "gatsby-plugin-breadcrumb"
import { getPageName, getUserType, isAdmin, isBrowser } from "../../common/utils"
import { ADMIN, BUYER, BUYER_ADMIN, MAKER, MAKER_ADMIN } from "../../common/constants"
import MakerSideBar from "../organisms/common/sidebar/maker/MakerSideBar"
import 'gatsby-plugin-breadcrumb/gatsby-plugin-breadcrumb.css'
import CustomBreadCrumb from "../molecules/CustomBreadCrumb/CustomBreadCrumb"
import MakerHeadBottom from "../organisms/common/header/maker/MakerHeadBottom"

const Layout: FunctionComponent<ILayout> = (props) => {
  const { header, header_lower, side_bar, children } = props
  const [ isOpen, setIsOpen ] = useState(side_bar["is_open"])
  const crumbs = {
    location: isBrowser ? window.location : null,
    crumbLabel: isBrowser ? getPageName(window.location.pathname) : null,
  }

  useEffect(() => {
    setIsOpen(false)
  }, [])

  const renderComponent = () => {
    let rtnComponent
    switch (parseInt(getUserType())) {
      case BUYER:
      case BUYER_ADMIN:
        rtnComponent = (
          <>
            <header>
              <Header {...header} />
              <BuyerHeadBottom
                news_release={header_lower["news_release"]}
                showSideBar={() => setIsOpen(true)}
              />
            </header>
            <BuyerSideBar
              is_open={isOpen}
              closeSidebar={() => setIsOpen(false)}
              product_count={side_bar["product_count"]}
              categorys={side_bar["categorys"]}
            />
          </>
        )
        break
      case MAKER:
      case MAKER_ADMIN:
        rtnComponent = (
          <>
            <header>
              <Header {...header} />
              <MakerHeadBottom
                news_release={header_lower["news_release"]}
                showSideBar={() => setIsOpen(true)}
              />
            </header>
            <MakerSideBar
              is_open={isOpen}
              closeSidebar={() => setIsOpen(false)}
            />
          </>
        )
        break
      case ADMIN:
        // TODO:管理画面ができるまで仮設置
        rtnComponent = (
          <>
            <header>
              <Header {...header} is_admin={true} />
              <AdminHeadBottom
                showSideBar={() => setIsOpen(true)}
              />
            </header>
            <AdminSideBar
              is_open={isOpen}
              closeSidebar={() => setIsOpen(false)}
            />
          </>
        )
        break
      default:
        break
    }

    return rtnComponent
  }

  return (
    <>
      {JSON.parse(localStorage.getItem("authUser")) ? renderComponent() : undefined}

      <section className="bread_area">
        <CustomBreadCrumb crumbs={crumbs} />
      </section>

      <Backdrop className="overlay" open={isOpen} />
      <div className="main_area_wrap">
        {children}
      </div>

      {JSON.parse(localStorage.getItem("authUser")) ? <Footer /> : undefined}
    </>
  )
}

export default Layout