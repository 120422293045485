import * as React from "react"
import { FunctionComponent, useEffect, useState } from "react"
import { RouteProps } from "react-router"
import { Loading } from "../../atoms/Loading/Loading"
import { get, setHeaderCommonData } from "../../../common/utils"
import { MESSAGE_NOTICE } from "../../../common/apiConstants"
import Notification from "../../templates/Message/NotificationDetail/Notification"

const NotificationPage: FunctionComponent<RouteProps> = (props) => {
  const [id, setId] = useState(props.location.search ? props.location.search.replace("?id","") : "")
  const [notification, setNotification] = useState()

  useEffect(() => {
    (async () => {
      let paramObj = {params: {}}
      paramObj.params.id = id
      const result = await get(MESSAGE_NOTICE, paramObj).catch(e => console.log("e", e))
      await setHeaderCommonData()
      // @ts-ignore
      setNotification(result)
    })()
  }, [id])

  const changePagination = (id) => {
    setId(id)
  }

  return <Notification notification={notification} />
}

export default NotificationPage
