import { FunctionComponent, useEffect, useState } from "react"
import * as React from "react"
import "../../../../styles/message/message.css"
import MessageSideBar from "../../../organisms/common/message/MessageSideBar"
import { NotificationList } from "../../../organisms/Message/NotificationList/NotificationList"
import { INotificationList } from "../../../organisms/Message/NotificationList/INotificationList"
import { isAdmin } from "../../../../common/utils"

const Notifications: FunctionComponent<INotificationList> = (props) => {
  return (
    <main className={isAdmin() ? "main_area_one_col" : "main_area_two_col"}>
      <NotificationList {...props} />

      {isAdmin() ? undefined : <MessageSideBar current={"notification"} /> }
    </main>
  )
}

export default Notifications
