import { FunctionComponent } from "react"
import * as React from "react"
import { IMakersList } from "./IMakersList"
import { MakerList } from "../../organisms/MakerList/MakerList"
import "../../../styles/buyer/maker_list.css"

const MakersList: FunctionComponent<IMakersList> = (props) => {
  return (
    <main className="regist">
      <MakerList {...props} />
    </main>
  )
}

export default MakersList
