import * as React from "react"
import { FunctionComponent } from "react"
import "video-react/dist/video-react.css"
import { Player, BigPlayButton } from "video-react"
import { IMoviePlayer } from "./IMoviePlayer"

export const MoviePlayer: FunctionComponent<IMoviePlayer> = (props) => {
  const { url } = props

  return (
    <Player
      playsInline
      src={url}
    >
      <BigPlayButton position="center" />
    </Player>
  )
}
