import { FunctionComponent, useEffect, useMemo, useState } from "react"
import * as React from "react"
import { IBrandInfo } from "./IBrandInfo"
import "../../../../styles/maker/maker_common.css"
import ProfileSideBar from "../../../organisms/common/profile/ProfileSideBar"
import { isCompanyAdmin, validURL } from "../../../../common/utils"
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { Loading } from "../../../atoms/Loading/Loading"
import { TextAreaForm } from "../../../molecules/TextAreaForm/TextAreaForm"
import { TextForm } from "../../../molecules/TextForm/TextForm"
import * as _ from "lodash"
import { Button } from "@material-ui/core"
import Backdrop from "@material-ui/core/Backdrop"
import Fade from "@material-ui/core/Fade"
import Modal from "@material-ui/core/Modal"

const BrandInfo: FunctionComponent<IBrandInfo> = (props) => {
  const { company_id, brand_info, loading, submitBrandInfo } = props
  const is_company_admin = isCompanyAdmin()
  const [ form, setForm ] = useState(brand_info)
  const [showModal, setShowModal] = useState(false)
  const schema = yup.lazy(() => {
    let yupList = {}
    form.map((value, index) => {
      yupList[`${index}_name`] = yup.string().required("必須")
      yupList[`${index}_url`] = yup.string().url("URLが不正です")
      yupList[`${index}_introduction`] = yup.string().max(1000, "1000文字以内").required("必須")
    })
    const yupObj = yup.object().shape({ ...yupList })
    return yupObj
  })

  const methods = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema)
  })
  const { handleSubmit, formState: { errors } } = methods

  const onSubmit = async (data) => {
    let paramObjArray = {brand_info : []}
    let paramObj = {}
    const dataArray = Object.keys(data)
    dataArray.map((key) => {
      const currentItem = key.split("_")
      currentItem[1] === `name` ? paramObj.brand_name = data[key] : false
      currentItem[1] === `url` ? paramObj.brand_url = data[key] : false
      currentItem[1] === `introduction` ? paramObj.brand_body = data[key] : false

      if (Object.values(paramObj).length === 3) {
        paramObj.id = company_id
        paramObjArray.brand_info.push(paramObj)
        paramObj = {}
      }
    })

    let is_success = await submitBrandInfo(paramObjArray)
    setShowModal(is_success)
  }

  const onAdd = () => {
    let temp = _.cloneDeep(form)
    const lastIndex = temp.length - 1
    const attribute = _.cloneDeep(temp[lastIndex])
    attribute["name"] = ""
    attribute["introduction"] = ""
    temp.push(attribute)
    setForm(temp)
  }

  const onRemove = () => {
    let temp = _.cloneDeep(form)
    if (temp.length > 1) {
      temp.pop()
      setForm(temp)
    }
  }

  // フォームを呼び出し
  const renderForm = useMemo(() => {
    console.log("form", form)
    // @ts-ignore
    return (form.map((item, index) => (
      <div className="others brand_info" key={`${index}_others`} style={{ width: "100%", marginBottom: 30 }}>
        <div className="account_pub_row brand_name" style={{marginBottom: 15}}>
          <TextForm
            id={`${index}_name`}
            name={`${index}_name`}
            title={`ブランド名 ${index + 1}`}
            value={item.brand_name}
          />
        </div>
        <div className="account_pub_row brand_url">
          <TextForm
            id={`${index}_url`}
            name={`${index}_url`}
            title={`ブランドURL`}
            value={item.brand_url}
          />
        </div>
        <div className="account_pub_row account_row_border">
          <TextAreaForm
            id={`${index}_introduction`}
            name={`${index}_introduction`}
            title={`ブランド紹介`}
            value={item.brand_body}
          />
        </div>
      </div>
    )))
  }, [ form ])

  return (
    <main className="main_area_two_col">
      <section className="main_area">
        <h2 className="h2_1">ブランド情報設定<span className="must_title">必須項目</span></h2>
        <div className="account_wrap">
          {loading ? <Loading /> : undefined}
          <FormProvider {...methods}>
            <form method="post" autoComplete="on" onSubmit={handleSubmit(onSubmit)}>
              {renderForm}
              <div className="clone_buttons">
                <Button onClick={e => onAdd()} className="addBtn" style={{ marginRight: "1rem" }}>追加</Button>
                <Button onClick={e => onRemove()} variant="outlined">削除</Button>
              </div>

              <div style={{ display: "flex", justifyContent: "flex-end", margin: "10px 0 0 0" }}>
                <Button variant="contained" size="large" className="submitBtn" type="submit">更新</Button>
              </div>
            </form>
          </FormProvider>
        </div>

        <Modal
          className="confirm_modal"
          open={showModal}
          onClose={() => setShowModal(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={showModal}>
            <div className="modal_body" style={{width: 280}}>
              <h2 className="contact_title" style={{marginBottom: 30}}>ブランド情報を更新しました</h2>
              <Button
                variant="contained"
                size="large"
                className="login_button"
                onClick={() => setShowModal(false)}>
                閉じる
              </Button>
            </div>
          </Fade>
        </Modal>
      </section>

      <ProfileSideBar current={"brand_info"} is_company_admin={is_company_admin} />
    </main>
  )
}

export default BrandInfo
