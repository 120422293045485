import * as React from "react"
import { FunctionComponent, useMemo, useState } from "react"
import { IProductParent } from "./IProductParent"
import { Title } from "../../atoms/Title/Title"
import { Description } from "../../atoms/Description/Description"
import { Caution } from "../../atoms/Caution/Caution"
import { TextForm } from "../../molecules/TextForm/TextForm"
import { TextAreaForm } from "../../molecules/TextAreaForm/TextAreaForm"
import { SelectBoxForm } from "../../molecules/SelectBoxForm/SelectBoxForm"
import { RadioForm } from "../../molecules/RadioForm/RadioForm"
import { CheckBoxForm } from "../../molecules/CheckBoxForm/CheckBoxForm"
import { FileForm } from "../../molecules/FileForm/FileForm"
import { ImageForm } from "../../molecules/ImageForm/ImageForm"
import { IProductChild } from "./IProductChild"
import { Button } from "@material-ui/core"
import * as _ from "lodash"
import { renderTextAtCaution, returnIncrementName } from "../../../common/utils"
import { useFormContext } from "react-hook-form"
import { TableForm } from "../../molecules/TableForm/TableForm"
import { CustomRadioForm } from "../../molecules/CustomRadioForm/CustomRadioForm"
import { HiddenSelectForm } from "../../molecules/HiddenSelectForm/HiddenSelectForm"

export const ProductForm: FunctionComponent<IProductParent> = (props) => {
  const { id, caution, description, addable, title, attributes } = props
  const [ localAttribute, setLocalAttribute ] = useState<IProductChild[]>(attributes)
  const { unregister } = useFormContext()

  const onAdd = () => {
    let temp = []
    // 指定件数分を複製
    if (addable === 1) {
      const attribute = _.cloneDeep(localAttribute[localAttribute.length - 1])
      attribute.name = returnIncrementName(attribute.name)
      attribute.id = returnIncrementName(attribute.id)
      attribute.value = ""
      temp.push(attribute)
    } else {
      for ( let i = addable; i > 0 ; i-- ) {
        const attribute = _.cloneDeep(localAttribute[localAttribute.length - i])
        attribute.name = returnIncrementName(attribute.name)
        attribute.id = returnIncrementName(attribute.id)
        attribute.value = ""
        temp.push(attribute)
      }
    }

    setLocalAttribute(localAttribute.concat(temp))
  }

  const onRemove = () => {
    if (localAttribute.length > addable) {


      const temp = localAttribute.filter((value, index) => {
        const isKeep = index < localAttribute.length - addable
        if (isKeep === false) {
          unregister(`${id}.${value.name}`)
        }
        return index < localAttribute.length - addable
      })
      setLocalAttribute(temp)
    }
  }

  const renderAttributes = useMemo(() => {
    return localAttribute.map((attribute, index) => {
      let retVal = null
      const tempAttribute = _.cloneDeep(attribute)
      tempAttribute.name = `${id}.${attribute.name}`
      switch (Number(attribute.input_type)) {
        case 0:
          // @ts-ignore
          retVal = <TextForm key={`${id}_attribute_${index}`} {...tempAttribute} />
          break
        case 1:
          // @ts-ignore
          retVal = <TextAreaForm key={`${id}_attribute_${index}`} {...tempAttribute} />
          break
        case 2:
          // @ts-ignore
          retVal = <SelectBoxForm key={`${id}_attribute_${index}`} {...tempAttribute} />
          break
        case 3:
          // @ts-ignore
          retVal = <RadioForm key={`${id}_attribute_${index}`} {...tempAttribute} />
          break
        case 4:
          // @ts-ignore
          retVal = <CheckBoxForm key={`${id}_attribute_${index}`} {...tempAttribute} />
          break
        case 5:
          // @ts-ignore
          retVal = <FileForm key={`${id}_attribute_${index}`} {...tempAttribute} />
          break
        case 6:
          // @ts-ignore
          retVal = <ImageForm key={`${id}_attribute_${index}`} {...tempAttribute} />
          break
        case 7:
          // @ts-ignore
          retVal = <TableForm key={`${id}_attribute_${index}`} {...tempAttribute} />
          break
        case 8:
          // @ts-ignore
          retVal = <CustomRadioForm key={`${id}_attribute_${index}`} {...tempAttribute} />
          break
        case 9:
          // @ts-ignore
          retVal = <HiddenSelectForm key={`${id}_attribute_${index}`} {...tempAttribute} />
          break
      }
      return retVal
    })
  }, [localAttribute])

  const renderAddButton = () => {
    let retVal = null
    if (addable) {
      retVal = <div className="clone_buttons">
        <Button onClick={onAdd} className="addBtn" style={{marginRight: "1rem"}}>追加</Button>
        <Button onClick={onRemove} variant="outlined">削除</Button>
      </div>
    }

    return retVal
  }

  return (
    <div className={localAttribute.length > 1 ||　addable ? "ir_item multiple_input" : "ir_item"}>
      <div className="ir_head">{renderTextAtCaution(title)}</div>
      <Description description={description} />
      <div className="ir_content">
        {renderAttributes}
        {renderAddButton()}
      </div>
      <Caution caution={caution} />
    </div>
  )
}
