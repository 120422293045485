import "../../../../common/wdyr";
import { FunctionComponent, useEffect, useState } from "react"
import * as React from "react"
import { IStep1 } from "../../AddProduct/step1/IStep1"
import "../../../../styles/maker/maker_common.css"
import { get } from "../../../../common/utils"
import { PRODUCT_REGISTER_FORM } from "../../../../common/apiConstants"
import { Loading } from "../../../atoms/Loading/Loading"


export const Step1View: FunctionComponent<IStep1> = (props) => {
  const {categories, name} = props

  return (
    <section className="product_view_wrap">
      <div className="product_view_row">
        <div className="ir_head">カテゴリー</div>
        <div className="view_content">
          <div className="view_item">
            <div className="view_value">{name}</div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default Step1View