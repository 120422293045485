import { FunctionComponent, useEffect, useState } from "react"
import * as React from "react"
import "../../../../styles/message/message.css"
import MessageSideBar from "../../../organisms/common/message/MessageSideBar"
import { INotification } from "./INotification"
import { NotificationDetail } from "../../../organisms/Message/NotificationDetail/NotificationDetail"
import { isAdmin } from "../../../../common/utils"

const Notification: FunctionComponent<INotification> = (props) => {
  const { notification } = props

  return (
    <main className={isAdmin() ? "main_area_one_col" : "main_area_two_col"}>
      <NotificationDetail {...notification} />

      {isAdmin() ? undefined : <MessageSideBar current={"notification"} /> }
    </main>
  )
}

export default Notification
