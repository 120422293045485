import * as React from "react"
import { FunctionComponent, useEffect, useMemo, useState } from "react"
import { Title } from "../../atoms/Title/Title"
import { Description } from "../../atoms/Description/Description"
import { Caution } from "../../atoms/Caution/Caution"
import { IHiddenSelectForm } from "./IHiddenSelectForm"
import { useFormContext } from "react-hook-form"
import { Button, FormControl, Grid, MenuItem, Radio, RadioGroup, Select } from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { isBrowser } from "../../../common/utils"
import { radioForm } from "../RadioForm/RadioForm.stories"

export const HiddenSelectForm: FunctionComponent<IHiddenSelectForm> = (props) => {
  const { register } = useFormContext()
  const { caution, description, id, title, name, value, rules, style, selects, selectsValue } = props

  const changedShowStatus = (e) => {
    if (isBrowser) {
      selects.map((select) => {
        const inputObj = document.getElementById(select)
        inputObj.style.display = e.target.value ? "flex" : "none"

        if (inputObj.getElementsByTagName("input").length >= 1) {
          // input属性
          const inputType = inputObj.getElementsByTagName("input")[0].type
          console.log("inputType", inputType)
          // タイプ判定
          switch (inputType) {
            case "text":
            case "file":
            case "image":
              inputObj.getElementsByTagName("input")[0].value = ""
              break
            case "radio":
              const radioGroup = inputObj.getElementsByTagName("input")
              Object.values(radioGroup).map((radio) => {
                radio.checked = false
              })
              break
            case "checkbox":
              const checkbox = inputObj.getElementsByTagName("input")
              Object.values(checkbox).map((checkItem) => {
                checkItem.checked ? checkItem.click() : false
              })
              break
            default:
              break
          }
        } else if (inputObj.getElementsByTagName("textarea")) {
          inputObj.getElementsByTagName("textarea")[0].value = ""
        }
      })
    }
  }

  return (
    <div style={style} className={props["is_column"] ? "ir_sub_item column_item" : "ir_sub_item"}>
      {title ?
        <div className="input_title_wrap">
          <Title title={title} />
        </div>
        : null}
      <div>
        <FormControl variant="outlined">
          <Select
            displayEmpty
            labelId=""
            defaultValue={1}
            onChange={changedShowStatus}
            IconComponent={props => (
              <ExpandMoreIcon {...props} className={`material-icons ${props.className}`}>
              </ExpandMoreIcon>
            )}
          >
            <MenuItem value={0}>無し</MenuItem>
            <MenuItem value={1}>有り</MenuItem>
          </Select>
        </FormControl>
      </div>
      {description ? <Description description={description} /> : null}
      {caution ? <div style={{width: "100%"}}><Caution caution={caution} /></div> : null}
    </div>
  )
}
