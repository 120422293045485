import * as React from "react"
import { FunctionComponent, useEffect, useMemo, useState } from "react"
import { IDealingDetail } from "./IDealingDetail"
import * as moment from "moment"
import { Button } from "@material-ui/core"
import Backdrop from "@material-ui/core/Backdrop"
import Fade from "@material-ui/core/Fade"
import Modal from "@material-ui/core/Modal"
import { getDealingStatus, getLocalUser, post } from "../../../../common/utils"
import { DEALING_ADOPT, DEALING_CLOSE, DEALING_POST_MESSAGE } from "../../../../common/apiConstants"
import { Loading } from "../../../atoms/Loading/Loading"
import { DEALING_TYPE_ADOPT, DEALING_TYPE_CLOSE } from "../../../../common/constants"
import { Link } from "gatsby"
import { RELATIVE_PATH } from "../../../../common/routePath"
import { MessageList } from 'react-chat-elements'
import 'react-chat-elements/dist/main.css'
import { useForm, FormProvider } from "react-hook-form"

export const DealingDetail: FunctionComponent<IDealingDetail> = (props) => {
  const { data } = props
  const [ dealing, setDealing ] = useState([])
  const [ dealingModal, setDealingModal ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const [ isClose, setIsClose ] = useState(false)
  const [ dealingStatus, setDealingStatus ] = useState(0)
  const loginUser = getLocalUser()
  let retVal = undefined
  const adoptMessage = "「商談成立」処理を行いました。\n商談内容に問題なければ「商談成立」ボタンをクリックして下さい。"
  const closeMessage = "「商談終了」処理を行いました。\n商談内容に問題なければ「商談終了」ボタンをクリックして下さい。"

  const methods = useForm({
    mode: "onBlur"
  })
  const { handleSubmit, register } = methods

  useEffect(() => {
    setDealingStatus(getDealingStatus(data.buyer_adopted_at, data.buyer_closed_at, data.maker_adopted_at, data.maker_closed_at))
    createTextObj(data.messages)
  }, [])

  const isMe = (userId) => {
    let chatPosition = "left"
    if (userId === loginUser["userId"]) {
      chatPosition = "right"
    }
    return chatPosition
  }

  const getMessageDate = (momentDate) => {
    let rtnDate
    const createdAtDate = momentDate.format("YYYY-MM-DD")
    const now = moment().format("YYYY-MM-DD")
    if (createdAtDate === now) {
      rtnDate = momentDate.format("h:mm")
    } else {
      rtnDate = momentDate.format("M/D h:mm")
    }
    return rtnDate
  }

  const createTextObj = (messageListData) => {
    let messageList = []
    messageListData.map((message, index) => {
      messageList.push({
        position: isMe(message.user.id),
        type: 'text',
        date: moment(message.created_at).toDate(),
        dateString: getMessageDate(moment(message.created_at)),
        title: message.user.name,
        text: message.message,
      })
    })
    setDealing(messageList)
  }

  const onSubmitMessage = async (chatData) => {
    setLoading(true)
    let paramObj = {}
    paramObj.id = data.id
    paramObj.message = chatData.chatArea
    await post(DEALING_POST_MESSAGE, paramObj).then(res => {
      methods.setValue("chatArea", "")
      createTextObj(res.messages)
    }).catch(e => {
      alert(e.message)
    }).finally(() => setLoading(false))
  }

  const submitMessage = async (text, type) => {
    setLoading(true)
    let paramObj = {}
    paramObj.id = data.id
    paramObj.message = text
    await post(DEALING_POST_MESSAGE, paramObj).then(res => {
      methods.setValue("chatArea", "")
      createTextObj(res.messages)
    }).catch(e => {
      alert(e.message)
    }).finally(() => setLoading(false))

    if (type === DEALING_TYPE_ADOPT) {
      // 商談成立
      await post(DEALING_ADOPT, paramObj).then(() => {
        setDealingStatus(9)
      }).catch(e => {
        alert(e.message)
      })
    } else if (type === DEALING_TYPE_CLOSE) {
      // 商談終了
      await post(DEALING_CLOSE, paramObj).then(() => {
        setDealingStatus(1)
      }).catch(e => {
        alert(e.message)
      })
    }
  }

  const renderCommentsBlock = useMemo(() => {
    return (
      <>
        <MessageList
          toBottomHeight={'100%'}
          lockable={true}
          dataSource={dealing} />
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmitMessage)}>
            <textarea
              id="chatArea"
              name="chatArea"
              className="chatArea"
              placeholder="Type here..."
              disabled={dealingStatus !== 0}
              rows={3}
              {...register("chatArea", {})}
            />
            <Button
              className="submitBtn submitMessage"
              type="submit"
              disabled={dealingStatus !== 0}
            >
              送信
            </Button>
          </form>
        </FormProvider>
      </>
    )
  }, [dealing, dealingStatus])

  const returnFinalDate = () => {
    let finalDate
    const buyerAdopt = moment(data.buyer_adopted_at)
    const buyerClose = moment(data.buyer_closed_at)
    const makerAdopt = moment(data.maker_adopted_at)
    const makerClose = moment(data.maker_closed_at)

    if (dealingStatus === 9) {
      finalDate = (buyerAdopt.isAfter(makerAdopt) ? buyerAdopt : makerAdopt).format("YYYY/MM/DD HH:mm")
    } else if (dealingStatus === 1) {
      finalDate = (buyerClose.isAfter(makerClose) ? buyerClose : makerClose).format("YYYY/MM/DD HH:mm")
    } else {
      finalDate = "----/--/--　-- : --"
    }

    return finalDate
  }

  retVal = (
    <>
      <section className="main_area dealing_detail">
        <div className="mp_product_title">
          <div className="list_title_box">
            <h2 className="top_title_h2">商談詳細</h2>
          </div>
        </div>

        {loading ? <Loading /> : undefined}
        <div>
          <div className="dealing_head">
            <div className="dealing_head_content">
              <div className="dealing_detail_wrap">
                <div><span>商談開始日：</span>{moment(data.created_at).format("YYYY/MM/DD HH:mm")}</div>
                <div>
                  <span>{dealingStatus === 9 ? "商談成立日：" : "商談終了日："}</span>
                  {returnFinalDate()}
                </div>
              </div>
              <div className="dealing_detail_wrap">
                <div>商品：</div>
                <div className="img_with_text">
                  <Link to={RELATIVE_PATH.PRODUCT} state={{ product_id: data.product.id }} className="product_item_link"
                        style={{ display: "contents", color: "#000" }}>
                    {data.product.images ? <img src={data.product.images[0].image} width={40} /> : undefined}
                    <span className="bold">{data.product.name}</span>
                  </Link>
                </div>
              </div>
            </div>

            <div className="dealing_button">
              <Button className="completion" variant="contained" disabled={dealingStatus !== 0 ? true : false}
                      onClick={() => {
                        setIsClose(false)
                        setDealingModal(true)
                      }}>商談成立</Button>
              <Button className="un_completion" variant="outlined" disabled={dealingStatus !== 0 ? true : false}
                      onClick={() => {
                        setIsClose(true)
                        setDealingModal(true)
                      }}>商談終了</Button>
            </div>
          </div>

          <div className="comment_body">
            {renderCommentsBlock}
          </div>
        </div>
      </section>

      <Modal
        className="confirm_modal account_modal"
        open={dealingModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={dealingModal}>
          <div className="modal_body">
            <h2 id="transition-modal-title">確認</h2>
            <div
              className="dealing_confirm">商談{isClose ? "終了" : "成立"}します。よろしいですか？<br /><span>※両社が商談{isClose ? "終了" : "成立"}ボタンを押下することで{isClose ? "終了" : "成立"}となります。</span>
            </div>
            <Button className="positive" variant="contained" onClick={() => {
              const message = isClose ? closeMessage : adoptMessage
              const type = isClose ? DEALING_TYPE_CLOSE : DEALING_TYPE_ADOPT
              submitMessage(message, type)
              setDealingModal(false)
            }}>はい</Button>
            <Button className="negation" variant="outlined" onClick={() => setDealingModal(false)}>いいえ</Button>
          </div>
        </Fade>
      </Modal>
    </>
  )


  return retVal
}