import * as React from "react"
import { FunctionComponent, useEffect, useState } from "react"
import { RouteProps } from "react-router"
import { Loading } from "../../atoms/Loading/Loading"
import { get, isAdmin } from "../../../common/utils"
import { MESSAGE_NOTICE } from "../../../common/apiConstants"
import Notification from "../../templates/Message/NotificationDetail/Notification"
import { NotificationDetailEdit } from "../../organisms/Message/NotificationDetail/NotificationDetailEdit"

const NotificationEditPage: FunctionComponent<RouteProps> = (props) => {
  const [id, setId] = useState(props.location.search ? props.location.search.replace("?id","") : "")
  const [notification, setNotification] = useState()

  return (
    <main className="main_area_one_col">
      <NotificationDetailEdit />
    </main>
  )
}

export default NotificationEditPage
