import * as React from "react"
import "../../../../../styles/common.css"
import {FunctionComponent, useEffect, useState} from "react";
import closeImage from "../../../../../images/common/batu.svg"
import {IMakerSideBar} from "./IMakerSideBar";
import {Slide} from "@material-ui/core";
import { RELATIVE_PATH } from "../../../../../common/routePath"
import { Link, navigate } from "gatsby"
import { deleteStore } from "../../../../../common/utils"
import Contact from "../contact/Contact"

const MakerSideBar:FunctionComponent<IMakerSideBar> = (props) => {
    const {is_open , closeSidebar} = props

    const onMoveAddProduct = () => {
        // 新規作成のためlocalの商品一時情報は削除
        deleteStore()
        closeSidebar(true)
        navigate(RELATIVE_PATH.STEP1)
    }

    return (
        <Slide direction="right" in={is_open} mountOnEnter unmountOnExit>
            <section className="side_menu">
                <div className="side_menu_wrap">
                    <div onClick={closeSidebar}><img src={closeImage} alt="" className="sm_batu" /></div>
                    <h2 className="sm_title">全ての商品</h2>
                    <ul className="sm_list">
                        <li className="sm_item" onClick={() => onMoveAddProduct()} style={{cursor: "pointer"}}><span className="sm_item_text">商品登録</span></li>
                        <li className="sm_innter_item"><Link to={RELATIVE_PATH.CREATED_PRODUCTS} onClick={() => closeSidebar(true)}>登録済商品一覧</Link></li>
                    </ul>
                    <h2 className="sm_title">メッセージ管理</h2>
                    <ul className="sm_list">
                        <li className="sm_innter_item"><Link to={RELATIVE_PATH.NOTIFICATIONS} onClick={() => closeSidebar(true)}>お知らせ</Link></li>
                        <li className="sm_innter_item"><Link to={RELATIVE_PATH.DEALINGS} onClick={() => closeSidebar(true)}>商談</Link></li>
                        <li className="sm_innter_item"><Link to={RELATIVE_PATH.APPROVALS} onClick={() => closeSidebar(true)}>申請</Link></li>
                        <li className="sm_innter_item"><Link to={RELATIVE_PATH.QUESTIONS} onClick={() => closeSidebar(true)}>QA</Link></li>
                        <li className="sm_innter_item"><Contact /></li>
                    </ul>
                </div>
            </section>
        </Slide>
    )
}

export default MakerSideBar
