import "../../../../common/wdyr";
import { FunctionComponent} from "react"
import * as React from "react"
import { IStep6 } from "../../AddProduct/step6/IStep6"
import { Image } from "../../../atoms/Image/Image"
import "../../../../styles/maker/maker_common.css"
import { OuterLink } from "../../../atoms/OuterLink/OuterLink"
import { convertDataURItoBlobUrl } from "../../../../common/utils"
import CancelIcon from "@material-ui/icons/Cancel"



const Step6View : FunctionComponent<IStep6> = (props) => {
  const { sales_title, sales_documents } = props
  console.log("sales_documents", sales_documents)

  return (
    <section className="product_view_wrap">
      <div className="product_view_row">
      <div className="ir_head">一言でまとめた商品<br />概要を入力してださ<br />い</div>
        <div className="view_content">
        <div className="view_item">
          <div className="view_value">{sales_title}</div>
        </div>
        </div>
      </div>

      <div className="product_view_row">
        <div className="ir_head">添付資料</div>
        <div className="view_content">
          <div className="view_item">
          {sales_documents.length >= 1 ? (
            sales_documents.map((item, index) => {
            let docUrl = item.document.startsWith("data:") ? convertDataURItoBlobUrl(item.document) : item.document
            console.log("docUrl", docUrl)
            return (
              <div className="view_item" key={`${index}_view_img`}>
                <div className="view_value">
                  <p>
                    {docUrl ? (
                      <a id={`${index}_file`} href={docUrl} target="_blank" >ファイルを開く</a>
                    ) : "添付なし"}
                  </p>
                </div>
              </div>
            )
          })) : (
            "添付なし"
          )}
          </div>
        </div>
      </div>


    </section>
  )
}

export default Step6View


