import * as React from "react"
import { FunctionComponent } from "react"
import { IMakerDetailSideBar } from "./IMakerDetailSideBar"
import { RELATIVE_PATH } from "../../../../common/routePath"
import "../../../../styles/maker_view.css"
import { Link } from "gatsby"

const MakerDetailSideBar: FunctionComponent<IMakerDetailSideBar> = (props) => {
  const { current, company, categories, product_count } = props

  return (
    <section className="side_area">
      <div className="side_logo"><img src={company.logo} style={{ width: "100%" }} /></div>
      <ul className="side_bar">
        <li className={current === "profile_top" ? "side_item active" : "side_item"}>
          <Link to={RELATIVE_PATH.MAKER + "/?id=" + company.id} className="side_link">プロフィールトップ</Link>
        </li>
        <li className={current === "categories" ? "side_item active" : "side_item"}>
          掲載商品一覧
          {categories ? (
            <ul>
              <li><Link to={RELATIVE_PATH.MAKER_PRODUCT} state={{company_id: company.id}} className="side_link">{`すべての商品（${product_count}）`}</Link></li>
              {Object.keys(categories).map((key, index) => (
                <li className="category_item" key={`${index}_category`}>
                  <Link
                    className="side_link"
                    to={RELATIVE_PATH.MAKER_PRODUCT}
                    state={
                      { category_id: categories[key],
                        category_name: key,
                        company_id: company.id,
                      }
                    }
                  >{key}</Link>
                </li>
              ))}
            </ul>
          ) : undefined}
        </li>
      </ul>
    </section>
  )
}

export default MakerDetailSideBar
