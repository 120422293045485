import * as React from "react"
import { FunctionComponent, useMemo, useState } from "react"
import { Title } from "../../atoms/Title/Title"
import { Description } from "../../atoms/Description/Description"
import { Caution } from "../../atoms/Caution/Caution"
import { IMovieForm } from "./IMovieForm"
import { useFormContext } from "react-hook-form"
import imageCompression from 'browser-image-compression'
import CancelIcon from '@material-ui/icons/Cancel'
import { Loading } from "../../atoms/Loading/Loading"
import { MoviePlayer } from "../MoviePlayer/MoviePlayer"
import { convertDataURItoBlobUrl } from "../../../common/utils"

export const MovieForm: FunctionComponent<IMovieForm> = (props) => {
  const { register } = useFormContext()
  const { caution, description, id, title, name, value, placeholder, rules, style, setForm } = props
  const [ imageValue, setImageValue ] = useState(value)
  const [ isFileTypeError, setIsFileTypeError ] = useState(false)
  const [loading, setLoading] = useState(false)
  const compressOption = { maxSizeMB: 2 }

  const imageField = register(name)

  const handleFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files === null || event.target.files.length === 0) {
      return
    }
    const files = event.target.files
    const fileSize = files[0].size / 1024 / 1024

    // fileが存在しない、image以外の場合はエラー
    if (files.length == 0 || ![ "video/mp4" ].includes(files[0]["type"]) || fileSize > 10) {
      setIsFileTypeError(true)
      return false
    } else {
      setIsFileTypeError(false)
      let reader = new FileReader()
      reader.readAsDataURL(files[0])
      reader.onloadend = (e) => {
        let base64data = reader.result
        setImageValue(base64data)
        console.log("setForm", setForm, base64data)
        if (setForm) {
          setForm(base64data)
        }
      }
    }
  }

  const renderRequired = () => {
    let retVal = null
    // @ts-ignore
    if (rules && "required" in rules && rules.required) {
      retVal = <span>*</span>
    }
    return retVal
  }

  const onDeleteImage = () => {
    setImageValue(null)
    if (setForm) {
      setForm(null, null)
    }
  }

  const urlStartWith = (url) => {
    let dataUrl
    if (url instanceof Array) {
      dataUrl = url[0] ? url[0] : ""
    } else if (url instanceof Object) {
      console.log("dataUrl Object", )
      dataUrl = url["0"].length >= 1 ? url["0"] : ""
    } else {
      dataUrl = url
    }

    if (dataUrl && dataUrl.startsWith("data:")) {
      return (
        <div>
          <a id={`${id}_file`} href={convertDataURItoBlobUrl(dataUrl)} target="_blank">ファイルを開く</a>
          <CancelIcon onClick={e => onDeleteImage(e)} />
        </div>
      )
    } else if (dataUrl && dataUrl.startsWith("http")) {
      return (
        <div>
          <a id={`${id}_file`} href={dataUrl} target="_blank">ファイルを開く</a>
          <CancelIcon onClick={e => onDeleteImage(e)} />
        </div>
      )
    } else {
      return undefined
    }
  }

  const renderDom = useMemo(() => {
    let retVal = (
      <div className="thumbnail_wrap">
        <div className="label_wrap" style={{marginRight: 20}}>
          <label htmlFor={`${id}_file_upload`} className="file_label">
            動画アップロード
            <input
              id={`${id}_file_upload`}
              name={name}
              type={"file"}
              {...imageField}
              onChange={ async (e) => {
                setLoading(true)
                imageField.onChange(e)
                await handleFile(e)
                setLoading(false)
              }}
              accept={"video/mp4"}
              className="file_upload"
            />
          </label>
          <Caution caution={"※形式はMPEG/MP4のみ\n※容量は最大10MBまで"} />
        </div>
        {imageValue ? urlStartWith(imageValue) : undefined}
      </div>
    )

    return retVal
  }, [imageValue])

  return (
    <div style={style} className={props["is_column"] ? "ir_sub_item column_item" : "ir_sub_item"}>
      {loading ? <Loading /> : undefined}
      {title ? (
        <div className="input_title_wrap clearfix">
          <Title title={title} />
          {renderRequired()}
        </div>
      ) : null}
      {description ? <Description description={description} /> : null}
      {renderDom}
      {isFileTypeError ? <div className="file_error">アップロードは10MB以下のMPEG/MP4のみ</div> : undefined}
    </div>
  )
}
