import * as React from "react"
import { FunctionComponent, useEffect, useState } from "react"
import {IProductList} from "./IProductList"
import {FormControl, InputLabel, MenuItem, NativeSelect, Select} from "@material-ui/core";
import {ProductItem} from "../../molecules/ProductItem/ProductItem";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Pagination} from "@material-ui/lab";
import { Loading } from "../../atoms/Loading/Loading"
import { get, matchFavorite } from "../../../common/utils"
import { SEARCH_USER } from "../../../common/apiConstants"
import { CustomPagination } from "../../molecules/Pagination/Pagination"

export const ProductList: FunctionComponent<IProductList> = (props) => {
    const { total, data, loading, categoryName, sort, setSort, current_page, last_page, setPage } = props
    let retVal = undefined
    const [favoriteList, setFavoriteList] = useState([])

    useEffect(() => {
        (async () => {
            const dataList = await matchFavorite()
            setFavoriteList(dataList)
        })()
    }, [])

    const handleChange = (event) => {
        setSort(event.target.value);
    }

    retVal = (
        <section className="main_area product_list">
            {loading ? <Loading /> : undefined}
            <div className="mp_product_title">
                <div className="list_title_box">
                    <h2 className="top_title_h2">{categoryName ? categoryName : "すべて"}</h2>
                    <div className="search_list_num"><span>{total ? total : 0}</span>件見つかりました</div>
                </div>
                {setSort ? (
                  <FormControl variant="outlined" className="mp_product_sort">
                      <Select
                        displayEmpty
                        labelId=""
                        id=""
                        value={sort}
                        onChange={handleChange}
                        IconComponent={props => (
                          <ExpandMoreIcon {...props} className={`material-icons ${props.className}`}>
                          </ExpandMoreIcon>
                        )}
                      >
                          <MenuItem value="">
                              <em>商品の並び替え</em>
                          </MenuItem>
                          <MenuItem value={5}>安い順</MenuItem>
                          <MenuItem value={4}>高い順</MenuItem>
                      </Select>
                  </FormControl>
                ) : undefined}
            </div>

            <div className="mp_product_area">
                <div className="mp_product_box">
                    {data ? (
                      data.map((item) => <ProductItem key={`${item.id}_custom_form`} favoriteList={favoriteList} {...item} />)
                    ) : (
                      <div>
                          <p>該当する商品は見つかりませんでした</p>
                      </div>
                    )}
                </div>
            </div>

        </section>
    )


    return retVal
}