import * as React from "react"
import { FunctionComponent, useEffect, useState } from "react"
import { RouteProps } from "react-router"
import { Loading } from "../../atoms/Loading/Loading"
import Step13 from "../../templates/AddProduct/step13/Step13"
import Dexie from "dexie"
import Regist from "../../templates/AddProduct/regist/Regist"

const RegistPage: FunctionComponent<RouteProps> = (props) => {
  const [ data, setData ] = useState()
  const [ id, setId ] = useState(props.id)

  const emptyData = {
    sales_extra_point: ""
  }

  useEffect(() => {

  }, [])

  return (
    <main className="regist">
      <Regist />
    </main>
  )
}

export default RegistPage
