import * as React from "react"
import { FunctionComponent, useEffect, useState } from "react"
import { RouteProps } from "react-router"
import { buyerTop } from "../../../common/data/sampleData"
import BuyerTop from "../../templates/BuyerTop/BuyerTop"
import { useCommonData } from "../../../common/customHooks"
import { Loading } from "../../atoms/Loading/Loading"
import { get, getUserType } from "../../../common/utils"
import { HOME } from "../../../common/apiConstants"

const Top: FunctionComponent<RouteProps> = () => {
  const [commonData] = useCommonData()
  const [jsonData, setJsonData] = useState()

  useEffect( () => {
    (async () => {
      await get(HOME, {}).then(response => {
        setJsonData(response)
      }).catch(e => {
        console.log("e", e)
      })
    })()
  }, [])

  return jsonData ? (
    <BuyerTop
      messageList={jsonData.latest_messages}
      dealingList={jsonData.processing_dealings}
      recommendList={jsonData.recommend_products}
      newProductList={jsonData.new_products}
      adoptProductList={jsonData.latest_adopted_dealings}
    />
  ) : <Loading />
}

export default Top
