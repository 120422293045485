import * as React from "react"
import {IButton} from "./IButton"
import { FunctionComponent } from "react"
import { Button } from "@material-ui/core"

export const ButtonComponent: FunctionComponent<IButton> = props => {
    const { onClick, text, type } = props
    return (
        <Button onClick={onClick} type={type || "button"}>{text}</Button>
    )
}
