import * as React from "react"
import { IMovie } from "./IMovie"
import { useState } from "react"
import PlayArrowIcon from "@material-ui/icons/PlayArrow"
import Modal from "@material-ui/core/Modal"
import Backdrop from "@material-ui/core/Backdrop"
import Fade from "@material-ui/core/Fade"
import { MoviePlayer } from "../../molecules/MoviePlayer/MoviePlayer"

export const Movie = React.memo((props: IMovie) => {
  const [ movieModal, setMovieModal ] = useState(false)

  return (
    <>
      <div className="movie_poster_img" onClick={() => setMovieModal(true)}><PlayArrowIcon className="playIcon" /></div>

      <Modal
        className="confirm_modal"
        open={movieModal}
        onClose={() => setMovieModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={movieModal}>
          <div className="modal_body">
            <div className="movie_wrap">
              <MoviePlayer url={props.url} />
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  )
})
