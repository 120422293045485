import "../../../../common/wdyr";
import { FunctionComponent, useEffect, useMemo, useState } from "react"
import * as React from "react"
import { yupResolver } from "@hookform/resolvers/yup"
import { Controller, FormProvider, useForm } from "react-hook-form"
import { ButtonComponent } from "../../../atoms/Button/Button"
import "../../../../styles/maker/maker_common.css"
import { IStep6 } from "./IStep6"
import { ProductForm } from "../../../organisms/ProductForm/ProductForm"
import * as yup from "yup"
import { Button } from "@material-ui/core"
import { TextForm } from "../../../molecules/TextForm/TextForm"
import { ImageForm } from "../../../molecules/ImageForm/ImageForm"
import * as _ from "lodash"
import { FileForm } from "../../../molecules/FileForm/FileForm"
import { navigate } from "gatsby"
import { RELATIVE_PATH } from "../../../../common/routePath"
import { addLocalDB, onDraftSubmit } from "../../../../common/utils"
import DraftModal from "../../../organisms/common/modal/DraftModal"

export const Step6: FunctionComponent<IStep6> = (props) => {
  const { sales_title, sales_documents } = props
  const [imageForm, setImageForm] = useState(sales_documents)
  const [ isDraft, setIsDraft ] = React.useState(false)
  const [ showDraftModal, setShowDraftModal ] = React.useState(false)
  const [ rtnProductId, setRtnProductId] = useState()
  const [loading, setLoading] = useState(false)

  const methods = useForm({
    mode: "onBlur",
    resolver: yupResolver(
      yup.object().shape({
        sales_title: yup.string().max(255,"255文字以内"),
      })
    )
  })

  const { handleSubmit } = methods
  const onClick = async (data) => {
    const dataObj = JSON.stringify({
      id: props.id ? props.id : "",
      product_id: props.product_id ? props.product_id : "",
      sales_title: data.sales_title,
      sales_documents: imageForm,
    })
    if (isDraft) {
      setShowDraftModal(true)
      await onDraftSubmit("step6", dataObj).then(res => {
        // @ts-ignore
        setRtnProductId(res ? res["product_id"] : props.product_id)
      }).catch(e => console.log("onDraftSubmit", e)).finally(() => setLoading(false))
    } else {
      await addLocalDB("step6", dataObj, RELATIVE_PATH.STEP7)
    }
  }

  const onAdd = () => {
    let temp = _.cloneDeep(imageForm)
    const lastIndex = temp.length - 1
    const attribute = _.cloneDeep(temp[lastIndex])
    attribute["document"] = ""
    temp.push(attribute)
    setImageForm(temp)
  }

  const onRemove = () => {
    let temp = _.cloneDeep(imageForm)
    if (temp.length > 1) {
      temp.pop()
      setImageForm(temp)
    }
  }

  const setFormFile = (index, url) => {
    let temp = _.cloneDeep(imageForm)
    const attribute = _.cloneDeep(temp[index])
    attribute["document"] = url
    temp[index] = attribute
    setImageForm(temp)
  }


  // プロダクトフォームを呼び出し
// フォームを呼び出し
  const renderForm = useMemo(() => {
    // @ts-ignore
    return (
      <div className="item_full_wrap">
        <TextForm
          id={"sales_title"}
          name={"sales_title"}
          value={sales_title}
          placeholder={"XXX文字以内で入力"}
          style={{ width: "100%" }}
        />
        <div className="step_caution">例文：独自技術を採用した最新の美白クリームが登場<br />独自技術を採用した最新の美白クリームが登場</div>
        <div className="upload_caution"><span>ご確認</span>記入内容に対する根拠資料を下記より添付してください。詳細はこちら</div>
        <div className="effect_child">
          {imageForm.map((document, i) => {
            return (
              <div key={`${i}_document_form`} className="effect_child_row">
                <FileForm
                  id={`document_${i}`}
                  title={"添付資料"}
                  name={`document_${i}`}
                  value={document["document"]}
                  caution={"※形式はPDFのみ\r\n※容量は最大2MBまで"}
                  setForm={(index, url) => setFormFile(index, url)}
                />
              </div>
            )
          })}
          <div className="clone_buttons">
            <Button onClick={() => onAdd()} className="addBtn" style={{ marginRight: "1rem" }}>追加</Button>
            <Button onClick={() => onRemove()} variant="outlined">削除</Button>
          </div>
        </div>
      </div>
    )
  }, [imageForm])

  return (
    <section className="main_area_full main_center step step6">
      <h2 className="h2_2">商品新規登録 - セールス情報
        <div className="step">STEP <span className="big">6</span>/ <span>13</span></div>
      </h2>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onClick)}>
          <div className="ir_box">
            <h3 className="rh3">セールスポイント商品概要<span className="must">必須項目</span></h3>
            <div className="step7_wrap">
              <div className="step_description">1言でまとめた商品概要を入力してください</div>

              {renderForm}

            </div>
          </div>

          <div className="form_bottom_btn_wrap">
            <div className="fbbw_box">
              <ButtonComponent text={"下書き保存"} type={"submit"} onClick={() => setIsDraft(true)} />
              <ButtonComponent text={"次へ進む"} type={"submit"} onClick={() => setIsDraft(false)} />
            </div>
          </div>
        </form>
      </FormProvider>

      <DraftModal
        productId={rtnProductId}
        is_show={showDraftModal}
        loading={loading}
        setLoading={e => setLoading(e)}
        set_is_show={e => setShowDraftModal(e)}
      />
    </section>
  )
}

export default Step6
