import * as React from "react"
import "../../../styles/common.css"
import { FunctionComponent } from "react"
import { CircularProgress } from "@material-ui/core"
import { isBrowser } from "../../../common/utils"

export const Loading:FunctionComponent = (props) => {
  let height = "100%"
  if (isBrowser) {
    height = document.documentElement.scrollHeight
  }
  return (
    <div className="loading" style={{height: height}}>
      <CircularProgress className="spinner" />
    </div>
  )
}
