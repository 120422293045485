import { FunctionComponent, useEffect, useState } from "react"
import * as React from "react"
import "../../../styles/common.css"
import { IAccountForm } from "./IAccountForm"
import { Button, MenuItem, Select, TextField } from "@material-ui/core"
import { FormProvider, Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { useAccount } from "../../../common/customHooks"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import {
  appLogout,
  deleteStore,
  get,
  getLocalUser,
  getUserType,
  isAdmin,
  isCompanyAdmin,
  post
} from "../../../common/utils"
import { ADMIN, BUYER, BUYER_ADMIN, MAKER, MAKER_ADMIN } from "../../../common/constants"
import { USER_CREATE, USER_UPDATE, WITHDRAW } from "../../../common/apiConstants"
import { Loading } from "../../atoms/Loading/Loading"
import * as moment from "moment-timezone"
import Modal from "@material-ui/core/Modal"
import Backdrop from "@material-ui/core/Backdrop"
import Fade from "@material-ui/core/Fade"
import { navigate } from "gatsby"
import { LOGOUT_OK } from "../../../store/auth/actionType"
import { useDispatch } from "react-redux"

export const AccountForm: FunctionComponent<IAccountForm> = (props) => {
  const {
    id,
    name,
    password,
    email,
    type,
    company_name,
    from,
    to,
    is_edit,
    setShowModal,
    getUserList,
    company_type,
    company_id,
    remarks
  } = props
  const [ loading, setLoading ] = useState(false)
  const [ withDrawModal, setWithDrawModal ] = useState(false)
  const dispatch = useDispatch()

  const methods = useForm({
    mode: "onBlur",
    resolver: yupResolver(
      yup.object().shape({
        company_name: yup.string().required("必須"),
        name: yup.string().min(1).max(30).required("必須"),
        email: yup.string().email().required("必須"),
        password: yup.string().min(8,"8文字以上の半角英数字").test('match', '確認パスワードと一致しません', function (password) {
          return is_edit ? true : password === this.parent.confirm_password
        }),
        new_password: yup.string().min(8,"8文字以上の半角英数字").test('match', '確認パスワードと一致しません', function (new_password) {
          return is_edit ? new_password === this.parent.confirm_password : true
        }),
        confirm_password: yup.mixed().test('match', 'パスワードと一致しません', function (confirm_password) {
          return is_edit ? confirm_password === this.parent.new_password : confirm_password === this.parent.password
        }),
        from: yup.mixed().test('match', '正しい日付を入力してください', function (from) {
          if (isAdmin() && !is_edit) {
            return from ? moment(from).isBefore(moment(this.parent.to)) : false
          } else {
            return true
          }
        }),
        to: yup.mixed().test('match', '正しい日付を入力してください', function (to) {
          if (isAdmin() && !is_edit) {
            return to ? moment(to).isAfter(moment(this.parent.from)) : false
          } else {
            return true
          }
        }),
      })
    )
  })
  const { handleSubmit, control, formState: { errors } } = methods

  const onSubmitAccount = async (data) => {
    setLoading(true)
    const localUser = getLocalUser()
    const isMe = parseInt(localUser["userId"]) === id
    let paramObj = {}
    id ? paramObj.user_id = id : false
    paramObj.company_name = data.company_name
    if (isAdmin() && !is_edit) {
      paramObj.from = moment(new Date(data.from)).add(1, "days").toDate()
      paramObj.to = moment(new Date(data.to)).add(1, "days").toDate()
      getUserTypeFrame() === "10" ? paramObj.remarks = data.remarks : false
    }
    paramObj.name = data.name
    paramObj.email = data.email
    company_id ? paramObj.company_id = company_id : false
    data.password ? paramObj.password = data.password : false
    if (is_edit) {
      paramObj.password = data.new_password
    }
    data.confirm_password ? paramObj.password_confirmation = data.confirm_password : false

    let accountType
    switch (parseInt(localUser["userType"])) {
      case BUYER_ADMIN:
        if (is_edit && isMe) {
          // 自分自身の更新
          accountType = parseInt(localUser["userType"])
        } else {
          accountType = BUYER
        }
        break
      case MAKER_ADMIN:
        if (is_edit && isMe) {
          // 自分自身の更新
          accountType = parseInt(localUser["userType"])
        } else {
          accountType = MAKER
        }
        break
      case BUYER:
        accountType = BUYER
        break
      case MAKER:
        accountType = MAKER
        break
      case ADMIN:
        if (parseInt(type) === BUYER || parseInt(type) === BUYER_ADMIN) {
          accountType = BUYER_ADMIN
        } else if (parseInt(type) === MAKER || parseInt(type) === MAKER_ADMIN) {
          accountType = MAKER_ADMIN
        } else {
          if (company_type && parseInt(company_type) === 1) {
            accountType = MAKER_ADMIN
          } else {
            accountType = BUYER_ADMIN
          }
        }

        break
      default:
        accountType = 0
        break
    }
    paramObj.type = accountType

    // アカウント登録API実行
    await post(is_edit ? USER_UPDATE : USER_CREATE, paramObj).then(response => {
      isMe ? editLocalUser(paramObj) : undefined
      if (setShowModal) {
        setShowModal(false)
      }
      getUserList ? getUserList() : false
    }).catch(e => {
      console.log("USER_UPDATE", e)
      const errorsList = Object.values(e.errors)
      alert(errorsList.join())
    }).finally(() => {
      setLoading(false)
    })
  }

  const editLocalUser = (paramObj) => {
    let localUser = JSON.parse(localStorage.getItem("authUser"))
    localUser.userName = paramObj.name
    localUser.userEmail = paramObj.email
    localStorage.setItem("authUser", JSON.stringify({ ...localUser }))
  }

  const getUserTypeFrame = () => {
    const userType = getUserType()
    let accountType
    switch (parseInt(userType)) {
      case BUYER:
      case BUYER_ADMIN:
        accountType = BUYER
        break
      case MAKER:
      case MAKER_ADMIN:
        accountType = MAKER
        break
      case ADMIN:
        console.log("type", type)
        if (parseInt(type) === BUYER || parseInt(type) === BUYER_ADMIN) {
          accountType = BUYER
        } else if (parseInt(type) === MAKER || parseInt(type) === MAKER_ADMIN) {
          accountType = MAKER
        } else {
          console.log("company_type", company_type)
          if (company_type && parseInt(company_type) === 1) {
            accountType = MAKER
          } else {
            accountType = BUYER
          }
        }
        break
      case undefined:
        accountType = ""
        break
      default:
        accountType = type
        break
    }

    return accountType ? accountType.toString() : ""
  }

  const onWithdraw = async () => {
    setLoading(true)
    await get(WITHDRAW, {}).then(res => {
      logout()
    }).catch(e => {
      console.log("WITHDRAW ERROR", e.message)
    }).finally(() => {
      setWithDrawModal(false)
      setLoading(false)
    })
  }

  const logout = async () => {
    setLoading(true)
    await appLogout().then(responce => {
      navigate("/")
      deleteStore()
      localStorage.removeItem("product_forms")
    })
    setLoading(false)
  }

  return (
    <div className="account_wrap">
      {loading ? <Loading /> : undefined}
      <FormProvider {...methods}>
        <form method="post" autoComplete="on" onSubmit={handleSubmit(onSubmitAccount)}>
          <div className="account_row">
            <div className="account_title">会社名</div>
            <div className="account_input">
              <Controller
                name="company_name"
                control={control}
                defaultValue={company_name}
                render={({ field }) => <TextField
                  id="company_name"
                  variant="outlined"
                  color="secondary"
                  size="small"
                  // disabled={is_edit ? true : false}
                  error={errors.company_name?.message}
                  helperText={errors.company_name?.message}
                  fullWidth
                  disabled={!isAdmin()}
                  {...field} />}
              />
            </div>
          </div>

          {isAdmin() && !is_edit ? (
            <>
              <div className="account_row">
                <div className="account_title">契約期間</div>
                <div className="account_input subscriptionDays">
                  <Controller
                    name="from"
                    control={control}
                    defaultValue={from}
                    render={({ field }) => <TextField
                      id="from"
                      variant="outlined"
                      color="secondary"
                      size="small"
                      style={{fontSize: 16}}
                      type="date"
                      error={errors.from?.message}
                      helperText={errors.from?.message}
                      {...field} />}
                  />
                  <span>～</span>
                  <Controller
                    name="to"
                    control={control}
                    defaultValue={to}
                    render={({ field }) => <TextField
                      id="to"
                      variant="outlined"
                      color="secondary"
                      size="small"
                      type="date"
                      error={errors.to?.message}
                      helperText={errors.to?.message}
                      {...field} />}
                  />
                </div>
              </div>
              {getUserTypeFrame() === "10" ? (
                <div className="account_row">
                  <div className="account_title">備考</div>
                  <div className="account_input">
                    <Controller
                      name="remarks"
                      control={control}
                      defaultValue={remarks}
                      render={({ field }) => <textarea
                        id="remarks"
                        {...field} />}
                    />
                  </div>
                </div>
              ) : undefined}
            </>
          ) : undefined}

          <div className="account_row">
            <div className="account_title">氏名</div>
            <div className="account_input">
              <Controller
                name="name"
                control={control}
                defaultValue={name}
                render={({ field }) => <TextField
                  id="name"
                  variant="outlined"
                  color="secondary"
                  size="small"
                  error={errors.name?.message}
                  helperText={errors.name?.message}
                  fullWidth
                  {...field} />}
              />
            </div>
          </div>

          <div className="account_row">
            <div className="account_title">メールアドレス</div>
            <div className="account_input">
              <Controller
                name="email"
                control={control}
                defaultValue={email}
                render={({ field }) => <TextField
                  id="email"
                  variant="outlined"
                  color="secondary"
                  size="small"
                  error={errors.email?.message}
                  helperText={errors.email?.message}
                  fullWidth
                  {...field} />}
              />
            </div>
          </div>

          {isAdmin ? (
            <div className="account_row">
              <div className="account_title">ユーザー種別</div>
              <div className="account_input">
                <Controller
                  name="user_type"
                  control={control}
                  render={({ field }) => <select
                    id="user_type"
                    className="basic_select"
                    defaultValue={getUserTypeFrame()}
                    disabled={true}
                  >
                    <option value="0">バイヤー</option>
                    <option value="10">メーカー</option>
                    {/*<option value="99">管理者</option>*/}
                  </select>}
                />
              </div>
            </div>
          ) : undefined}

          {is_edit ? (
            <>
              <div className="account_row">
                <div className="account_title">新しいパスワード</div>
                <div className="account_input">
                  <Controller
                    name="new_password"
                    control={control}
                    defaultValue=""
                    render={({ field }) => <TextField
                      id="new_password"
                      variant="outlined"
                      type="password"
                      color="secondary"
                      size="small"
                      error={errors.new_password?.message}
                      helperText={errors.new_password?.message}
                      fullWidth
                      {...field} />}
                  />
                </div>
              </div>
            </>
          ) : (
            <div className="account_row">
              <div className="account_title">パスワード</div>
              <div className="account_input">
                <Controller
                  name="password"
                  control={control}
                  defaultValue={password}
                  render={({ field }) => <TextField
                    id="password"
                    variant="outlined"
                    type="password"
                    color="secondary"
                    size="small"
                    error={errors.password?.message}
                    helperText={errors.password?.message}
                    fullWidth
                    {...field} />}
                />
              </div>
            </div>
          )}
          <div className="account_row">
            <div className="account_title">パスワード確認</div>
            <div className="account_input">
              <Controller
                name="confirm_password"
                control={control}
                defaultValue=""
                render={({ field }) => <TextField
                  id="confirm_password"
                  variant="outlined"
                  type="password"
                  color="secondary"
                  size="small"
                  error={errors.confirm_password?.message}
                  helperText={errors.confirm_password?.message}
                  fullWidth
                  {...field} />}
              />
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button variant="contained" size="large" className="submitBtn" type="submit">保存</Button>
          </div>
        </form>
      </FormProvider>

      {/*{isCompanyAdmin() ? (*/}
      {/*  <div style={{ display: "flex", justifyContent: "center", paddingTop: 100 }}>*/}
      {/*    <Button*/}
      {/*      variant="contained"*/}
      {/*      size="large"*/}
      {/*      className="product_delete_btn"*/}
      {/*      onClick={() => setWithDrawModal(true)}*/}
      {/*    >VENDINから退会する</Button>*/}
      {/*  </div>*/}
      {/*) : undefined}*/}

      <Modal
        className="confirm_modal"
        open={withDrawModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={withDrawModal}>
          <div className="modal_body">
            <h2 id="transition-modal-title">退会処理を行います。よろしいですか？</h2>
            <div>※退会した会社の商品、商談内容等は削除されます。</div>
            <div style={{ paddingTop: 15 }}>
              <Button className="positive" variant="contained" onClick={() => onWithdraw()}>はい</Button>
              <Button className="positive" variant="contained" onClick={() => setWithDrawModal(false)}>いいえ</Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  )
}

export default AccountForm
