import * as React from "react"
import { RouteProps } from "react-router"
import Login from "./pages/login"
import { FunctionComponent } from "react"
import { useLocalAuthFile } from "../common/authentication/auth"

export const PrivateRoute: FunctionComponent<RouteProps> = ({component: Component, ...rest}) => {
  let retVal
  let is_login = useLocalAuthFile()

  if (is_login.isLogin) {
    // @ts-ignore
    retVal = <Component {...rest} />
  } else {
    retVal = <Login />
  }

  return retVal
}
