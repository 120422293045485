import * as React from "react"
import { FunctionComponent, useEffect, useRef, useState } from "react"
import { IProductList } from "../ProductList/IProductList"
import { ProductItem } from "../../molecules/ProductItem/ProductItem"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/core'
import "swiper/swiper-bundle.css"
import "swiper/components/navigation/navigation.min.css"
import "swiper/components/pagination/pagination.min.css"
import { SwiperOptions } from "swiper/types/swiper-options"
import SwiperClass from "swiper/types/swiper-class"
import { matchFavorite } from "../../../common/utils"

// install Swiper modules
SwiperCore.use([ Navigation, Pagination, Mousewheel, Keyboard ])

export const RecommendList: FunctionComponent<IProductList> = (props) => {
  const { productList } = props
  const [ swipeController, setSwipeController ] = useState<SwiperClass>(null)
  const [favoriteList, setFavoriteList] = useState([])

  useEffect(() => {
    (async () => {
      const dataList = await matchFavorite()
      setFavoriteList(dataList)
    })()
  }, [])

  return (
    <div className="recommend_area">
      <Swiper
        onSwiper={setSwipeController}
        cssMode={true}
        keyboard={true}
        loop={false}
        slidesPerView={5}
        centeredSlides={true}
        spaceBetween={0}
        pagination={true}
        className="recommend_wrapper"
      >
        {productList.map((item, index) => (
          <SwiperSlide key={`${index}_recommend`}>
            <ProductItem {...item} favoriteList={favoriteList} />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="swiper-button-prev" onClick={() => swipeController.slidePrev()} />
      <div className="swiper-button-next" onClick={() => swipeController.slideNext()} />
    </div>
  )
}
