import * as React from "react"
import { FunctionComponent, useEffect, useState } from "react"
import { RouteProps } from "react-router"
import { Loading } from "../../atoms/Loading/Loading"
import { get } from "../../../common/utils"
import { SUB_CATEGORIES } from "../../../common/apiConstants"
import Step5 from "../../templates/AddProduct/step5/Step5"
import Dexie from "dexie"

const Step5Page: FunctionComponent<RouteProps> = (props) => {
  const [ data, setData ] = useState()
  const [ id, setId ] = useState(props.id)

  const emptyJson = {
    after_service: "0",
    contact_company: "",
    division_name: "",
    contact_person: "",
    reception_time_from: "",
    reception_time_to: "",
    contact_company_tel: "",
    contact_company_fax: "",
    contact_company_address: "",
    contact_company_mail_address: "",
    return_name: "",
    return_post: "",
    return_prefectures: "東京都",
    return_address: "",
    return_tel:　"",
    return_fax: "",
    pl_insurance_number: ""
  }

  useEffect(() => {
    (async () => {
      const db = new Dexie("vendinDB")
      db.version(1).stores({ productForm: 'formName', })
      db.open()
      db.productForm.get("step5").then(res => {
        if (res) {
          // @ts-ignore
          setData(JSON.parse(res.form))
        } else {
          // @ts-ignore
          setData(emptyJson)
        }
      }).catch(e => {
        console.log("getLocalDB e", e)
      })
    })()
  }, [])

  let render = data ? (
      <main className="regist">
        <Step5 {...data} />
      </main>
    ) : <Loading />

  return render
}

export default Step5Page
