import { FunctionComponent } from "react"
import * as React from "react"
import "../../../styles/maker/maker_common.css"
import { FavoriteProducts } from "../../molecules/FavoriteProductList/FavoriteProducts"
import { IFavoriteProductList } from "../../molecules/FavoriteProductList/IFavoriteProductList"

export const FavoriteProductForm: FunctionComponent<IFavoriteProductList> = (props) => {
  const { favoriteProductList, setDeleteId } = props
  return (
    <section className="main_center">
      <div className="Favorite_top_title" style={{marginBottom: 30}}>
        <h2 className="top_title_h2">お気に入り一覧</h2>
      </div>
      <div>
        {/*<span className="top_title_box">{favoriteProductList.length}件見つかりました</span>*/}
      </div>
      <div className="top_title_box">
        {favoriteProductList.length >= 1 ? (
          <FavoriteProducts favoriteProductList={favoriteProductList} setDeleteId={setDeleteId} />
        ) : <div>現在お気に入りしている商品はありません</div>}
      </div>
    </section>
  )
}
export default FavoriteProductForm