import * as React from "react"
import { FunctionComponent, useEffect, useState } from "react"
import { RouteProps } from "react-router"
import { Loading } from "../../atoms/Loading/Loading"
import Step11 from "../../templates/AddProduct/step11/Step11"
import Dexie from "dexie"

const Step11Page: FunctionComponent<RouteProps> = (props) => {
  const [ data, setData ] = useState()
  const [ id, setId ] = useState(props.id)

  const emptyJson = {
    is_tv_achievement: "0",
    is_approval: "0",
    talent_name: "",
    talent_image: "",
    talent_movie_url: "",
    others: [
      {
        others_comment: "",
        others_movie: ""
      },
    ],
  }

  useEffect(() => {
    (async () => {
      const db = new Dexie("vendinDB")
      db.version(1).stores({ productForm: 'formName', })
      db.open()
      db.productForm.get("step11").then(res => {
        if (res) {
          const tempForm = JSON.parse(res.form)
          console.log("tempForm", tempForm)
          if (tempForm.others.length >= 1) {
            // @ts-ignore
            setData(JSON.parse(res.form))
          } else {
            emptyJson["id"] = tempForm["id"]
            emptyJson["product_id"] = tempForm["product_id"]
            // @ts-ignore
            setData(emptyJson)
          }
        } else {
          // @ts-ignore
          setData(emptyJson)
        }
      }).catch(e => {
        console.log("getLocalDB e", e)
      })
    })()
  }, [])

  return data ? (
    <main className="regist">
      <Step11 {...data} />
    </main>
  ) : <Loading />
}

export default Step11Page
