import "../../../../common/wdyr";
import { FunctionComponent } from "react"
import * as React from "react"
import { IStep8 } from "../../AddProduct/step8/IStep8"
import "../../../../styles/maker/maker_common.css"
import { OuterLink } from "../../../atoms/OuterLink/OuterLink"
import { convertDataURItoBlobUrl } from "../../../../common/utils"


export const Step8View: FunctionComponent<IStep8> = (props) => {
  const {achievements} = props

  return (
    <section className="product_view_wrap">
      {achievements.map((row, index) => (
        <div className="product_view_row" key={index} >
          <div className="ir_head" > {`実績${index + 1}`} </div>
          <div className="view_content">
            <div className="view_item">
              <div className="view_value" >{row.achievement_title} </div>
            </div>
            {row.achievement_documents.map((item, i) =>　{
              let docUrl = item.achievement_document.startsWith("data:") ? convertDataURItoBlobUrl(item.achievement_document) : item.achievement_document
              return (
                <div className="view_value" key={i}>
                  <div className="view_title">実績資料</div>
                  <div className="view_item">
                    <div className="view_value" >
                      <p>
                        {docUrl ? (
                          <a id={`${index}_file`} href={docUrl} target="_blank" >ファイルを開く</a>
                        ) : "添付なし"}
                      </p>
                    </div>
                  </div>
                  <div className="view_title">実績URL</div>
                  <div className="view_item">
                    <div className="view_value"><OuterLink url={item.achievement_url} title={item.achievement_url} /></div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      ))}
    </section>
  )
}
export default Step8View