import * as React from "react"
import { FunctionComponent, useEffect, useState } from "react"
import { RouteProps } from "react-router"
import { useCommonData } from "../../../common/customHooks"
import { Loading } from "../../atoms/Loading/Loading"
import Question from "../../templates/Message/Question/Question"
import { questions } from "../../../common/data/sampleData"
import { del, get, getUserType, isBrowser, post } from "../../../common/utils"
import { PRODUCT_ANSWER, PRODUCT_QA_DELETE, PRODUCT_QA_DETAIL } from "../../../common/apiConstants"

const QuestionPage: FunctionComponent<RouteProps> = (props) => {
  const { id } = props.location.state
  const [ commonData ] = useCommonData()
  const [ jsonData, setJsonData ] = useState()

  useEffect(() => {
    (async () => {
      let paramObj = { params: {} }
      paramObj.params.product_id = id
      await get(PRODUCT_QA_DETAIL, paramObj).then(response => {
        setJsonData(response)
      }).catch(e => {
        console.log("e", e)
      })
    })()
  }, [])

  const onAnswer = async (data) => {
    console.log("onAnswer", data)
    let rtnSuccess = false
    await post(PRODUCT_ANSWER, data).then(res => {
      console.log("PRODUCT_ANSWER success", res)
      rtnSuccess = true
    }).catch(e => console.log("PRODUCT_ANSWER error", e))
    return rtnSuccess
  }

  const onDelete = async (data) => {
    console.log("onDelete", data)
    let paramObj = { params: {} }
    paramObj.params.question_id = data.question_id
    await del(PRODUCT_QA_DELETE, paramObj).then(res => {
      console.log("PRODUCT_QA_DELETE success", res)
      isBrowser ? window.location.reload() : false
    }).catch(e => console.log("PRODUCT_QA_DELETE error", e))
  }

  return jsonData ? (
    <Question
      {...jsonData}
      onAnswer={onAnswer}
      onDelete={onDelete}
    />
  ) : <Loading />
}

export default QuestionPage
