import * as React from "react"
import { FunctionComponent, useEffect, useState } from "react"
import { RouteProps } from "react-router"
import MakerTop from "../../templates/MakerTop/MakerTop"
import { makerTop } from "../../../common/data/sampleData"
import { useCommonData } from "../../../common/customHooks"
import "../../../styles/common.css"
import { Loading } from "../../atoms/Loading/Loading"
import { get } from "../../../common/utils"
import { HOME } from "../../../common/apiConstants"

// markup
const Top: FunctionComponent<RouteProps> = () => {
  const [ commonData ] = useCommonData()
  const [jsonData, setJsonData] = useState()

  useEffect( () => {
    (async () => {
      await get(HOME, {}).then(response => {
        setJsonData(response)
      }).catch(e => {
        console.log("e", e)
      })
    })()
  }, [])

  return jsonData ? (
    <MakerTop
      messageList={jsonData.latest_messages}
      dealingList={jsonData.processing_dealings}
      reviewProductList={jsonData.products_latest_approval_status}
    />
  ) : <Loading />
}

export default Top
