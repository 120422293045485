import { FunctionComponent, useEffect, useState } from "react"
import * as React from "react"
import "../../../styles/common.css"
import "../../../styles/product_view.css"
import { IProductParent } from "../ProductForm/IProductParent"
import { changeMultipleLines, convertDataURItoBlobUrl, renderTextAtCaution } from "../../../common/utils"
import { Image } from "../../atoms/Image/Image"
import { Table, TableContainer, TextField } from "@material-ui/core"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableBody from "@material-ui/core/TableBody"
import { withStyles } from "@material-ui/core/styles"
import TableCell from "@material-ui/core/TableCell"
import h27_wash_190 from "../../../images/jis/h27_wash_190.jpg"
import h27_wash_170 from "../../../images/jis/h27_wash_170.jpg"
import h27_wash_160 from "../../../images/jis/h27_wash_160.jpg"
import h27_wash_161 from "../../../images/jis/h27_wash_161.jpg"
import h27_wash_151 from "../../../images/jis/h27_wash_151.jpg"
import h27_wash_150 from "../../../images/jis/h27_wash_150.jpg"
import h27_wash_140 from "../../../images/jis/h27_wash_140.jpg"
import h27_wash_141 from "../../../images/jis/h27_wash_141.jpg"
import h27_wash_142 from "../../../images/jis/h27_wash_142.jpg"
import h27_wash_130 from "../../../images/jis/h27_wash_130.jpg"
import h27_wash_131 from "../../../images/jis/h27_wash_131.jpg"
import h27_wash_132 from "../../../images/jis/h27_wash_132.jpg"
import h27_wash_110 from "../../../images/jis/h27_wash_110.jpg"
import h27_wash_100 from "../../../images/jis/h27_wash_100.jpg"
import h27_wash_220 from "../../../images/jis/h27_wash_220.jpg"
import h27_wash_210 from "../../../images/jis/h27_wash_210.jpg"
import h27_wash_200 from "../../../images/jis/h27_wash_200.jpg"
import h27_wash_320 from "../../../images/jis/h27_wash_320.jpg"
import h27_wash_310 from "../../../images/jis/h27_wash_310.jpg"
import h27_wash_300 from "../../../images/jis/h27_wash_300.jpg"
import h27_wash_440 from "../../../images/jis/h27_wash_440.jpg"
import h27_wash_430 from "../../../images/jis/h27_wash_430.jpg"
import h27_wash_420 from "../../../images/jis/h27_wash_420.jpg"
import h27_wash_410 from "../../../images/jis/h27_wash_410.jpg"
import h27_wash_445 from "../../../images/jis/h27_wash_445.jpg"
import h27_wash_435 from "../../../images/jis/h27_wash_435.jpg"
import h27_wash_425 from "../../../images/jis/h27_wash_425.jpg"
import h27_wash_415 from "../../../images/jis/h27_wash_415.jpg"
import h27_wash_530 from "../../../images/jis/h27_wash_530.jpg"
import h27_wash_520 from "../../../images/jis/h27_wash_520.jpg"
import h27_wash_510 from "../../../images/jis/h27_wash_510.jpg"
import h27_wash_500 from "../../../images/jis/h27_wash_500.jpg"
import h27_wash_620 from "../../../images/jis/h27_wash_620.jpg"
import h27_wash_621 from "../../../images/jis/h27_wash_621.jpg"
import h27_wash_610 from "../../../images/jis/h27_wash_610.jpg"
import h27_wash_611 from "../../../images/jis/h27_wash_611.jpg"
import h27_wash_600 from "../../../images/jis/h27_wash_600.jpg"
import h27_wash_710 from "../../../images/jis/h27_wash_710.jpg"
import h27_wash_711 from "../../../images/jis/h27_wash_711.jpg"
import h27_wash_712 from "../../../images/jis/h27_wash_712.jpg"
import h27_wash_700 from "../../../images/jis/h27_wash_700.jpg"

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell)

export const ProductViewItem: FunctionComponent<IProductParent> = (props) => {
  const { id, title, attributes } = props

  const renderValue = (input_type, value) => {
    let retVal = null
    switch (Number(input_type)) {
      case 0:
      case 1:
      case 2:
      case 3:
      case 4:
        let tmpTxt = changeMultipleLines(value)
        retVal = value ? <div className="view_value" children={tmpTxt} /> : "未記入"
        break
      case 5:
        let docUrl = value && value.length >= 1 && value[0] ? value : false
        if (docUrl && docUrl.startsWith("data:")) {
          docUrl = convertDataURItoBlobUrl(docUrl)
        }
        retVal = <div className="view_value_file">{docUrl ? <a href={docUrl} target="_blank">ファイルリンク</a> : "添付なし"}</div>
        break
      case 6:
        retVal = (
          <div className="view_item has_img">
            {value ? <div className="view_value_img"><Image url={value} /></div> : <div className="view_value">なし</div>}
          </div>
        )
        break
      case 7:
        const dataObj = value instanceof Object ? value : JSON.parse(value)
        const headKeys = Object.keys(dataObj[0])
        retVal = (
          <div className="view_item">
            <div className="view_value">
              <TableContainer style={{ width: "95%" }}>
                <Table className="vendin_table size_table" aria-label="vendin_table">
                  <TableHead>
                    <TableRow>
                      {headKeys.map((key, index) => (
                        <StyledTableCell align="left" key={`head_${index}`}>{key}</StyledTableCell>))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dataObj.map((row, i) => (
                      <TableRow className="clickEnabled" key={i}>
                        {Object.values(row).map((item, j) => {
                          return (
                            <StyledTableCell align="left" key={`text_${i}_${j}`}>{item}</StyledTableCell>
                          )
                        })}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        )
        break
      case 8:
        const radioData = value instanceof Object ? value : JSON.parse(value)
        const radioKeys = Object.keys(radioData)
        retVal = (
          <div className="view_item customRadio">
            {radioKeys.map((key, index) => {
              switch (key) {
                case "washing":
                  let washImg
                  let washTitle
                  switch (radioData[key]) {
                    case "0":
                      washTitle = "190"
                      washImg = h27_wash_190
                      break
                    case "1":
                      washTitle = "170"
                      washImg = h27_wash_170
                      break
                    case "2":
                      washTitle = "160"
                      washImg = h27_wash_160
                      break
                    case "3":
                      washTitle = "161"
                      washImg = h27_wash_161
                      break
                    case "4":
                      washTitle = "151"
                      washImg = h27_wash_151
                      break
                    case "5":
                      washTitle = "150"
                      washImg = h27_wash_150
                      break
                    case "6":
                      washTitle = "140"
                      washImg = h27_wash_140
                      break
                    case "7":
                      washTitle = "141"
                      washImg = h27_wash_141
                      break
                    case "8":
                      washTitle = "142"
                      washImg = h27_wash_142
                      break
                    case "9":
                      washTitle = "130"
                      washImg = h27_wash_130
                      break
                    case "10":
                      washTitle = "131"
                      washImg = h27_wash_131
                      break
                    case "11":
                      washTitle = "132"
                      washImg = h27_wash_132
                      break
                    case "12":
                      washTitle = "110"
                      washImg = h27_wash_110
                      break
                    case "13":
                      washTitle = "100"
                      washImg = h27_wash_100
                      break
                  }
                  return (
                    <div className="view_value" key={`wash_${index}`}>
                      {washTitle ? (
                        <div className="custom_radio_item">
                          <p>{washTitle}</p>
                          <div><img src={washImg} width={45} /></div>
                        </div>
                      ) : <p style={{marginRight: 10}}>選択無し</p>}
                      <label>洗濯処理</label>
                    </div>
                  )
                case "bleaching":
                  let bleaching
                  let bleachingTitle
                  switch (radioData[key]) {
                    case "0":
                      bleachingTitle = "220"
                      bleaching = h27_wash_220
                      break
                    case "1":
                      bleachingTitle = "210"
                      bleaching = h27_wash_210
                      break
                    case "2":
                      bleachingTitle = "200"
                      bleaching = h27_wash_200
                      break
                  }
                  return (
                    <div className="view_value" key={`wash_${index}`}>
                      {bleachingTitle ? (
                        <div className="custom_radio_item">
                          <p>{bleachingTitle}</p>
                          <div><img src={bleaching} width={45} /></div>
                        </div>
                      ) : <p style={{marginRight: 10}}>選択無し</p>}
                      <label>漂白処理</label>
                    </div>
                  )
                case "dry":
                  let dry
                  let dryTitle
                  switch (radioData[key]) {
                    case "0":
                      dryTitle = "320"
                      dry = h27_wash_320
                      break
                    case "1":
                      dryTitle = "310"
                      dry = h27_wash_310
                      break
                    case "2":
                      dryTitle = "300"
                      dry = h27_wash_300
                      break
                  }
                  return (
                    <div className="view_value" key={`wash_${index}`}>
                      {dryTitle ? (
                        <div className="custom_radio_item">
                          <p>{dryTitle}</p>
                          <div><img src={dry} width={45} /></div>
                        </div>
                      ) : <p style={{marginRight: 10}}>選択無し</p>}
                      <label>タンブル乾燥処理</label>
                    </div>
                  )
                case "naturalDrying":
                  let naturalDrying
                  let naturalDryingTitle
                  switch (radioData[key]) {
                    case "0":
                      naturalDryingTitle = "440"
                      naturalDrying = h27_wash_440
                      break
                    case "1":
                      naturalDryingTitle = "430"
                      naturalDrying = h27_wash_430
                      break
                    case "2":
                      naturalDryingTitle = "420"
                      naturalDrying = h27_wash_420
                      break
                    case "3":
                      naturalDryingTitle = "410"
                      naturalDrying = h27_wash_410
                      break
                    case "4":
                      naturalDryingTitle = "445"
                      naturalDrying = h27_wash_445
                      break
                    case "5":
                      naturalDryingTitle = "435"
                      naturalDrying = h27_wash_435
                      break
                    case "6":
                      naturalDryingTitle = "425"
                      naturalDrying = h27_wash_425
                      break
                    case "7":
                      naturalDryingTitle = "415"
                      naturalDrying = h27_wash_415
                      break
                  }
                  return (
                    <div className="view_value" key={`wash_${index}`}>
                      {naturalDryingTitle ? (
                        <div className="custom_radio_item">
                          <p>{naturalDryingTitle}</p>
                          <div><img src={naturalDrying} width={45} /></div>
                        </div>
                      ) : <p style={{marginRight: 10}}>選択無し</p>}
                      <label>自然乾燥処理</label>
                    </div>
                  )
                case "iron":
                  let iron
                  let ironTitle
                  switch (radioData[key]) {
                    case "0":
                      ironTitle = "530"
                      iron = h27_wash_530
                      break
                    case "1":
                      ironTitle = "520"
                      iron = h27_wash_520
                      break
                    case "2":
                      ironTitle = "510"
                      iron = h27_wash_510
                      break
                    case "3":
                      ironTitle = "500"
                      iron = h27_wash_500
                      break
                  }
                  return (
                    <div className="view_value" key={`wash_${index}`}>
                      {ironTitle ? (
                        <div className="custom_radio_item">
                          <p>{ironTitle}</p>
                          <div><img src={iron} width={45} /></div>
                        </div>
                      ) : <p style={{marginRight: 10}}>選択無し</p>}
                      <label>アイロン仕上げ処理</label>
                    </div>
                  )
                case "dryCleaning":
                  let dryCleaning
                  let dryCleaningTitle
                  switch (radioData[key]) {
                    case "0":
                      dryCleaningTitle = "620"
                      dryCleaning = h27_wash_620
                      break
                    case "1":
                      dryCleaningTitle = "621"
                      dryCleaning = h27_wash_621
                      break
                    case "2":
                      dryCleaningTitle = "610"
                      dryCleaning = h27_wash_610
                      break
                    case "3":
                      dryCleaningTitle = "611"
                      dryCleaning = h27_wash_611
                      break
                    case "4":
                      dryCleaningTitle = "600"
                      dryCleaning = h27_wash_600
                      break
                  }
                  return (
                    <div className="view_value" key={`wash_${index}`}>
                      {dryCleaningTitle ? (
                        <div className="custom_radio_item">
                          <p>{dryCleaningTitle}</p>
                          <div><img src={dryCleaning} width={45} /></div>
                        </div>
                      ) : <p style={{marginRight: 10}}>選択無し</p>}
                      <label>ドライクリーニング処理</label>
                    </div>
                  )
                case "wetCleaning":
                  let wetCleaning
                  let wetCleaningTitle
                  switch (radioData[key]) {
                    case "0":
                      wetCleaningTitle = "710"
                      wetCleaning = h27_wash_710
                      break
                    case "1":
                      wetCleaningTitle = "711"
                      wetCleaning = h27_wash_711
                      break
                    case "2":
                      wetCleaningTitle = "712"
                      wetCleaning = h27_wash_712
                      break
                    case "3":
                      wetCleaningTitle = "700"
                      wetCleaning = h27_wash_700
                      break
                  }
                  return (
                    <div className="view_value" key={`wash_${index}`}>
                      {wetCleaningTitle ? (
                        <div className="custom_radio_item">
                          <p>{wetCleaningTitle}</p>
                          <div><img src={wetCleaning} width={45} /></div>
                        </div>
                      ) : <p style={{marginRight: 10}}>選択無し</p>}
                      <label>ウエットクリーニング処理</label>
                    </div>
                  )
              }
            })}
          </div>
        )
        break
      case 9:
        console.log("9", value)
        // let tmpTxt = changeMultipleLines(value)
        // retVal = value ? <div className="view_value" children={tmpTxt} /> : "なし"
        break
    }
    return retVal
  }


return (
  <div className="product_view_row">
    <div className="ir_head">{renderTextAtCaution(title)}</div>
    <div className="view_content">
      {attributes.map((item, index) => (
        <div className={item.input_type === 6 ? "view_item has_img" : "view_item"} key={index}>
          {item.title ? <div className="view_title">{item.title}</div> : undefined}
          {renderValue(item.input_type, item.value)}
        </div>
      ))}
    </div>
  </div>
)
}

export default ProductViewItem
