import "../../../../common/wdyr"
import { FunctionComponent, useEffect, useMemo, useState } from "react"
import * as React from "react"
import { Controller, FormProvider, useForm } from "react-hook-form"
import { ButtonComponent } from "../../../atoms/Button/Button"
import "../../../../styles/maker/maker_common.css"
import { IStep13 } from "./IStep13"
import { TextAreaForm } from "../../../molecules/TextAreaForm/TextAreaForm"
import {
  addLocalDB,
  convertBlobToFile,
  convertDataURItoBlob,
  formatVariableForm,
  get, getProduct,
  getUserType,
  post
} from "../../../../common/utils"
import { RELATIVE_PATH } from "../../../../common/routePath"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import { Accordion, AccordionDetails, AccordionSummary, Button } from "@material-ui/core"
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline"
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"
import Step2View from "../../ProductView/step2/Step2View"
import Step5View from "../../ProductView/step5/Step5View"
import Step6View from "../../ProductView/step6/Step6View"
import Step7View from "../../ProductView/step7/Step7View"
import Step8View from "../../ProductView/step8/Step8View"
import Step9View from "../../ProductView/step9/Step9View"
import Step10View from "../../ProductView/step10/Step10View"
import Step11View from "../../ProductView/step11/Step11View"
import Step12View from "../../ProductView/step12/Step12View"
import ProductView from "../../ProductView/ProductView"
import { navigate } from "gatsby"
import {
  PRODUCT_REGISTER,
  PRODUCT_UPDATE,
} from "../../../../common/apiConstants"
import { Loading } from "../../../atoms/Loading/Loading"
import { EXCEPTION_NAME } from "../../../../common/constants"
import _ from "lodash"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: 400,
      borderRadius: 5,
      borderColor: "#ccc"
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },
    accountBlock: {
      marginBottom: "2rem",
      width: '100%',
    },
    heading: {
      fontWeight: theme.typography.fontWeightBold,
    },
  }),
)

export const Step13: FunctionComponent<IStep13> = (props) => {
  const [ forms, setForms ] = useState(props)
  const [ loading, setLoading ] = useState(false)
  const [ step1Form, setStep1Form ] = useState()
  const [ step2Form, setStep2Form ] = useState()
  const [ step3Form, setStep3Form ] = useState()
  const [ step4Form, setStep4Form ] = useState()
  const [ step5Form, setStep5Form ] = useState()
  const [ step6Form, setStep6Form ] = useState()
  const [ step7Form, setStep7Form ] = useState()
  const [ step8Form, setStep8Form ] = useState()
  const [ step9Form, setStep9Form ] = useState()
  const [ step10Form, setStep10Form ] = useState()
  const [ step11Form, setStep11Form ] = useState()
  const [ step12Form, setStep12Form ] = useState()
  const [ expandPanel, setExpandPanel ] = useState<string | false>(false)
  const classes = useStyles()

  useEffect(() => {
    Object.values(props).map((form, index) => {
      switch (form.formName) {
        case "step1":
          setStep1Form(JSON.parse(form.form))
          break
        case "step2":
          setStep2Form(JSON.parse(form.form))
          break
        case "step3":
          setStep3Form(JSON.parse(form.form))
          break
        case "step4":
          setStep4Form(JSON.parse(form.form))
          break
        case "step5":
          setStep5Form(JSON.parse(form.form))
          break
        case "step6":
          setStep6Form(JSON.parse(form.form))
          break
        case "step7":
          setStep7Form(JSON.parse(form.form))
          break
        case "step8":
          setStep8Form(JSON.parse(form.form))
          break
        case "step9":
          setStep9Form(JSON.parse(form.form))
          break
        case "step10":
          setStep10Form(JSON.parse(form.form))
          break
        case "step11":
          setStep11Form(JSON.parse(form.form))
          break
        case "step12":
          setStep12Form(JSON.parse(form.form))
          break
      }
    })
  }, [])

  const renderProductView = (data: JSX.Element, title, panel, path) => {
    return (
      <Accordion expanded={expandPanel === panel} className="accordion_body" key={panel}>
        <AccordionSummary onClick={() => expandPanel === panel ? setExpandPanel("") : setExpandPanel(panel)}>
          <div className="accordion_title">
            {expandPanel === panel ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />}
            <div className="accordion_title_area">
              <label className={classes.heading}>{title}</label>
              <Button variant="contained" className="submitBtn"
                      onClick={() => navigate(path, { state: { from: "step13" } })}>編集</Button>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails className="member_account">
          {data}
        </AccordionDetails>
      </Accordion>
    )
  }

  const onNextStep = async () => {
    setLoading(true)
    const copyStep3 = formatVariableForm(step3Form)
    const copyStep4 = formatVariableForm(step4Form)
    const stepWrap = {
      step1: { ...step1Form },
      step2: { ...step2Form },
      step3: { ...copyStep3 },
      step4: { ...copyStep4 },
      step5: { ...step5Form },
      step6: { ...step6Form },
      step7: { ...step7Form },
      step8: { ...step8Form },
      step9: { ...step9Form },
      step10: { ...step10Form },
      step11: { ...step11Form },
      step12: { ...step12Form }
    }
    let diffFlg = false
    if (step2Form.id) {
      const stepDiffs = await getProduct(step2Form.id, stepWrap)
      stepDiffs.map((stepDiff, index) => {
        if (JSON.stringify(stepDiff.diff) !== '{}') {
          Object.keys(stepDiff).map((key, i) => {
            if (!EXCEPTION_NAME.includes(key)) {
              diffFlg = true
            }
          })
        }
      })
    }

    const paramObj = {
      product_info: {
        step1: { ...step1Form },
        step2: { ...step2Form },
        step3: { ...copyStep3 },
        step4: { ...copyStep4 },
        step5: { ...step5Form },
        step6: { ...step6Form },
        step7: { ...step7Form },
        step8: { ...step8Form },
        step9: { ...step9Form },
        step10: { ...step10Form },
        step11: { ...step11Form },
        step12: { ...step12Form }
      },
      is_draft: 0,
      is_revoke: diffFlg,
    }
    if (step2Form.id) {
      await post(PRODUCT_UPDATE, paramObj).then(response => {
        navigate(RELATIVE_PATH.FINISH)
      }).catch(e => {
        console.log("PRODUCT_UPDATE", e)
      }).finally(() => {
        setLoading(false)
      })
    } else {
      await post(PRODUCT_REGISTER, paramObj).then(response => {
        navigate(RELATIVE_PATH.FINISH)
      }).catch(e => {
        console.log("PRODUCT_REGISTER", e)
      }).finally(() => {
        setLoading(false)
      })
    }
  }

  return (
    <section className="main_area_full main_center step step13">
      {loading ? <Loading /> : undefined}
      <h2 className="h2_2">商品新規登録 - 最終確認
        <div className="step">STEP <span className="big">13</span>/ <span>13</span></div>
      </h2>
      <section className="main_center product_detail">
        <div className={classes.accountBlock}>
          <div style={{ textAlign: "center", marginBottom: 30, fontWeight: "bold" }}>下記の内容で間違いがなければ、「審査へ提出」ボタンを押してください
          </div>
          {step2Form ? renderProductView(
            <Step2View {...step2Form} />, "商品基本情報", "step2", RELATIVE_PATH.STEP2) : undefined}
          {step3Form ? renderProductView(
            <ProductView {...step3Form} />, "商品仕様", "step3", RELATIVE_PATH.STEP3) : undefined}
          {step4Form ? renderProductView(
            <ProductView {...step4Form} />, "商品分類別情報", "step4", RELATIVE_PATH.STEP4) : undefined}
          {step5Form ? renderProductView(
            <Step5View {...step5Form} />, "返品・サポート情報", "step5", RELATIVE_PATH.STEP5) : undefined}
          {step6Form ? renderProductView(
            <Step6View {...step6Form} />, "セールスポイント - 商品概要", "step6", RELATIVE_PATH.STEP6) : undefined}
          {step7Form ? renderProductView(
            <Step7View {...step7Form} />, "セールスポイント - 効果", "step7", RELATIVE_PATH.STEP7) : undefined}
          {step8Form ? renderProductView(
            <Step8View {...step8Form} />, "セールスポイント - 実績", "step8", RELATIVE_PATH.STEP8) : undefined}
          {step9Form ? renderProductView(
            <Step9View {...step9Form} />, "セールスポイント - 基本", "step9", RELATIVE_PATH.STEP9) : undefined}
          {step10Form ? renderProductView(
            <Step10View {...step10Form} />, "セールスポイント - 実演", "step10", RELATIVE_PATH.STEP10) : undefined}
          {step11Form ? renderProductView(
            <Step11View {...step11Form} />, "セールスポイント - 出演情報", "step11", RELATIVE_PATH.STEP11) : undefined}
          {step12Form ? renderProductView(
            <Step12View {...step12Form} />, "その他", "step12", RELATIVE_PATH.STEP12) : undefined}
        </div>
        <div className="approve_button_area">
          <div className="approve_text">
            商品は管理者による審査を経て公開されます。<br />レビュー中に確認事項があれば、メッセージにより通知されます。
          </div>
          <Button variant="contained" onClick={() => onNextStep()}>審査へ提出</Button>
        </div>
      </section>
    </section>
  )
}

export default Step13
