import * as React from "react"
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { FunctionComponent } from "react"
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { IAdoptProductList } from "./IAdoptProductList"
import * as moment from "moment"
import { SELLING_TYPES } from "../../../common/constants"
import { getSellingStatusStyle } from "../../../common/utils"

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell)

export const AdoptProducts: FunctionComponent<IAdoptProductList> = (props) => {
  const { adoptProductList } = props

  const rowClick = (event, row) => {
    console.log(event, row)
  }

  const getAdoptedAt = (buyerAt, makerAt) => {
    let adoptedAt
    if (moment(buyerAt).isAfter(makerAt)){
      adoptedAt = buyerAt
    } else {
      adoptedAt = makerAt
    }
    return adoptedAt
  }

  return (
    <TableContainer>
      <Table className="vendin_table" aria-label="vendin_table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="left">No.</StyledTableCell>
            <StyledTableCell align="left">商品名</StyledTableCell>
            <StyledTableCell align="left">価格</StyledTableCell>
            <StyledTableCell align="left">商談成立日</StyledTableCell>
            <StyledTableCell align="left">公開ステータス</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {adoptProductList.map((row) => (
            <TableRow className="clickEnabled" key={row.id} onClick={(e) => rowClick(e, row)}>
              <StyledTableCell align="left">{row.product_id}</StyledTableCell>
              <StyledTableCell align="left" className="bold">
                <div className="img_with_text"><img src={row.product.images ? row.product.images[0].image : false} width={40} /><span className="bold">{row.product.name}</span></div>
              </StyledTableCell>
              <StyledTableCell align="left">¥{row.product.regular_price.toLocaleString()}(税抜)</StyledTableCell>
              <StyledTableCell align="left">{moment(getAdoptedAt(row.buyer_adopted_at, row.maker_adopted_at)).format("YYYY/MM/DD")}</StyledTableCell>
              <StyledTableCell align="left">
                <div className={`goods_table_status ${getSellingStatusStyle(row.product.is_selling)}`}>{SELLING_TYPES[row.product.is_selling]}</div>
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}