import * as React from "react"
import { IImage } from "./IImage"
import { useState } from "react"
import Modal from "@material-ui/core/Modal"
import Backdrop from "@material-ui/core/Backdrop"
import Fade from "@material-ui/core/Fade"

export const Image = React.memo((props: IImage) => {
  const [ imageModal, setImageModal ] = useState(false)

  return (
    <>
      <img src={props.url} onClick={() => setImageModal(true)} />

      <Modal
        className="confirm_modal"
        open={imageModal}
        onClose={() => setImageModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={imageModal}>
          <div className="modal_body">
            <div className="movie_wrap">
              <img src={props.url} style={{maxWidth: "800px"}} />
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  )
})
