import * as React from "react"
import { FunctionComponent, useMemo, useState } from "react"
import { Button, FormControl, InputLabel, MenuItem, NativeSelect, Select } from "@material-ui/core"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Pagination } from "@material-ui/lab"
import { IAdminMemberList } from "./IAdminMemberList"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableBody from "@material-ui/core/TableBody"
import TableContainer from "@material-ui/core/TableContainer"
import { withStyles } from "@material-ui/core/styles"
import TableCell from "@material-ui/core/TableCell"
import { BUYER_ADMIN, DEALING_TYPES, MAKER_ADMIN } from "../../../../common/constants"
import { getDealingStatus } from "../../../../common/utils"
import { CustomPagination } from "../../../molecules/Pagination/Pagination"
import { Loading } from "../../../atoms/Loading/Loading"
import { navigate } from "gatsby"
import { RELATIVE_PATH } from "../../../../common/routePath"
import * as moment from "moment"
import { ButtonComponent } from "../../../atoms/Button/Button"
import Backdrop from "@material-ui/core/Backdrop"
import Fade from "@material-ui/core/Fade"
import AccountForm from "../../../organisms/AccountForm/AccountForm"
import Modal from "@material-ui/core/Modal"

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell)

export const AdminMemberList: FunctionComponent<IAdminMemberList> = (props) => {
  const { total, result, loading, company_id, company_type, companyList, userList, selectedType, onSearch, setCreateFlg } = props
  const [ accountModal, setAccountModal ] = useState(false)
  const [ editModal, setEditModal ] = useState(false)
  const [ totalCnt, setTotalCnt] = useState(0)
  const [ isEdit, setIsEdit] = useState(false)
  const [ success, setSuccess ] = useState(false)
  const [ companyName, setCompanyName ] = useState<string>()
  const [editId, setEditId] = useState()
  const [editCompanyId, setEditCompanyId] = useState()
  const [editName, setEditName] = useState()
  const [editMail, setEditMail] = useState()
  const [editType, setEditType] = useState()

  let retVal = undefined

  const handleChange = (event, row) => {
    onSearch(event.target.value)
    setCompanyName(row.props.children)
  }
  const typeChange = (event) => {
    selectedType(event.target.value)
  }

  const rowClick = (event, row) => {
    setEditId(row.id)
    setEditCompanyId(row.company_id)
    setEditName(row.name)
    setEditMail(row.email)
    setEditType(row.type)
    setEditModal(true)
  }

  const setShowModal = () => {
    setSuccess(true)
  }

  const getCompanyName = () => {
    let rtnName = ""
    companyList.map((company) => {
      if (company.id === company_id) {
        rtnName = company.name
      }
    })
    return rtnName
  }

  retVal = (
    <>
      <section className="main_center admin_member">
        <div className="mp_product_title dealing">
          <div className="list_title_box">
            <h2 className="top_title_h2">管理者一覧</h2>
            <div className="search_list_num"><span>{total ? total : 0}</span>件見つかりました</div>
          </div>
        </div>

        {loading ? <Loading /> : undefined}

        <div className="search_status">
          <FormControl variant="outlined" className="select_company_type">
            <Select
              displayEmpty
              labelId=""
              id=""
              value={company_type}
              onChange={typeChange}
              IconComponent={props => (
                <ExpandMoreIcon {...props} className={`material-icons ${props.className}`}>
                </ExpandMoreIcon>
              )}
            >
              <MenuItem value="">
                <em>選択して下さい</em>
              </MenuItem>
              <MenuItem value={0}>バイヤー</MenuItem>
              <MenuItem value={1}>メーカー</MenuItem>
            </Select>
          </FormControl>

          <FormControl variant="outlined" className="select_company">
            <Select
              displayEmpty
              labelId=""
              id=""
              value={company_id}
              disabled={company_type.toString() === "" ? true : false}
              onChange={handleChange}
              IconComponent={props => (
                <ExpandMoreIcon {...props} className={`material-icons ${props.className}`}>
                </ExpandMoreIcon>
              )}
            >
              <MenuItem value="">
                <em>会社名</em>
              </MenuItem>
              {companyList.map((item, index) => (
                <MenuItem key={`company_${index}`} value={item.id}>{item.name}</MenuItem>))}
            </Select>
          </FormControl>
        </div>

        <div style={{ marginBottom: 20 }}>
          {result ? (
            <TableContainer>
              <Table className="vendin_table" aria-label="vendin_table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">No.</StyledTableCell>
                    <StyledTableCell align="left">会社名</StyledTableCell>
                    <StyledTableCell align="left">管理者名</StyledTableCell>
                    <StyledTableCell align="left">メールアドレス</StyledTableCell>
                    <StyledTableCell align="left">更新日時</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userList.map((row) => {
                    if (row.type == BUYER_ADMIN || row.type == MAKER_ADMIN) {
                      return (
                        <TableRow className="clickEnabled" key={row.id} onClick={(e) => rowClick(e, row)}>
                          <StyledTableCell align="left">{row.id}</StyledTableCell>
                          <StyledTableCell align="left" className="bold">{getCompanyName()}</StyledTableCell>
                          <StyledTableCell align="left"><span className="bold">{row.name}</span></StyledTableCell>
                          <StyledTableCell align="left">{row.email}</StyledTableCell>
                          <StyledTableCell
                            align="left">{moment(row.updated_at).format("YYYY/MM/DD HH:mm")}</StyledTableCell>
                        </TableRow>
                      )
                    }
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : <div>該当するユーザーがいません</div>}
        </div>
        {company_type.toString() ? (
          <div style={{paddingTop: 50}}>
            <div className="fbbw_box">
              <ButtonComponent text={"新規登録"} type={"button"} onClick={() => {
                setIsEdit(false)
                setAccountModal(true)
              }} />
            </div>
          </div>
        ) : undefined }
        {/*<CustomPagination current_page={props.current_page} last_page={props.last_page} setPage={changePagination} />*/}
      </section>

      <Modal
        className="confirm_modal account_modal"
        open={accountModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={accountModal}>
          <div className="modal_body account_modal_width">
            <h2 id="transition-modal-title">会社管理者アカウント追加</h2>
            <div>
              {success ?
                <div className="success_message">会社管理者アカウントを作成しました</div> :
                <AccountForm
                  is_edit={false}
                  company_name={getCompanyName()}
                  company_type={company_type}
                  setShowModal={() => setShowModal()} />
              }
            </div>
            <Button className="positive" variant="contained" onClick={() => {
              setAccountModal(false)
              setCompanyName("")
              setSuccess(false)
              // setCreateFlg(success)
            }}>閉じる</Button>
          </div>
        </Fade>
      </Modal>

      <Modal
        className="confirm_modal account_modal"
        open={editModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={editModal}>
          <div className="modal_body">
            <h2 id="transition-modal-title">会社管理者アカウント更新</h2>
            <div>
              {success ?
                <div className="success_message">会社管理者アカウントを更新しました</div> :
                <AccountForm
                  is_edit={true}
                  id={editId}
                  name={editName}
                  email={editMail}
                  type={editType}
                  company_id={editCompanyId}
                  company_name={getCompanyName()}
                  setShowModal={() => setShowModal()}
                />
              }
            </div>
            <Button className="positive" variant="contained" onClick={() => {
              setEditModal(false)
              setCompanyName("")
              setSuccess(false)
              selectedType("")
              // setCreateFlg(success)
            }}>閉じる</Button>
          </div>
        </Fade>
      </Modal>
    </>
  )


  return retVal
}