import { FunctionComponent, useEffect, useState } from "react"
import * as React from "react"
import "../../../../styles/message/message.css"
import MessageSideBar from "../../../organisms/common/message/MessageSideBar"
import { IDealings } from "./IDealings"
import { DealingList } from "../../../organisms/Message/DealingList/DealingList"

const Dealings: FunctionComponent<IDealings> = (props) => {
  return (
    <main className="main_area_two_col">
      <DealingList {...props} />

      <MessageSideBar current={"dealing"} />
    </main>
  )
}

export default Dealings
