import * as React from "react"
import { FunctionComponent, useEffect, useState } from "react"
import { RouteProps } from "react-router"
import { Loading } from "../../atoms/Loading/Loading"
import Maker from "../../templates/Maker/Maker"
import { get } from "../../../common/utils"
import { COMPANY_DETAIL, PRODUCT_ALL_INFORMATION } from "../../../common/apiConstants"
import Approve from "../../templates/Approve/Approve"

const ApprovePage: FunctionComponent<RouteProps> = (props) => {
  const [data, setData] = useState()
  const [productId, setProductId] = useState(props.location.state["product_id"])

  useEffect( () => {
    (async () => {
      let paramObj = {params: {}}
      paramObj.params.id = productId
      const result = await get(PRODUCT_ALL_INFORMATION, paramObj).catch(e => console.log("e", e))
      // @ts-ignore
      setData(result)
    })()
  }, [])

  return data ? (
    <Approve {...data} />
  ) : <Loading />
}

export default ApprovePage
