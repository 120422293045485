import * as React from "react"
import { FunctionComponent, useEffect, useMemo, useState } from "react"
import { IMakerDetail } from "./IMakerDetail"
import { OuterLink } from "../../atoms/OuterLink/OuterLink"
import "../../../styles/maker_view.css"
import { ProductItem } from "../../molecules/ProductItem/ProductItem"
import { changeMultipleLines, matchFavorite } from "../../../common/utils"
import { Loading } from "../../atoms/Loading/Loading"
import { Grid } from "@material-ui/core"

export const MakerDetail: FunctionComponent<IMakerDetail> = (props) => {
  const { company, recent_products, brand_info } = props
  const [ favoriteList, setFavoriteList ] = useState([])
  const [ products, setProducts ] = useState()
  const [ loading, setLoading ] = useState(false)

  useEffect(() => {
    (async () => {
      setLoading(true)
      const dataList = await matchFavorite()
      setFavoriteList(dataList)
      // @ts-ignore
      setProducts(renderProduct(dataList))
      setLoading(false)
    })()
  }, [])

  const renderProduct = (dataList) => {
    return (
      <div className="recent_products">
        {recent_products.map((product, index) => (
          <ProductItem key={`${index}_product_item`} favoriteList={dataList} {...product} />
        ))}
      </div>
    )
  }

  return (
    <section className="main_area maker_detail">
      <h2 className="top_title_h2">{company.name}<span><OuterLink url={"##"} title={"公式HP"} /></span></h2>
      <div className="maker_detail_header">
        <img src={company.image} />
      </div>
      <h3 className="maker_title">メーカー紹介</h3>
      <div className="maker_profile">{changeMultipleLines(company.introduction)}</div>
      <h3 className="maker_title">会社概要</h3>
      <div className="maker_introduction">{changeMultipleLines(company.profile)}</div>
      <h3 className="maker_title">担当者アピール</h3>
      <div className="maker_introduction">{changeMultipleLines(company.appeal)}</div>

      <div className="brand_info_wrap">
        <h3 className="brand_wrap_title">ブランドシリーズ</h3>
        {brand_info.length >= 1 ? (
          brand_info.map((brand, index) => {
            return (
              <Grid container justifyContent="flex-start" spacing={2} key={`${index}_brand`} className="brand_info_row">
                <Grid item md={3} className="brand_name">
                  {brand.brand_url ? <OuterLink url={brand.brand_url} title={brand.brand_name} /> : brand.brand_name}
                </Grid>
                <Grid item md={9} className="brand_body">
                  {changeMultipleLines(brand.brand_body)}
                </Grid>
              </Grid>
            )
          })
        ) : (
          <div style={{ textAlign: "center", marginBottom: 20 }}>ブランドシリーズはありません</div>
        )}
      </div>

      <h2 className="top_title_h2">最新の掲載商品</h2>
      {loading ? <Loading /> : products}
    </section>
  )
}
