import "../../../../common/wdyr"
import { FunctionComponent, useEffect, useMemo, useState } from "react"
import * as React from "react"
import { yupResolver } from "@hookform/resolvers/yup"
import { Controller, FormProvider, useForm } from "react-hook-form"
import { ButtonComponent } from "../../../atoms/Button/Button"
import "../../../../styles/maker/maker_common.css"
import { IStep9 } from "./IStep9"
import { ProductForm } from "../../../organisms/ProductForm/ProductForm"
import * as yup from "yup"
import { Button, TextField } from "@material-ui/core"
import { TextForm } from "../../../molecules/TextForm/TextForm"
import { FileForm } from "../../../molecules/FileForm/FileForm"
import { ImageForm } from "../../../molecules/ImageForm/ImageForm"
import * as _ from "lodash"
import { addLocalDB, onDraftSubmit, returnIncrementName } from "../../../../common/utils"
import { TextAreaForm } from "../../../molecules/TextAreaForm/TextAreaForm"
import { RELATIVE_PATH } from "../../../../common/routePath"
import DraftModal from "../../../organisms/common/modal/DraftModal"

export const Step9: FunctionComponent<IStep9> = (props) => {
  const [form, setForm] = useState(props.sales_points)
  const [ isDraft, setIsDraft ] = React.useState(false)
  const [ showDraftModal, setShowDraftModal ] = React.useState(false)
  const [ rtnProductId, setRtnProductId] = useState()
  const [loading, setLoading] = useState(false)
  const schema = yup.lazy(() => {
    let yupList = {}
    form.map((value, index) => {
      yupList[`title_${index}`] = yup.string().max(255,"255文字以内")
      yupList[`sales_point_${index}`] = yup.string().max(1000,"1000文字以内")
      value.sales_documents.map((document, i) => {
        yupList[`sales_url_${index}_${i}`] = yup.string().url("URLが不正です")
      })
    })
    return yup.object().shape({ ...yupList })
  })

  const methods = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema)
  })

  const { handleSubmit, formState: { errors }  } = methods
  const onClick = async (data) => {
    const submitObje = _.cloneDeep(form)
    Object.keys(data).map((key, index) => {
      const names = key.split("_")
      if (key.includes("title")) {
        if (names.length > 1 && submitObje[names[1]]) {
          submitObje[names[1]]["title"] = data[key]
        }
      } else if (key.includes("sales_point")) {
        if (names.length > 2 && submitObje[names[2]]) {
          submitObje[names[2]]["sales_point"] = data[key]
        }
      } else if (key.includes("sales_url")) {
        if (submitObje[names[2]] && submitObje[names[2]]["sales_documents"][names[3]]) {
          submitObje[names[2]]["sales_documents"][names[3]]["sales_url"] = data[key]
        }
      }
    })
    const dataObj = JSON.stringify({
      sales_points: [
        ...submitObje
      ]
    })
    if (isDraft) {
      setShowDraftModal(true)
      await onDraftSubmit("step9", dataObj).then(res => {
        // @ts-ignore
        setRtnProductId(res ? res["product_id"] : props.sales_points[0].product_id)
      }).catch(e => console.log("onDraftSubmit", e)).finally(() => setLoading(false))
    } else {
      await addLocalDB("step9", dataObj, RELATIVE_PATH.STEP10)
    }
  }

  // フォームを呼び出し
  const renderForm = useMemo(() => {
    // @ts-ignore
    return (form.map((value, index) => (
      <div key={`${index}_custom_form`} className="item_full_wrap">
        <TextForm
          id={`title_${index}`}
          title={(index + 1) + ""}
          name={`title_${index}`}
          value={value.title}
        />
        <TextAreaForm
          id={`sales_point_${index}`}
          name={`sales_point_${index}`}
          value={value.sales_point}
        />
        <div className="effect_child">
          {value.sales_documents.map((document, i) => {
            return (
              <div key={`${i}_document_form`} className="effect_child_row">
                <FileForm
                  id={`document_${index}_${i}`}
                  title={"実績資料"}
                  name={`document_${index}_${i}`}
                  value={document["sales_document"]}
                  caution={"※形式はPDFのみ\r\n※容量は最大2MBまで"}
                  setForm={(index, url) => setFormFile(index, url, i)}
                />
                <TextForm
                  id={`sales_url_${index}_${i}`}
                  title={"資料URL"}
                  name={`sales_url_${index}_${i}`}
                  value={document["sales_url"]}
                  placeholder={"例：https://www.vendin.co.jp"}
                />
              </div>
            )
          })}
          <div className="clone_buttons">
            <Button onClick={e => onAdd(index)} className="addBtn" style={{ marginRight: "1rem" }}>追加</Button>
            <Button onClick={e => onRemove(index)} variant="outlined">削除</Button>
          </div>
        </div>
      </div>
    )))
  }, [form])

  const onAdd = (index) => {
    let temp = _.cloneDeep(form)
    const lastIndex = temp[index].sales_documents.length - 1
    const attribute = _.cloneDeep(temp[index].sales_documents[lastIndex])
    attribute["sales_document"] = ""
    attribute["sales_url"] = ""
    temp[index].sales_documents.push(attribute)

    setForm(temp)
  }

  const onRemove = (index) => {
    let temp = _.cloneDeep(form)
    if (temp[index].sales_documents.length > 1) {
      temp[index].sales_documents.pop()
      setForm(temp)
    }
  }

  const onFormAdd = () => {
    let temp = _.cloneDeep(form)
    const lastIndex = temp.length - 1
    const lastForm = _.cloneDeep(temp[lastIndex])
    lastForm["title"] = ""
    lastForm["sales_point"] = ""
    const tempDocument = lastForm.sales_documents[0]
    tempDocument["sales_document"] = ""
    tempDocument["sales_url"] = ""
    lastForm.sales_documents.splice(0)
    lastForm.sales_documents.push(tempDocument)
    temp.push(lastForm)

    setForm(temp)
  }

  const onFormRemove = () => {
    let temp = _.cloneDeep(form)
    if (temp.length > 1) {
      temp.pop()
      setForm(temp)
    }
  }

  const setFormFile = (index, url, childIndex) => {
    let i = index ? index : 0
    let j = childIndex ? childIndex : 0
    let temp = _.cloneDeep(form)
    const attribute = _.cloneDeep(temp[i])
    attribute["sales_documents"][j]["sales_document"] = url
    temp[i] = attribute
    setForm(temp)
  }

  return (
    <section className="main_area_full main_center step step9">
      <h2 className="h2_2">商品新規登録 - セールス情報
        <div className="step">STEP <span className="big">9</span>/ <span>13</span></div>
      </h2>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onClick)}>
          <div className="ir_box">
            <h3 className="rh3">セールスポイント基本<span className="must">必須項目</span></h3>
            <div className="step9_wrap">
              {renderForm}

              <div className="clone_buttons">
                <Button onClick={onFormAdd} className="addBtn" style={{ marginRight: "1rem" }}>入力項目を追加</Button>
                <Button onClick={onFormRemove} variant="outlined">削除</Button>
              </div>
            </div>
          </div>

          <div className="form_bottom_btn_wrap">
            <div className="fbbw_box">
              <ButtonComponent text={"下書き保存"} type={"submit"} onClick={() => setIsDraft(true)} />
              <ButtonComponent text={"次へ進む"} type={"submit"} onClick={() => setIsDraft(false)} />
            </div>
          </div>
        </form>
      </FormProvider>

      <DraftModal
        productId={rtnProductId}
        is_show={showDraftModal}
        loading={loading}
        setLoading={e => setLoading(e)}
        set_is_show={e => setShowDraftModal(e)}
      />
    </section>
  )
}

export default Step9
