import * as React from "react"
import "../../../../../styles/common.css"
import {FunctionComponent} from "react";
import {IAdminHeadBottom} from "./IAdminHeadBottom";
import { RELATIVE_PATH } from "../../../../../common/routePath"
import { Link } from "gatsby"

const AdminHeadBottom:FunctionComponent<IAdminHeadBottom> = (props) => {
    const { showSideBar } = props

    return (
        <div className="head_lower_wrap admin_head_lower">
            <div className="head_lower_box">
                <div className="h_side_btn_wrap">
                    <div className="h_side_btn" onClick={showSideBar} color="initial">システム管理</div>
                </div>
                <div className="h_list_box">
                    <Link to={RELATIVE_PATH.MAKER_LIST} className="h_l_maker">掲載メーカー一覧</Link>
                </div>
            </div>
        </div>
    )
}

export default AdminHeadBottom