import "../../../../common/wdyr"
import { FunctionComponent, useEffect, useMemo, useState } from "react"
import * as React from "react"
import { yupResolver } from "@hookform/resolvers/yup"
import { Controller, FormProvider, useForm } from "react-hook-form"
import { ButtonComponent } from "../../../atoms/Button/Button"
import "../../../../styles/maker/maker_common.css"
import { IStep5 } from "./IStep5"
import * as yup from "yup"
import { RELATIVE_PATH } from "../../../../common/routePath"
import { PHONE_REG_EXP, POST_REG_EXP } from "../../../../common/yupConstants"
import { addLocalDB, onDraftSubmit } from "../../../../common/utils"
import { PREFECTURES } from "../../../../common/constants"
import { RadioForm } from "../../../molecules/RadioForm/RadioForm"
import { TextForm } from "../../../molecules/TextForm/TextForm"
import { SelectBoxForm } from "../../../molecules/SelectBoxForm/SelectBoxForm"
import DraftModal from "../../../organisms/common/modal/DraftModal"
import { TextField } from "@material-ui/core"

export const Step5: FunctionComponent<IStep5> = (props) => {
  const {
    after_service,
    return_name,
    return_post,
    return_prefectures,
    return_address,
    return_tel,
    return_fax,
    pl_insurance_number,
    contact_company,
    division_name,
    contact_person,
    reception_time_from,
    reception_time_to,
    contact_company_tel,
    contact_company_fax,
    contact_company_address,
    contact_company_mail_address
  } = props
  const [ insurance, setInsurance ] = useState(pl_insurance_number)
  const [ stateProps, setStateProps ] = useState(props)
  const [ isDraft, setIsDraft ] = React.useState(false)
  const [ showDraftModal, setShowDraftModal ] = React.useState(false)
  const [ rtnProductId, setRtnProductId ] = useState()
  const [ loading, setLoading ] = useState(false)

  const methods = useForm({
    mode: "onBlur",
    resolver: yupResolver(
      yup.object().shape({
        after_service: yup.string().required("必須"),
        contact_company: yup.string().max(255, "255文字以内").test('match', '必須', function (contact_company) {
          return this.parent.after_service === "1" ? contact_company.length >= 1 : true
        }),
        division_name: yup.string().max(255, "255文字以内"),
        contact_person:yup.string().max(255, "255文字以内"),
        reception_time_from: yup.mixed().test('match', '必須', function (reception_time_from) {
          return this.parent.after_service === "1" ? reception_time_from : true
        }),
        reception_time_to: yup.mixed().test('match', '必須', function (reception_time_to) {
          return this.parent.after_service === "1" ? reception_time_to : true
        }),
        contact_company_tel: yup.mixed().test('match', '電話番号の形式に誤りがあります', function (contact_company_tel) {
          if (this.parent.after_service === "1") {
            if (contact_company_tel) {
              return !contact_company_tel.match(PHONE_REG_EXP) ? false : true
            } else {
              return false
            }
          } else {
            if (contact_company_tel) {
              return !contact_company_tel.match(PHONE_REG_EXP) ? false : true
            } else {
              return true
            }
          }
        }),
        contact_company_fax: yup.mixed().test('match', 'FAX番号の形式に誤りがあります', function (contact_company_fax) {
            if (contact_company_fax) {
              return !contact_company_fax.match(PHONE_REG_EXP) ? false : true
            } else {
              return true
            }
        }),
        contact_company_address: yup.string().max(255, "255文字以内").test('match', '必須', function (contact_company_address) {
          return this.parent.after_service === "1" ? contact_company_address.length >= 1 : true
        }),
        contact_company_mail_address: yup.string().email("Emailの形式に誤りがあります"),
        return_name: yup.string().max(255, "255文字以内").required("必須"),
        return_post: yup.string().matches(POST_REG_EXP, '郵便番号の形式に誤りがあります').required("必須"),
        return_prefectures: yup.string().required("必須"),
        return_address: yup.string().max(255, "255文字以内").required("必須"),
        return_tel: yup.string().matches(PHONE_REG_EXP, '電話番号の形式に誤りがあります').required("必須"),
        return_fax: yup.string().matches(PHONE_REG_EXP, 'FAX番号の形式に誤りがあります').required("必須"),
        pl_insurance_number: yup.string().matches(/^[0-9a-zA-Z\-]+$/, "半額英数字、ハイフンのみ").required("必須"),
      })
    )
  })

  const { handleSubmit, control, formState: { errors } } = methods
  const onNextStep = async (data) => {
    const dataObj = JSON.stringify({
      id: props.id ? props.id : "",
      product_id: props.product_id ? props.product_id : "",
      after_service: data.after_service,
      contact_company: data.contact_company,
      division_name: data.division_name,
      contact_person: data.contact_person,
      reception_time_from: data.reception_time_from,
      reception_time_to: data.reception_time_to,
      contact_company_tel: data.contact_company_tel,
      contact_company_fax: data.contact_company_fax,
      contact_company_address: data.contact_company_address,
      contact_company_mail_address: data.contact_company_mail_address,
      return_name: data.return_name,
      return_post: data.return_post,
      return_prefectures: data.return_prefectures,
      return_address: data.return_address,
      return_tel: data.return_tel,
      return_fax: data.return_fax,
      pl_insurance_number: data.pl_insurance_number,
    })
    if (isDraft) {
      setShowDraftModal(true)
      await onDraftSubmit("step5", dataObj).then(res => {
        // @ts-ignore
        setRtnProductId(res ? res["product_id"] : props.product_id)
      }).catch(e => console.log("onDraftSubmit", e)).finally(() => setLoading(false))
    } else {
      await addLocalDB("step5", dataObj, RELATIVE_PATH.STEP6)
    }
  }

  const onChangeInsurance = (e) => {
    if (e.target.name === "is_join_pl_insurance") {
      if (e.target.value === "0") {
        setInsurance("")
      }
    }
  }

  const renderDom = (
    <section className="main_area_full main_center">
      <h2 className="h2_2">商品新規登録
        <div className="step">STEP <span className="big">5</span>/ <span>13</span></div>
      </h2>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onNextStep)} onChange={onChangeInsurance}>
          <div className="ir_box">
            <h3 className="rh3">返品・サポート情報<span className="must">必須項目</span></h3>
            <div className="ir_item multiple_input">
              <div className="ir_head">アフターサービス</div>
              <div className="ir_content">
                <RadioForm
                  id="after_service"
                  name="after_service"
                  value={after_service ? after_service.toString() : "0"}
                  selects={[ "あり", "なし" ]}
                  selectsValue={[ "1", "0" ]}
                />
                <TextForm
                  id="contact_company"
                  name="contact_company"
                  title="窓口会社名"
                  value={contact_company}
                />
                <TextForm
                  id="division_name"
                  name="division_name"
                  title="部署名"
                  value={division_name}
                />
                <TextForm
                  id="contact_person"
                  name="contact_person"
                  title="担当者"
                  value={contact_person}
                />
                <div className="time_wrap">
                  <div className="time_content">
                    <div className="time_title">受付時間</div>
                    <Controller
                      name="reception_time_from"
                      control={control}
                      defaultValue={reception_time_from}
                      render={({ field }) => <TextField
                        id="reception_time_from"
                        variant="outlined"
                        color="secondary"
                        size="small"
                        type="time"
                        error={errors.reception_time_from?.message}
                        helperText={errors.reception_time_from?.message}
                        {...field} />}
                    />
                  </div>
                  <div className="time_spacer">～</div>
                  <div className="time_content">
                    <Controller
                      name="reception_time_to"
                      control={control}
                      defaultValue={reception_time_to}
                      render={({ field }) => <TextField
                        id="reception_time_to"
                        variant="outlined"
                        color="secondary"
                        size="small"
                        type="time"
                        error={errors.reception_time_to?.message}
                        helperText={errors.reception_time_to?.message}
                        {...field} />}
                    />
                  </div>
                </div>
                <TextForm
                  id="contact_company_tel"
                  name="contact_company_tel"
                  title="電話番号"
                  value={contact_company_tel}
                  placeholder="例）03-1234-1234"
                />
                <TextForm
                  id="contact_company_fax"
                  name="contact_company_fax"
                  title="FAX番号"
                  value={contact_company_fax}
                  placeholder="例）03-1234-1234"
                />
                <TextForm
                  id="contact_company_address"
                  name="contact_company_address"
                  title="住所"
                  value={contact_company_address}
                />
                <TextForm
                  id="contact_company_mail_address"
                  name="contact_company_mail_address"
                  title="Eメールアドレス"
                  value={contact_company_mail_address}
                />
              </div>
            </div>

            <div className="ir_item multiple_input">
              <div className="ir_head">返品先情報</div>
              <div className="ir_content">
                <TextForm
                  id="return_name"
                  name="return_name"
                  title="返品先情報"
                  value={return_name}
                  placeholder="例）VENDIN返品受付係"
                />
                <TextForm
                  id="return_post"
                  name="return_post"
                  title="郵便番号"
                  value={return_post}
                  placeholder="例）100-123"
                />
                <SelectBoxForm
                  id="return_prefectures"
                  name="return_prefectures"
                  title="都道府県"
                  value={return_prefectures}
                  selects={[ ...PREFECTURES ]}
                />
                <TextForm
                  id="return_address"
                  name="return_address"
                  title="以降の住所"
                  value={return_address}
                />
                <TextForm
                  id="return_tel"
                  name="return_tel"
                  title="電話番号"
                  value={return_tel}
                  placeholder="例）03-1234-1234"
                />
                <TextForm
                  id="return_fax"
                  name="return_fax"
                  title="FAX番号"
                  value={return_fax}
                  placeholder="例）03-1234-1234"
                />
              </div>
            </div>

            <div className="ir_item multiple_input">
              <div className="ir_head">保険情報（PL保険等）</div>
              <div className="ir_content">
                <TextForm
                  id="pl_insurance_number"
                  name="pl_insurance_number"
                  title="保険証番号"
                  value={insurance}
                />
              </div>
            </div>
          </div>


          <div className="form_bottom_btn_wrap">
            <div className="fbbw_box">
              <ButtonComponent text={"下書き保存"} type={"submit"} onClick={() => setIsDraft(true)} />
              <ButtonComponent text={"次へ進む"} type={"submit"} onClick={() => setIsDraft(false)} />
            </div>
          </div>
        </form>
      </FormProvider>

      <DraftModal
        productId={rtnProductId}
        is_show={showDraftModal}
        loading={loading}
        setLoading={e => setLoading(e)}
        set_is_show={e => setShowDraftModal(e)}
      />
    </section>
  )

  return renderDom
}

export default Step5
