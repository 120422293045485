import * as React from "react"
import { FunctionComponent } from "react"
import { IMessageSideBar } from "./IMessageSideBar"
import { Link } from "@material-ui/core"
import { RELATIVE_PATH } from "../../../../common/routePath"
import { getUserType } from "../../../../common/utils"
import { MAKER, MAKER_ADMIN } from "../../../../common/constants"

const MessageSideBar: FunctionComponent<IMessageSideBar> = (props) => {
  const { current } = props

  const renderSideContent = () => {
    const userType = getUserType()
    let topPath
    if (parseInt(userType) === MAKER_ADMIN || parseInt(userType) === MAKER) {
      topPath = (
        <>
          <li className={current === "approval" ? "side_item active" : "side_item"}>
            <Link href={RELATIVE_PATH.APPROVALS} className="side_link">申請</Link>
          </li>
          <li className={current === "question" ? "side_item active" : "side_item"}>
            <Link href={RELATIVE_PATH.QUESTIONS} className="side_link">QA</Link>
          </li>
        </>
      )
    }
    return topPath
  }

  return (
    <section className="side_area">
      <h2 className="side_title">メッセージ</h2>
      <ul className="side_bar">
        <li className={current === "notification" ? "side_item active" : "side_item"}>
          <Link href={RELATIVE_PATH.NOTIFICATIONS} className="side_link">お知らせ</Link>
        </li>
        <li className={current === "dealing" ? "side_item active" : "side_item"}>
          <Link href={RELATIVE_PATH.DEALINGS} className="side_link">商談</Link>
        </li>
        {renderSideContent()}
      </ul>
    </section>
  )
}

export default MessageSideBar
