import * as React from "react"
import { FunctionComponent, useEffect, useMemo, useState } from "react"
import { IProductItem } from "./IProductItem"
import "../../../styles/buyer/maker_profile.css"
import "../../../styles/product.css"
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import FavoriteIcon from '@material-ui/icons/Favorite'
import { Link } from "gatsby"
import { RELATIVE_PATH } from "../../../common/routePath"
import { FAVORITE } from "../../../common/apiConstants"
import { del, isBrowser, post } from "../../../common/utils"

/**
 * 商品
 * @param props
 * @constructor
 */
export const ProductItem: FunctionComponent<IProductItem> = (props) => {
  const {
    id,
    name,
    images,
    is_favorite,
    is_new,
    company,
    regular_price,
    wholesale_price,
    broadcast_price,
    is_stock,
    favoriteList
  } = props
  const discount_rate = (regular_price - wholesale_price) / regular_price * 100
  const [ favorite, setFavorite ] = useState(false)

  const getDiscountRate = () => {
    return (1 - (broadcast_price / regular_price)) * 100
  }

  useEffect(() => {
    setFavorite((favoriteList.includes(id)))
  }, [ favoriteList ])

  //割引率表示
  const renderDiscountRate = () => {
    let retVal = undefined

    if (discount_rate >= 1) {
      retVal = (
        <div className="product_sale_text">
          <span className="sale_num">{Math.round(getDiscountRate())}</span>%OFF
        </div>
      )
    }
    return retVal
  }

  //割引判定
  const isDiscount = () => {
    let retVal = false

    if ((regular_price - wholesale_price) > 0) {
      retVal = true
    }
    return retVal
  }

  //在庫切れ表示
  const renderOutStock = () => {
    let retVal = undefined

    if (Number(props.is_selling) !== 0 && (Number(is_stock) > 0 || Number(props.stock) === 0)) {
      retVal = (<div className="product_out_stock_text">在庫切れ</div>)
    }
    return retVal
  }

  //非公開表示
  const renderUnSelling = () => {
    let retVal = undefined

    if (Number(props.is_selling) === 0 ) {
      retVal = (<div className="product_un_selling_text">非公開</div>)
    }
    return retVal
  }

  //お気に入り
  const onFavorite = async (e, id) => {
    if (e.currentTarget.className === "favoriteIcon") {
      e.preventDefault()

      if (favorite) {
        setFavorite(false)
        let paramObj = { params: {} }
        paramObj.params.product_id = id
        await del(FAVORITE, paramObj).then(response => {
          console.log("FAVORITE success", response)
          isBrowser ? window.location.reload() : false
        }).catch(e => {
          console.log(e.message)
        })
      } else {
        setFavorite(true)
        let paramObj = {}
        paramObj.product_id = id
        await post(FAVORITE, paramObj).then(response => {
          console.log("FAVORITE success", response)
          isBrowser ? window.location.reload() : false
        }).catch(e => {
          console.log(e.message)
        })
      }

    }
  }

  const favoriteIcon = useMemo(() => {
    return (
      <div className="favoriteIcon" onClick={e => onFavorite(e, id)}>
        {favorite ? <FavoriteIcon className="favColor" /> :
          <FavoriteBorderIcon className="noFavColor" />}
      </div>
    )
  }, [ favorite, favoriteList ])

  return (
    <div className="product_item_wrap">
      <Link to={RELATIVE_PATH.PRODUCT} state={{ product_id: id }} className="product_item_link new">
        <div className="product_img_box">
          {images.length >= 1 ? (
            <div className="product_main_img">
              <div><img src={images[0].image} alt="" className="product_img" /></div>
            </div>
          ) : (
            <div className="product_main_img">
              <div style={{textAlign: "center"}}>商品画像なし</div>
            </div>
          )}
          {is_new ? <div className="newBadge">NEW</div> : undefined}
          {favoriteIcon}
          {renderDiscountRate()}
          {renderOutStock()}
          {renderUnSelling()}
        </div>
        <div className="product_title">{name}</div>
        <div className="product_price_box">
          <div className="product_actual_price">¥<span>{broadcast_price.toLocaleString()}</span>(税抜)</div>
          {isDiscount() ? <div className="product_original_price">¥<span>{regular_price.toLocaleString()}</span>(税抜)
          </div> : undefined}
        </div>
      </Link>
    </div>
  )
}
