import * as React from "react"
import { FunctionComponent, useEffect, useState } from "react"
import { RouteProps } from "react-router"
import { useCommonData } from "../../../common/customHooks"
import { Loading } from "../../atoms/Loading/Loading"
import Questions from "../../templates/Message/QuestionList/Questions"
import {questions} from "../../../common/data/sampleData"
import { get, getUserType } from "../../../common/utils"
import { PRODUCT_QA_LIST } from "../../../common/apiConstants"

const QuestionsPage: FunctionComponent<RouteProps> = () => {
  const [page, setPage] = useState(1)
  const [jsonData, setJsonData] = useState()

  useEffect( () => {
    (async () => {
      let paramObj = {params: {}}
      paramObj.params.page = page
      await get(PRODUCT_QA_LIST, paramObj).then(response => {
        console.log("product", response)
        setJsonData(response)
      }).catch(e => {
        console.log("e", e)
      })
    })()
  }, [page])

  return jsonData ? (
    <Questions
      {...jsonData.data}
      setPage={e => setPage(e)}
    />
  ) : <Loading />
}

export default QuestionsPage
