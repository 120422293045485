import * as React from "react"
import { FunctionComponent, useEffect, useState } from "react"
import { RouteProps } from "react-router"
import { Loading } from "../../atoms/Loading/Loading"
import { FormProvider, useForm } from "react-hook-form"
import MemberList from "../../templates/ProfileSetting/MemberList/MemberList"
import { get, getUserType } from "../../../common/utils"
import { SEARCH_USER } from "../../../common/apiConstants"

const ProfileMembers: FunctionComponent<RouteProps> = () => {
  const method = useForm({ mode: "onBlur" })
  const [memberList, setMembers] = useState(null)
  const [search, setSearch] = useState("")

  useEffect(() => {
    (async () => {
      await getUserList()
    })()
  }, [search])

  const getUserList = async () => {
    let paramObj = {params: {}}
    paramObj.params.keyword = search
    const memberList = await get(SEARCH_USER, paramObj).catch(e => console.log("e", e))
    console.log("memberList.result", memberList)
    setMembers(memberList.result)
  }

  const changeSearch = (search) => {
    setSearch(search)
  }

  return memberList ? (
    <MemberList result={memberList} changeSearch={e => changeSearch(e)} getUserList={getUserList} />
  ) : <Loading />
}

export default ProfileMembers
