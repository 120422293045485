import * as React from "react"
import "../../../styles/buyer/maker_list.css"
import { FunctionComponent } from "react"
import { IMakerItem } from "./IMakerItem"
import { useFormContext } from "react-hook-form"
import { RELATIVE_PATH } from "../../../common/routePath"
import { Link } from "gatsby"
import { changeMultipleLines, isAdmin } from "../../../common/utils"

export const MakerItem: FunctionComponent<IMakerItem> = (props) => {
  const { id, name, logo, introduction, profile, remarks } = props

  return (
    <div className={"ml_initial_item"}>
      <div className={"ml_ii_lbox"}>
        <Link to={RELATIVE_PATH.MAKER + "/?id=" + id}>
          {logo ? (
            <img src={logo} alt={"Maker Item"} style={{width: 150}} />
          ) : (
            <div style={{textAlign: "center", paddingTop: 5}}>NO IMAGE</div>
          )}
        </Link>
      </div>
      <div className={"ml_ii_rbox"}>
        <Link to={RELATIVE_PATH.MAKER + "/?id=" + id}>
          <div className={"ml_ii_brand_name"} style={{marginBottom: 10}}>{name}</div>
        </Link>
        <div className={"ml_ii_detail"}>{changeMultipleLines(profile)}</div>
        {isAdmin() && remarks ? (
          <div className={"ml_ii_detail maker_remarks"}>
            <p>備考 <span>※本項目は管理者にしか表示されません</span></p>
            {changeMultipleLines(remarks)}
          </div>
        ): undefined}
      </div>
    </div>
  )
}
