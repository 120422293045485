import * as React from "react"
import { FunctionComponent, useMemo, useState } from "react"
import { ITableForm } from "./ITableForm"
import { Title } from "../../atoms/Title/Title"
import { Description } from "../../atoms/Description/Description"
import { Caution } from "../../atoms/Caution/Caution"
import { Controller, useFormContext } from "react-hook-form"
import { Table, TableContainer, TextField } from "@material-ui/core"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import { withStyles } from "@material-ui/core/styles"
import TableCell from "@material-ui/core/TableCell"
import { DEALING_TYPES } from "../../../common/constants"
import { getDealingStatus, renderTextAtCaution } from "../../../common/utils"
import TableBody from "@material-ui/core/TableBody"

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell)

export const TableForm: FunctionComponent<ITableForm> = (props) => {
  const { register, control, formState: {errors} } = useFormContext()
  const { caution, description, id, title, name, value,  placeholder, rules, style, after_text } = props


  const renderRequired = () => {
    let retVal = null
    // @ts-ignore
    if (rules && "required" in rules && rules.required) {
      retVal = <span className="required">*</span>
    }
    return retVal
  }

  const renderTable = () => {
    let headKeys
    let values
    // @ts-ignore
    if (value instanceof Array) {
      values = JSON.parse(JSON.stringify(value))
    } else {
      values = JSON.parse(value)
    }
    headKeys = Object.keys(values[0])
    return (
      <TableContainer style={{width: "95%"}}>
        <Table className="vendin_table size_table" aria-label="vendin_table">
          <TableHead>
            <TableRow>
              {headKeys.map((key, index) => (<StyledTableCell align="left" key={`head_${index}`}>{renderTextAtCaution(key)}</StyledTableCell>))}
            </TableRow>
          </TableHead>
          <TableBody>
            {values.map((row,i) => (
              <TableRow className="clickEnabled" key={i}>
                {Object.values(row).map((item, j) => {
                  return (
                    <StyledTableCell align="left" key={`text_${i}_${j}`}>
                      <TextField
                        variant="outlined"
                        id={`${name}.${i}_${j}_${headKeys[j]}`}
                        name={`${name}.${i}_${j}_${headKeys[j]}`}
                        type={"text"}
                        defaultValue={item}
                        {...register(`${name}.${i}_${j}_${headKeys[j]}`, rules)} />
                    </StyledTableCell>
                  )
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  return (
    <div style={style} className={props["is_column"] ? "ir_sub_item column_item" : "ir_sub_item"}>
      {title ? (
        <div className="input_title_wrap" style={{marginBottom: 10}}>
          <Title title={title} />
          {renderRequired()}
        </div>
      ) : null}
      {description ? <Description description={description} /> : null}
      <Controller
        name={name}
        control={control}
        // defaultValue={value}
        rules={rules}
        render={({ field }) => renderTable()}
      />
      {after_text ? <div className="after_text">{after_text}</div> : null}
      {caution ? <Caution caution={caution} /> : null}
    </div>
  )
}
