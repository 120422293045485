import * as React from "react"
import { FunctionComponent } from "react"
import { Button, FormControl, InputLabel, MenuItem, NativeSelect, Select, TextField } from "@material-ui/core"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Pagination } from "@material-ui/lab"
import { IApprovalList } from "./IApprovalList"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableBody from "@material-ui/core/TableBody"
import TableContainer from "@material-ui/core/TableContainer"
import { withStyles } from "@material-ui/core/styles"
import TableCell from "@material-ui/core/TableCell"
import * as moment from "moment"
import { APPROVAL_TYPES } from "../../../../common/constants"
import { ReviewProducts } from "../../../molecules/ReviewProducts/ReviewProducts"
import { isAdmin } from "../../../../common/utils"
import { useForm, FormProvider } from "react-hook-form"
import { TextForm } from "../../../molecules/TextForm/TextForm"
import { SelectBoxForm } from "../../../molecules/SelectBoxForm/SelectBoxForm"
import { CustomPagination } from "../../../molecules/Pagination/Pagination"

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell)

export const ApprovalList: FunctionComponent<IApprovalList> = (props) => {
  const { total, data, search, current_page, last_page, setPage, approveType, setSearch, setApproveType } = props
  const is_admin = isAdmin()
  let retVal = undefined
  const [ sort, setSort ] = React.useState('')

  const methods = useForm({
    mode: "onBlur",
  })
  const { handleSubmit } = methods

  const handleChange = (event) => {
    setSort(event.target.value)
  }

  const onSearch = async (data) => {
    console.log("data", data)
    setSearch(data["search"])
    setApproveType(data["approveType"])
  }

  const rowClick = (event, row) => {
    console.log(event, row)
  }

  retVal = (
    <section className={is_admin ? "main_center approve_list" : "main_area"}>
      <div className="mp_product_title">
        <div className="list_title_box">
          <h2 className="top_title_h2">申請一覧</h2>
          <div className="search_list_num"><span>{total ? total : 0}</span>件見つかりました</div>
        </div>
      </div>

      <FormProvider {...methods}>
        <form method="post" autoComplete="on" onSubmit={handleSubmit(onSearch)}>
          <div style={{ display: "inline-flex", marginBottom: 30 }}>
            <TextForm
              id="search"
              name="search"
              value={search}
              style={{ marginRight: 20 }}
            />
            <SelectBoxForm
              id="approveType"
              name="approveType"
              value={approveType}
              selects={[ "審査中", "差し戻し" ]}
              selectsValue={[ "0", "1" ]}
            />
            <Button className="submitBtn" type={"submit"}>検索</Button>
          </div>
        </form>
      </FormProvider>

      <div style={{marginBottom: 20}}>
        {data ? <ReviewProducts reviewProductList={data} is_admin={is_admin} /> : <div>申請はありません</div>}
      </div>
      <CustomPagination current_page={current_page} last_page={last_page} setPage={setPage} />
    </section>
  )


  return retVal
}